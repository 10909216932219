const setMarque = {
  default: {
    marque: 'Hellio',
    logoLink: 'https://particulier.hellio.com/',
    showHeader: true,
    color1: '#006fba',
    color2: '#fba03d',
    headerBgcolor: '#006fba',
    removeQ1: [],
    logoMarque: '',
    removeCodePromo: false,
  },

  // ---------------------------------------------------------
  // You can start modify and add new simulateur below :
  // ---------------------------------------------------------

  'laforet-simulateur.hellio.com': {
    marque: 'laforet.hellio.com',
    logoLink: 'http://laforet.com',
    favicon: 'https://www.laforet.com/favicon.ico',
    showHeader: true,
    color1: '#013C84',
    color2: '#099BD6',
    removeQ1: [],
    logoMarque:
      'https://hellio.com/hubfs/simulateur/marque/laforet-logo-500x100.png',
    removeCodePromo: true,
  },

  'bfm-simulateur.hellio.com': {
    marque: 'BFM',
    showHeader: false,
    headerBgcolor: 'white',
    color1: '#02399E',
    color2: '#001741',
    removeQ1: [],
    logoMarque: 'https://hellio.com/hubfs/simulateur/marque/logo-bfm.svg',
    removeCodePromo: true,
  },

  'hopenergie-simulateur.hellio.com': {
    marque: 'hopenergie.hellio.com',
    logoLink: 'https://www.hopenergie.com/',
    favicon:
      'https://www.hopenergie.com/images/xfavicon.png.pagespeed.ic.KtjtKK5ajX.webp',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#00caa9',
    color2: '#ff6e36',
    removeQ1: [],
    logoMarque:
      'https://hellio.com/hubfs/Partenaires/Hopenergie/logo-hopenergie-01.svg',
    removeCodePromo: true,
  },

  'mint-simulateur.hellio.com': {
    marque: 'mint.hellio.com',
    logoLink: 'https://mint-energie.com/',
    favicon: 'https://www.mint-energie.com/favicon.ico',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#2d2155',
    color2: '#00aa73',
    removeQ1: ['photovoltaïque'],
    logoMarque: 'https://hellio.com/hubfs/logo-mint-energie-1.svg',
    removeCodePromo: true,
  },

  'monideal-artisans-simulateur.hellio.com': {
    marque: 'monideal-artisan',
    logoLink: 'https://monideal-artisans.fr/',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#70a63b',
    color2: '#546f33',
    removeQ1: [],
    logoMarque:
      'https://hellio.com/hubfs/Partenaires/IAD/logo-monideal-artisan.png',
    removeCodePromo: false,
  },

  'seloger-simulateur.hellio.com': {
    marque: 'seloger',
    logoLink: 'https://seloger.com/',
    favicon: 'https://www.seloger.com/favicon.ico',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#df0635',
    color2: '#262626',
    removeQ1: [],
    logoMarque:
      'https://www.hellio.com/hubfs/Partenaires/SeLoger/Seloger-logo-2023.svg',
    removeCodePromo: true,
  },

  'fioulreduc-simulateur.hellio.com': {
    marque: 'FioulReduc',
    logoLink: 'https://www.fioulreduc.com/',
    favicon: 'https://cdn.fioulreduc.com/fo-icons/favicon.ico?38345',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#f80',
    color2: '#ff325f',
    removeQ1: ['chauffage', 'renovationGlobale'],
    logoMarque:
      'https://hellio.com/hubfs/Partenaires/Fioulreduc/logo-fioulreduc.svg',
    logoMarqueWidth: 200,
    removeCodePromo: true,
  },

  'capifrance-simulateur.hellio.com': {
    marque: 'Capifrance',
    logoLink: 'https://www.capifrance.fr/',
    favicon:
      'https://www.capifrance.fr/user/themes/capifrance/images/favicon/manifest.json',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#082e4f',
    color2: '#0093f7',
    removeQ1: [],
    logoMarque:
      'https://hellio.com/hubfs/Partenaires/Capifrance/Logo%20Capifrance.png',
    logoMarqueWidth: 400,
    removeCodePromo: true,
  },

  'bricorama-simulateur.hellio.com': {
    marque: 'Bricorama',
    logoLink: 'https://www.bricorama.fr/',
    favicon:
      'https://www.bricorama.fr/_ui/responsive/theme-bricoRama/assets/img/favicon/apple-touch-icon-60x60.png?v=PYqegXL420&vs=20230228%201621',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#333333',
    color2: '#FFD300',
    removeQ1: [],
    logoMarque: 'https://hellio.com/hubfs/logo-bricorama.png',
    logoMarqueWidth: 200,
  },

  'bricomarche-simulateur.hellio.com': {
    marque: 'Bricomarche',
    logoLink: 'https://www.bricomarche.com/',
    favicon:
      'https://www.bricomarche.com/_ui/responsive/theme-bricoMarche/assets/img/favicon/apple-touch-icon-57x57.png?v=PYqegXL420&vs=20230228%201621',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#23191A',
    color2: '#C81900',
    removeQ1: [4,5,8,9],
    logoMarque: 'https://hellio.com/hubfs/logo-bricomarch%C3%A9.svg',
    logoMarqueWidth: 200,
  },

  'optimhome-simulateur.hellio.com': {
    marque: 'Optimhome',
    logoLink: 'https://www.optimhome.com/',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#2B2E32',
    color2: '#ff5c02',
    removeQ1: [],
    logoMarque: 'https://hellio.com/hubfs/logo-optimhome.svg',
    removeCodePromo: true,
  },
  'terranova-simulateur.hellio.com': {
    marque: 'Terra Nova Ecouflant',
    logoLink: 'https://terranova-france.fr/',
    favicon:
      'https://terranova-france.fr/wp-content/uploads/2015/03/favicon-55097710v1_site_icon-32x32.png',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#5F5F5F',
    color2: '#8EBD17',
    removeQ1: [],
    logoMarque:
      'https://hellio.com/hubfs/Partenaires/Terra%20nova/Logo%20terra%20nova.png',
  },

  'seniorcg-simulateur.hellio.com': {
    marque: 'seniorcg',
    logoLink: 'https://seniorcg.com/',
    favicon:
      'https://seniorcg.com/wp-content/uploads/2022/11/cropped-tre%CC%80fle-bleu-150x150.png',
    showHeader: true,
    color1: '#1A5A6D',
    color2: '#E6AD59',
    removeQ1: [],
    logoMarque: 'https://hellio.com/hubfs/seniorcg-logo.png',
  },
  'metro-simulateur.hellio.com': {
    marque: 'Metro.hellio.com',
    logoLink: 'https://www.metro.fr/',
    favicon:
      'https://cdn.metro-online.com/-/media/Project/MCW/FR_Metro/Images/favicon/metro-favicon-180x180.png?rev=c22fa05920d54bc7adc28d19ec91afe5',
    showHeader: true,
    color1: '#002d72',
    color2: '#0059E4',
    removeQ1: [],
    logoMarque: 'https://hellio.com/hubfs/METRO.svg',
  },
  'simulateur-corse.hellio.com': {
    marque: 'renovation-globale-corse',
    logoLink: 'https://www.hellio.com/corse',
    showHeader: true,
    color1: '#006fba',
    color2: '#fba03d',
    headerBgcolor: '#006fba',
    removeQ1: ['isolation', 'chauffage', 'photovoltaïque', 'auditEnergetique'],
    logoMarque: 'https://www.hellio.com/hubfs/HellioCorse-logo-blanc.svg',
    logoMarqueWidth: 160,
    removeCodePromo: false,
    hideHellioLogo: true,
  },
  'toutvabiens-simulateur.hellio.com': {
    marque: 'Toutvabiens',
    logoLink: 'https://toutvabiens.com/',
    favicon:
      'https://i0.wp.com/toutvabiens.com/wp-content/uploads/2023/01/favicon.png?fit=32%2C32&ssl=1',
    showHeader: true,
    headerBgcolor: 'white',
    color1: '#2600B8',
    color2: '#88EA81',
    removeQ1: [],
    logoMarque:
      'https://www.hellio.com/hubfs/Partenaires/Toutvabiens/Toutvabiens-logo.png',
    removeCodePromo: true,
  },

};

export default setMarque;
