import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import infosBlack from 'images/icons/infos-black.svg';
import InfoTooltip from '../../InfoTooltip';

export default function DelayInfos({
  className,
  installationWarranty,
  lifecycle,
  rentability,
  indice_de_rentabilite_info,
  url_indice_de_rentabilite,
}) {
  return (
    <div
      className={clsx(
        'flex bg-white rounded border border-solid border-xlight_gray justify-between items-center px-4 py-4 leading-none',
        className,
      )}
    >
      <div className={'flex flex-col gap-2'}>
        <p className={'lg:text-[14px] text-[8px] font-light'}>
          Seuil de rentabilité
        </p>
        <p className={'font-bold text-[14px]'}>{rentability}</p>
      </div>
      <div className={'flex flex-col gap-2'}>
        <p className={'lg:text-[14px] text-[8px] font-light'}>Durée de vie</p>
        <p className={'font-bold text-[14px]'}>{lifecycle}</p>
      </div>
      <div className={'flex flex-col gap-2'}>
        <p className={'lg:text-[14px] text-[8px] font-light'}>
          Installation garantie
        </p>
        <p className={'font-bold text-[14px]'}>{installationWarranty}</p>
      </div>
      {indice_de_rentabilite_info && (
        <InfoTooltip
          className={'bg-black bg-opacity-70'}
          icon={<img src={infosBlack} className={'w-3 h-3'} alt="" />}
        >
          <p className={'text-white'}>{indice_de_rentabilite_info}</p>
          {url_indice_de_rentabilite && (
            <a
              className={'text-white underline'}
              href={url_indice_de_rentabilite}
              target={'_blank'}
              rel={'noreferrer'}
            >
              En savoir plus
            </a>
          )}
        </InfoTooltip>
      )}
    </div>
  );
}

DelayInfos.propTypes = {
  className: PropTypes.string,
  rentability: PropTypes.string,
  lifecycle: PropTypes.string,
  installationWarranty: PropTypes.string,
  indice_de_rentabilite_info: PropTypes.string,
  url_indice_de_rentabilite: PropTypes.string,
};
