import React from 'react';
import PropTypes from 'prop-types';

export default function SuccesItem({ label, value }) {
  return (
    <div className="success-item">
      <h3>{label}</h3>
      <h2>{value}</h2>
    </div>
  );
}
SuccesItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};
