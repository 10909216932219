import React from 'react';
import Navigation from './Navigation';
import InfoTooltip from 'components/InfoTooltip';
import FileUploader from './FileUploader';

class QuestionAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
    };
  }

  showHover = () => {
    this.setState({ display: 'block' });
  };

  hideHover = () => {
    this.setState({ display: 'none' });
  };

  render() {
    const itemsMobile = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="item-container-m" key={item.long || item.short}>
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <img src={item.icon} alt={item.short} width="30" height="35" />{' '}
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="item-container" key={item.long || item.short}>
        <label>
          <div
            className={
              this.props.data == item.short ? 'item item-checked' : 'item'
            }
          >
            <div className="img">
              <img src={item.icon} alt={item.short} />
            </div>
            <div className="d-flex">
              <span
                className={this.props.data == item.short ? 'text-sub2' : ''}
              >
                {item.long}
              </span>
              {item.tooltip && (
                <InfoTooltip className="tooltip-bailleur">
                  <h3>Propriétaire bailleur : </h3>
                  <p className="mt-10">
                    <strong>Un bailleur</strong> désigne une personne physique
                    ou morale (banque, mutuelle, compagnie d&apos;assurance),
                    généralement <strong>propriétaire</strong> d&apos;un bien
                    immobilier et qui met celui-ci en location.
                  </p>
                </InfoTooltip>
              )}
            </div>
          </div>
          <div className="d-flex mb-30">
            <div>
              <div>
                <input
                  type="radio"
                  name={this.props.name}
                  value={item.short}
                  onChange={(e) => {
                    if (this.props.handleData) {
                      this.props.handleData(e);
                    }
                    if (item.short === 'Non') {
                      this.props.handleFile();
                      this.props.nextStep();
                    }
                  }}
                  className="is-hidden"
                />
              </div>

              {/* <div className={this.props.data == item.short? 'square-checked':'square'}>
                  <svg width="16" height="11" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.45312 5.59251L5.27343 9.69831L14.2833 2.03833" stroke="white" strokeWidth="3"></path></svg>
                </div> */}
            </div>
            <div className={item.long.length > 18 ? 'lh16' : 'lh22'}></div>
          </div>
        </label>
      </div>
    ));

    return (
      <div className="fadeIn">
        <div
          className={
            this.props.dataItems[this.props.name].items.length > 3
              ? 'item-wrapper is-hidden-mobile'
              : 'item-wrapper jcc is-hidden-mobile'
          }
        >
          {items}
        </div>
        <div className="only-show-mobile">{itemsMobile}</div>
        <div style={{ maxWidth: '360px', margin: '0 auto', height: '500px' }}>
          <div className={this.props.data == 'Oui' ? '' : 'is-hidden'}>
            <FileUploader
              patchName="fileAudit"
              description="Déposez votre audit énergétique pour faciliter notre échange téléphonique. (15Mo maximum)"
              handleFile={this.props.handleFile}
            ></FileUploader>
          </div>
        </div>

        <Navigation
          data={this.props.data == 'Non' || this.props.data == 'Oui'}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
        />
      </div>
    );
  }
}

export default QuestionAudit;
