import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

export default function Recommandation({ children, className, title }) {
  return (
    <div
      className={clsx(
        'lg:p-6 p-4 text-white rounded bg-xblue leading-none',
        className,
      )}
    >
      <h2 className="text-[12px] lg:text-[14px] font-medium !mb-4">{title}</h2>
      <p className="font-medium lg:text-[20px] text-[16px]">{children}</p>
    </div>
  );
}

Recommandation.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
};
