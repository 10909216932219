import React from 'react';
import Navigation from './Navigation';
import FileUploader from './FileUploader';
class QuestionDevis extends React.Component {
  constructor(props) {
    super(props);
  }

  enterNext = (e) => {
    if (e.key === 'Enter' && this.props.data) {
      this.props.nextStep();
    }
  };

  componentDidMount() {
    document.querySelectorAll('input:not(input[type="radio"])')[0].focus();
  }

  componentDidUpdate() {
    const items = document.querySelectorAll('input:not(input[type="radio"])');
    if (!items[0].value) {
      items[0].focus();
    }
  }

  render() {
    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="input-container flex-input2" key={item.name}>
        <label className="mh-10 text-main is-bold" style={{ fontSize: '18px' }}>
          {item.label}
        </label>
        <div className="m-10">
          <div className="d-flex aic">
            <input
              type={item.type ? item.type : 'text'}
              placeholder={item.placeholder}
              name={item.name}
              onChange={this.props.handleData}
              value={this.props.userContact[item.name]}
              min={item.min}
              step={item.step}
              onKeyPress={this.enterNext}
            />
            <span className="text-main ml-5">€</span>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="fadeIn">
        <div className="w-100 d-flex jcc">
          <div style={{ minWidth: '360px' }} className="d-flex column">
            {items}
            <div className="mt-20">
              <FileUploader
                patchName="fileDevis"
                description="Veuillez déposer votre devis pour faciliter le montant de votre prime. (15Mo maximum)"
                handleFile={this.props.handleFile}
              ></FileUploader>
            </div>
          </div>
        </div>
        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
        />
      </div>
    );
  }
}

export default QuestionDevis;
