/* eslint-disable react/prop-types */
import React from 'react';
import SuccessPage from 'components/success/SuccessPage';
import CommentBlock from 'components/success/CommentBlock';

class SuccessCopro extends React.Component {
  render() {
    return (
      <SuccessPage {...this.props}>
        <CommentBlock>
          Votre inscription est bien prise en compte. Nos experts Hellio se
          chargent de contacter votre syndic afin de présenter les différentes
          opportunités de rénovation énergétique pour votre copropriété.
        </CommentBlock>
      </SuccessPage>
    );
  }
}

export default SuccessCopro;
