import React from 'react';

class QuestionsTextSalutation extends React.Component {
  render() {
    const salutationItems = this.props.data.map((item) => (
      <label className="mr-30 fw-600" key={item.value}>
        {item.value}
        {this.props.salutation == item.value && (
          <input
            type="radio"
            value={item.value}
            name={item.name}
            className="ml-10 radio-align"
            onChange={this.props.handleData}
            checked
          />
        )}
        {this.props.salutation !== item.value && (
          <input
            type="radio"
            value={item.value}
            name={item.name}
            className="ml-10 radio-align"
            onChange={this.props.handleData}
          />
        )}
      </label>
    ));
    return (
      <div className="mv-20 flex-input fz14">
        <div style={{ width: '90px' }}>
          <label className="fz14 is-normal">Civilité</label>
        </div>
        <div>{salutationItems}</div>
      </div>
    );
  }
}

export default QuestionsTextSalutation;
