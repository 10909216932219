/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import SuccessPage from 'components/success-v2/SuccessPage';
import BandeauPrixSolaire from 'components/success-v2/blocks/BandeauPrixSolaire';
import Resume from './blocks/Resume';
import solarPanel from 'images/icons/solar-panel.svg';
import document from 'images/icons/document.svg';
import Recommandation from './blocks/Recommandation';
import PromoCode from './blocks/PromoCode';
import Alert from './blocks/Alert';
import PowerRecommandations from './blocks/PowerRecommandations';
import ChoiceOffer from './blocks/ChoiceOffer';
import phoneIcon from 'images/icons/phoneIcon.svg';
import EcoNote from './blocks/EcoNote';
import PowerCharacteristics from './blocks/PowerCharacteristics';
import DelayInfos from './blocks/DelayInfos';
import VerticalRecommandation from './blocks/VerticalRecommandation';
import { useSimulation } from '../../providers/SimulationProvider';
import dataItems from '../../data/dataItems';
import { formatPriceEUR } from '../../utils/formatPrice';

export default function SuccessPhotovoltaique() {
  const { answers, result, resultCodePromo, simulationResult } =
    useSimulation();

  const data = simulationResult?.data?.Autoconsommation;

  const [selectedGamme, setSelectedGamme] = useState(
    data ? Object.keys(data)[0] : null,
  );

  // const [selectedQuantity, setSelectedQuantity] = useState(
  //   data ? Object.keys(data[selectedGamme].informations_panneaux)[0] : null,
  // );

  if (!data) return null;

  const gamme = data[selectedGamme];
  const informationsPanneaux = gamme.informations_panneaux;

  const logement = dataItems.Q2.items.find((item) => item.short === answers.Q2);

  const contractType = dataItems.Q16.items.find(
    (item) => item.short === answers.Q16,
  );
  const recommandation = result.data.Offre;

  const recommandationKw = recommandation?.match(/\d+/g).map(Number)[0];

  const selectedQuantity = Object.keys(informationsPanneaux).find(
    (quantity) => informationsPanneaux[quantity].puissance === recommandationKw,
  );

  const offre = informationsPanneaux[selectedQuantity];
  const price = offre.prix;
  const prime = offre.prime_autoconsommation;
  const monthly = offre.prix_mensuel;

  const { informations_panneaux } = data[Object.keys(data)[0]];
  const panneaux = Object.keys(informations_panneaux).find(
    (quantity) =>
      informations_panneaux[quantity].puissance === recommandationKw,
  );

  const montantPromo = resultCodePromo.data.montant_promo ?? 0;
  const reste = price - prime - montantPromo;

  return (
    <SuccessPage
      footer={
        <BandeauPrixSolaire
          installationPrice={`${formatPriceEUR(price)}`}
          autoconsomationPrime={`-${formatPriceEUR(prime)}`}
          codePromoPrime={
            montantPromo ? `-${formatPriceEUR(montantPromo)}` : null
          }
          totalCashPrice={`${formatPriceEUR(reste)}`}
          rentPrice={`${formatPriceEUR(monthly)} / mois*`}
          rentFee={offre.info_loyer}
          prime_autoconsommation_info={gamme.prime_autoconsommation_info}
          url_prime_autoconsommation={gamme.url_prime_autoconsommation}
          panneaux={panneaux}
          selectedGamme={selectedGamme}
        />
      }
    >
      <div className={'flex flex-col lg:flex-row gap-6 pt-4'}>
        <Resume
          className={'flex-1'}
          title={'Votre projet'}
          subtitle={{
            icon: solarPanel,
            title: 'Installation de panneaux solaires',
          }}
          footer={[
            {
              icon: logement?.icon,
              title: 'Type de logement',
              subtitle: logement?.short,
            },
            {
              icon: document,
              title: 'Facture d’électricité / mois ',
              subtitle: `${answers.Q15} €`,
            },
            {
              icon: contractType?.icon,
              title: 'Type de contrat',
              subtitle: contractType?.short,
            },
          ]}
        />
        <Recommandation
          className={'w-full lg:w-1/3'}
          title={'Notre recommandation'}
        >
          <span>Installation de {panneaux} panneaux solaires</span>{' '}
          <span className={'text-nowrap'}>({recommandationKw} kWc)</span>
        </Recommandation>
      </div>
      <Alert className={'w-full lg:my-6 my-4'}>
        Cette recommandation vous est suggéré en fonction de votre facture
        d’électricité. Vous pouvez ajuster le nombre de panneaux solaires et la
        gamme.
      </Alert>
      <div className={'lg:flex hidden gap-6'}>
        <div className={'w-1/2'}>
          <img src={offre.url_image_maison} className={'w-full px-8'} alt="" />
          <div className={'bg-white p-6 rounded-lg mt-4'}>
            <ChoiceOffer
              className={''}
              descriptions={[
                {
                  icon: gamme.url_image_origine_panneau,
                  title: gamme.origine_panneau,
                },
                {
                  icon: gamme.url_image_garantie,
                  title: gamme.type_garantie,
                },
                {
                  icon: phoneIcon,
                  title: gamme.type_monitoring,
                },
              ]}
              durations={[
                {
                  icon: gamme.url_image_panneau,
                  years: gamme.garantie_panneau,
                },
                {
                  icon: gamme.url_image_onduleur,
                  years: gamme.garantie_onduleur,
                },
                // {
                //   icon: coffretImage,
                //   years: 10,
                // },
              ]}
            />
            <DelayInfos
              rentability={`${offre.seuil_rentabilite} ans`}
              lifecycle={`${offre?.duree_de_vie} ans`}
              installationWarranty={`${gamme?.garantie_panneau} ans`}
              url_indice_de_rentabilite={gamme.url_indice_de_rentabilite}
              indice_de_rentabilite_info={gamme.indice_de_rentabilite_info}
              className={'mt-4'}
            />
          </div>
        </div>
        <div className={'w-1/2'}>
          <div className={'bg-white p-6 rounded-lg'}>
            <PowerRecommandations
              quantities={
                informationsPanneaux
                  ? Object.keys(informationsPanneaux).map((amount, index) => ({
                      amount,
                      id: amount,
                      power: `${informationsPanneaux[amount].puissance} kWc`,
                    }))
                  : []
              }
              gammes={Object.keys(data).map((id) => ({
                id,
                tag: id,
                price: `${data[id].a_partir_de} €`,
                title: `Panneaux ${data[id].type_panneau}\n${data[id].type_onduleur}`,
                gamme_info: data[id].gamme_info,
                url_gamme_info: data[id].url_gamme_info,
              }))}
              activeGamme={selectedGamme}
              activeQuantity={selectedQuantity}
              onGammeChange={setSelectedGamme}
              // onQuantityChange={setSelectedPannel}
            />
            <PowerCharacteristics
              className={'mt-4'}
              maxPower={`${offre.puissance} kWc`}
              surface={`${offre.surface} m²`}
              annualSaved={`${formatPriceEUR(offre?.economies_max_annuelle)}`}
              bonus25Years={
                offre
                  ? `${formatPriceEUR(offre.gain[0])} - ${formatPriceEUR(offre.gain[1])}`
                  : ''
              }
            />
            <PromoCode className={'items-center mt-4'} />
            <EcoNote
              econote_hellio_info={gamme.econote_hellio_info}
              url_econote_hellio_info={gamme.url_econote_hellio_info}
              active={gamme.econote_hellio}
              className={'mt-4'}
            />
          </div>
        </div>
      </div>
      <VerticalRecommandation
        offre={offre}
        gamme={gamme}
        data={data}
        activeGamme={selectedGamme}
        activeQuantity={selectedQuantity}
        onGammeChange={setSelectedGamme}
        className={'flex lg:hidden pt-3'}
      />
    </SuccessPage>
  );
}
