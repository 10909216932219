const showPopupList = [
  'OptimhomeAgent',
  'CapifranceAgent',
  'LaforetAgent',
  'LotsAgent',
  'EmailMandant',
  'LaforetEmailPhone',
  'LotsADB',
  'ToutvabiensAgent',
  'BricomarcheEmail',
];
export default showPopupList;
