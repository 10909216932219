import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import infosBlack from 'images/icons/infos-black.svg';
import ecoNote from 'images/icons/EcoNote.svg';
import InfoTooltip from '../../InfoTooltip';

const config = [
  {
    note: 'A',
    color: '#20A76A',
  },
  {
    note: 'B',
    color: '#FBA03D',
  },
  {
    note: 'C',
    color: '#FF816D',
  },
  {
    note: 'D',
    color: '#F13538',
  },
];

export default function EcoNote({
  className,
  active,
  vertical = false,
  infos,
  econote_hellio_info,
  url_econote_hellio_info,
}) {
  return (
    <div
      className={clsx(
        'flex bg-white rounded justify-center items-center p-3',
        { 'flex-col gap-2': vertical, 'gap-4': !vertical },
        className,
      )}
    >
      <div className={'flex items-center gap-1'}>
        <img src={ecoNote} className={'w-[18px] h-[18px]'} alt="" />
        <p className={'font-medium text-[12px] lg:text-[16px]'}>
          Éco-note Hellio
        </p>
      </div>

      <div className={clsx('flex gap-0.5 items-center')}>
        {config.map(({ color, note }, index) => (
          <div
            key={note}
            style={{
              backgroundColor: color,
              borderRadius: 2,
            }}
            className={clsx('flex duration-300 items-center justify-center', {
              'w-4 h-4': active !== note,
              'w-5 h-6': active === note,
            })}
          >
            <span
              className={clsx(
                'text-white duration-300 text-[10px] font-medium',
                {
                  'opacity-30': active !== note,
                  'opacity-100': active === note,
                },
              )}
            >
              {note}
            </span>
          </div>
        ))}
      </div>
      {!vertical && (
        <div className={'flex items-center'}>
          {infos && <p className={'text-[8px] font-normal pr-1'}>{infos}</p>}
          {econote_hellio_info && (
            <InfoTooltip
              className={'bg-black bg-opacity-70'}
              icon={<img src={infosBlack} className={'w-3 h-3'} alt="" />}
            >
              <p className={'text-white'}>{econote_hellio_info}</p>
              {url_econote_hellio_info && (
                <a
                  className={'text-white underline'}
                  href={url_econote_hellio_info}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  En savoir plus
                </a>
              )}
            </InfoTooltip>
          )}
        </div>
      )}
    </div>
  );
}

EcoNote.propTypes = {
  className: PropTypes.string,
  active: PropTypes.string,
  vertical: PropTypes.bool,
  infos: PropTypes.bool,
  econote_hellio_info: PropTypes.string,
  url_econote_hellio_info: PropTypes.string,
};
