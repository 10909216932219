/* eslint-disable react/prop-types */
import React from 'react';
import Avis from '../../images/avis-success.svg';
import CallCTA from '../CallCTA';

class NavSuccess extends React.Component {
  Q1AnswersToHideForCallCTA = ['isolation', 'chauffage'];

  constructor(props) {
    super(props);
  }

  shouldShowCTA = () => {
    if (this.Q1AnswersToHideForCallCTA.includes(this.props.answers['Q1'])) {
      return true;
    }

    return (
      this.props.answers['Q1'] === 'thermostat' &&
      this.props.answers['Q53.V2'] === 'Collectif'
    );
  };

  render() {
    const hideCallCTA = this.shouldShowCTA();

    return (
      <div>
        <div className="navSuccess">
          {!hideCallCTA && <CallCTA contact={this.props.contact} />}
        </div>

        <div className="only-show-mobile" style={{ marginTop: '20px' }}>
          <div className="navigation-mobile-success">
            <div className="d-flex jcsb">
              <button className="btn-retour" onClick={this.props.lastStep}>
                <svg
                  width="35"
                  height="37"
                  viewBox="0 0 35 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="35" height="36.2572" rx="17.5" fill="white" />
                  <path
                    d="M15.9053 12.4395L10.3905 18.176L15.9053 23.8185"
                    stroke="#FBA03D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                  />
                  <path
                    d="M11.5532 18.1758L24.6113 18.1758"
                    stroke="#FBA03D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                  />
                </svg>
                <span className="gris-hellio">Retour</span>
              </button>
              <img src={Avis} alt="Avis" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavSuccess;
