/* eslint-disable react/prop-types */
import React from 'react';
import dataMPRcolors from '../../data/dataMPRcolors';
class MaPrimeRenovBlock extends React.Component {
  render() {
    const bgc = dataMPRcolors[this.props.color].bg ?? 'rose';

    return (
      <div className="success-item">
        <h3>MaPrimeRénov’</h3>
        <h4 style={{ backgroundColor: bgc }} className="MaPrimeTag">
          Ménage {dataMPRcolors[this.props.color].display}
        </h4>
      </div>
    );
  }
}

export default MaPrimeRenovBlock;
