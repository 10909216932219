import React from 'react';
import arrowRight from '../images/arrow-right.svg';
import avisBase from '../images/avis-base.svg';
import avisBaseM from '../images/avis-base-m.svg';
import arrowLeft from '../images/arrow-left-gray.svg';
// import mobileLeft from '../images/arrow-left-white.svg';
class Navigation extends React.Component {
  render() {
    return (
      <div>
        <div className="only-show-mobile">
          <div className="navigation-mobile align-right">
            <img src={avisBaseM} alt="avis verifies" width="200" />
            <div className="d-flex">
              {this.props.leadsource && this.props.step == 0 && (
                <button
                  className="btn-nav"
                  onClick={this.props.lastStepPrequestion}
                >
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 51 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.08984"
                      y="0.674194"
                      width="49.2822"
                      height="51.0884"
                      rx="24.6411"
                      fill="white"
                    />
                    <path
                      d="M23.4404 18.0444L15.5177 26.2857L23.4404 34.392"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M17.1876 26.2861L35.9473 26.2861"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}
              {this.props.step !== 0 && (
                <button className="btn-nav" onClick={this.props.lastStep}>
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 51 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.08984"
                      y="0.674194"
                      width="49.2822"
                      height="51.0884"
                      rx="24.6411"
                      fill="white"
                    />
                    <path
                      d="M23.4404 18.0444L15.5177 26.2857L23.4404 34.392"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M17.1876 26.2861L35.9473 26.2861"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}
              {this.props.last && (
                <a
                  href="https://particulier.hellio.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="w-100"
                >
                  <button className="btn-nav-m" style={{ opacity: '1' }}>
                    <span>Retour au site Hellio</span>
                  </button>
                </a>
              )}

              {this.props.data && !this.props.last && (
                <button
                  className="btn-nav-m"
                  style={{ opacity: '1' }}
                  onClick={this.props.nextStep}
                >
                  <span>Continuer</span>
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7109 19.2052L22.6337 10.9639L14.7109 2.85763"
                      stroke="white"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M20.9638 10.964L2.2041 10.964"
                      stroke="white"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}

              {!this.props.data && !this.props.last && (
                <button className="btn-nav-m-disable" disabled>
                  <span>Continuer</span>
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7109 19.2052L22.6337 10.9639L14.7109 2.85763"
                      stroke="white"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M20.9638 10.964L2.2041 10.964"
                      stroke="white"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="is-hidden-mobile navigation-general">
          <div className="wrap">
            <img src={avisBase} alt="base" style={{ float: 'left' }} />
            <div>
              {this.props.leadsource && this.props.step == 0 && (
                <button
                  className="btn-retour"
                  onClick={this.props.lastStepPrequestion}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="19" fill="white" />
                    <path
                      d="M17.2012 13.3345L10.9819 19.0468L17.2012 24.6654"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M12.2924 19.0469L27.0186 19.0469"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}
              {this.props.step !== 0 && (
                <button className="btn-retour" onClick={this.props.lastStep}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="19" fill="white" />
                    <path
                      d="M17.2012 13.3345L10.9819 19.0468L17.2012 24.6654"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                    <path
                      d="M12.2924 19.0469L27.0186 19.0469"
                      stroke="#FBA03D"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              )}
              {this.props.data && !this.props.last && (
                <button
                  className="btn-aller is-bold"
                  onClick={this.props.nextStep}
                >
                  Continuer <img src={arrowRight} />
                </button>
              )}

              {!this.props.data && !this.props.last && (
                <button className="btn-aller-disable-web">
                  Continuer <img src={arrowRight} />
                </button>
              )}

              {this.props.last && (
                <a
                  href="https://particulier.hellio.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="w-100"
                >
                  <button className="btn-aller">Retour au site Hellio</button>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
