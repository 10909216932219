import React from 'react';
import PropTypes from 'prop-types';

export default function Amount({ value, decimals = 0, currency = 'EUR' }) {
  const numberValue =
    typeof value === 'string'
      ? +value.replace(/[^0-9.,-]/g, '').replace(/,/g, '.')
      : value;

  const formattedValue = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    maximumFractionDigits: decimals,
    currency,
  }).format(numberValue);

  return <span className="whitespace-nowrap">{formattedValue}</span>;
}
Amount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimals: PropTypes.number,
  currency: PropTypes.string,
};
