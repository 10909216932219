import React from 'react';
import Navigation from './Navigation';
import infoIcon from '../images/info.svg';
class QuestionQ45 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
    };
  }

  showHover = () => {
    this.setState({ display: 'block' });
  };

  hideHover = () => {
    this.setState({ display: 'none' });
  };

  render() {
    const items1 = this.props.dataItems[this.props.name].items[0].map(
      (item) => (
        <div
          className="col is-half item-container-Q45"
          key={item.long || item.short}
        >
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items2 = this.props.dataItems[this.props.name].items[1].map(
      (item) => (
        <div
          className="col is-half item-container-Q45"
          key={item.long || item.short}
        >
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items3 = this.props.dataItems[this.props.name].items[2].map(
      (item) => (
        <div
          className="col is-half item-container-Q45"
          key={item.long || item.short}
        >
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items4 = this.props.dataItems[this.props.name].items[3].map(
      (item) => (
        <div
          className="col is-half item-container-Q45"
          key={item.long || item.short}
        >
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    const items5 = this.props.dataItems[this.props.name].items[4].map(
      (item) => (
        <div
          className="col is-half item-container-Q45"
          key={item.long || item.short}
        >
          <label>
            <div
              className={
                this.props.data == item.short ? 'item item-checked' : 'item'
              }
            >
              <span>{item.long}</span>
            </div>
            <input
              type="radio"
              name={this.props.name}
              value={item.short}
              onChange={this.props.handleData}
              className="is-hidden"
            />
          </label>
        </div>
      ),
    );

    if (this.props.Q1 === 'isolation') {
      return (
        <div
          className="fadeIn"
          style={{
            paddingBottom: '100px',
            width: '692px',
            margin: 'auto auto',
            maxWidth: '100%',
          }}
        >
          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/1.svg" />
            <p>Isolation</p>
          </div>
          <div className="row">{items1}</div>

          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/5.svg" />
            <p>Autre</p>
          </div>
          <div className="row">{items5}</div>

          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
            loadingParcours={this.props.loadingParcours}
            leadsource={this.props.leadsource}
            lastStepPrequestion={this.props.lastStepPrequestion}
          />
        </div>
      );
    } else if (this.props.Q1 === 'chauffage') {
      return (
        <div
          className="fadeIn"
          style={{
            paddingBottom: '100px',
            width: '692px',
            margin: 'auto auto',
            maxWidth: '100%',
          }}
        >
          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/2.svg" />
            <p>Changement de chauffage</p>
          </div>
          <div className="row">{items2}</div>

          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/5.svg" />
            <p>Autre</p>
          </div>
          <div className="row">{items5}</div>

          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
            loadingParcours={this.props.loadingParcours}
            leadsource={this.props.leadsource}
            lastStepPrequestion={this.props.lastStepPrequestion}
          />
        </div>
      );
    } else {
      return (
        <div
          className="fadeIn"
          style={{
            paddingBottom: '100px',
            width: '692px',
            margin: 'auto auto',
            maxWidth: '100%',
          }}
        >
          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/1.svg" />
            <p>Isolation</p>
          </div>
          <div className="row">{items1}</div>
          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/2.svg" />
            <p>Changement de chauffage</p>
          </div>
          <div className="row">{items2}</div>

          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/3.svg" />
            <p>Chauffe-eau</p>
          </div>
          <div className="row">{items3}</div>

          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/4.svg" />
            <p>Ventilation</p>
          </div>
          <div className="row">{items4}</div>

          <div className="Q45-title">
            <img src="https://hellio.com/hubfs/simulateur/icons3/Q45icons/5.svg" />
            <p>Autre</p>
          </div>
          <div className="row">{items5}</div>

          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
            loadingParcours={this.props.loadingParcours}
            leadsource={this.props.leadsource}
            lastStepPrequestion={this.props.lastStepPrequestion}
          />
        </div>
      );
    }
  }
}

export default QuestionQ45;
