import React from 'react';
import Navigation from '../Navigation';
import dataAgentLaforet from '../../data/partenaires/dataAgentLaforet';
import Select from 'react-select';
import Popup from './Popup';
import showPopupList from '../../data/partenaires/showPopupList';
// import ItemMandant from './ItemMandant';
import setMarque from '../../data/setMarque';

const optionsAgent = dataAgentLaforet.map((item) => ({
  value: `${item[2]}`,
  label: `${item[0]} - ${item[1]}`,
}));

const optionsClient = dataAgentLaforet.map((item) => ({
  value: `${item[2]}`,
  label: `${item[1]}`,
}));

const color1 = setMarque['laforet-simulateur.hellio.com'].color1;
class QuestionAgentLaforet extends React.Component {
  enterNext = (e) => {
    const inShowPopList = showPopupList.includes(this.props.name);
    if (e.key === 'Enter' && this.props.data && !inShowPopList) {
      this.props.nextStep();
    } else if (e.key === 'Enter' && this.props.data && inShowPopList) {
      this.props.handlePopup();
    }
  };

  componentDidMount() {
    document.querySelectorAll('input:not(input[type="radio"])')[0].focus();
  }

  render() {
    const inShowPopList = false;
    const showPopup = inShowPopList && this.props.showPopup;

    return (
      <div className="mt-20">
        <div className="d-flex jcc">
          <div style={{ minWidth: '360px', fontSize: '14px' }}>
            {showPopup && <Popup nextStep={this.props.nextStep}></Popup>}
            <div className="w-100">
              <div
                className="input-container flex-input"
                style={{ maxWidth: '400px' }}
              >
                <div style={{ width: '50px' }}></div>
                <div style={{ minWidth: '300px' }}>
                  <Select
                    value={this.props.agence}
                    onChange={this.props.handleLaForetAgent}
                    options={
                      this.props.name == 'LaforetClient'
                        ? optionsClient
                        : optionsAgent
                    }
                    placeholder={
                      <div className="select-placeholder">Sélectionner</div>
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() => 'aucune option trouvée'}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#eee',
                        primary: color1,
                        primary50: '#eee',
                        currentColor: 'red',
                      },
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        boxShadow: 'none',
                        color: '#3A3A3A',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        transform: state.selectProps.menuIsOpen
                          ? 'rotate(0deg)'
                          : 'rotate(270deg)',
                        color: color1,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {inShowPopList && (
          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.handlePopup}
            lastStep={this.props.lastStep}
          />
        )}

        {!inShowPopList && (
          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
          />
        )}
      </div>
    );
  }
}

export default QuestionAgentLaforet;
