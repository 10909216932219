/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import Amount from 'components/Amount';
import SuccessPage from 'components/success/SuccessPage';
import PrimeBlock from 'components/success/PrimeBlock';
class SuccessAudit extends React.Component {
  render() {
    const APIresult = this.props.result.data;
    const price1 = this.props.answers.Q43
      ? this.props.answers.Q43
      : APIresult['Coût travaux unitaire (€)'];
    const price2 = APIresult['Montant total prime (€)'];
    const price3 = APIresult['Reste à charge unitaire (€)'];
    const pricePromo = APIresult['Coût travaux promotion (€)'];

    return (
      <SuccessPage {...this.props}>
        {/* {this.props.success !== 'AuditEnergetique' && (
              <h3
                className="mt-10 is-hidden-mobile"
                style={{ lineHeight: '1.1em' }}
              >
                {dataSuccess[this.props.success]['h2']}
              </h3>
            )} */}
        <div className="success-item-right">
          <h5>
            {this.props.success === 'AuditEnergetique' ||
            this.props.success === 'RenovationGlobale'
              ? 'Coût de l’audit énergétique'
              : 'Estimation des coûts de travaux'}
          </h5>
          <div>
            {!pricePromo && (
              <h5 className="h5-price text-main whitespace-nowrap">
                <Amount value={price1} />
                <span
                  className={
                    this?.props?.answers?.Q48 === 'Audit règlementaire'
                      ? ''
                      : 'is-hidden'
                  }
                  style={{ fontSize: '14px' }}
                >
                  {' '}
                  TTC
                </span>
              </h5>
            )}
            {pricePromo && (
              <div>
                <h2 className="whitespace-nowrap">
                  <del>
                    <Amount value={price1} />
                  </del>
                </h2>
                <h1
                  style={{ lineHeight: '1em' }}
                  className="text-sub2 whitespace-nowrap"
                >
                  <Amount value={pricePromo} />{' '}
                  {dataSuccess[this.props.success].showM2 && (
                    <span className="second">/ m²</span>
                  )}
                </h1>
              </div>
            )}
          </div>
        </div>
        {pricePromo && (
          <p className="text-sub2 is-light text-s mt-5">
            <i>
              offre limitée jusqu&apos;au <strong>31 Mars 2023</strong>
            </i>
          </p>
        )}
        <PrimeBlock
          APIresult={APIresult}
          success={this.props.success}
          resultCodePromo={this.props.resultCodePromo}
        ></PrimeBlock>

        <div
          className={
            (price2 == null &&
              !this.props.resultCodePromo.data.reste_a_charge) ||
            this.props.resultCodePromo.data.reste_a_charge === price3
              ? 'is-hidden'
              : ''
          }
        >
          <div className="success-item-right last">
            <h5>Reste à payer</h5>
            <div>
              {this.props.resultCodePromo.data.reste_a_charge && (
                <h5 className="h5-price whitespace-nowrap">
                  <Amount
                    value={this.props.resultCodePromo.data.reste_a_charge}
                  />
                </h5>
              )}

              {!this.props.resultCodePromo.data.reste_a_charge && (
                <h5 className="h5-price whitespace-nowrap">
                  <Amount value={price3} />
                </h5>
              )}
            </div>
          </div>
        </div>
      </SuccessPage>
    );
  }
}

export default SuccessAudit;
