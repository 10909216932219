/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import SuccessNormal from 'components/success/SuccessNormal';
import SuccessComment from 'components/success/SuccessComment';
import SuccessBorne from 'components/success/SuccessBorne';
import SuccessAudit from 'components/success/SuccessAudit';
import SuccessPhotovoltaique from 'components/success-v2/SuccessPhotovoltaique';
import SuccessCopro from 'components/success/SuccessCopro';
import SuccessFile from 'components/success/SuccessFile';

class Success extends React.Component {
  renderSuccess(current) {
    const props = {
      ...this.props,
      isPrime:
        this.props?.answers?.Q1 === 'primeHellio' ||
        this.props?.answers?.Q30 === 'primeHellio',
    };
    switch (dataSuccess[current].template) {
      case 'normal':
        return <SuccessNormal {...props} />;
      case 'photovoltaique':
        return (
          <SuccessPhotovoltaique
            {...props}
            requestCallBack={props.callBtn}
            result={props.result}
            reset={props.reset}
          />
        );
      case 'borne':
        return <SuccessBorne {...props} />;
      case 'copro':
        return <SuccessCopro {...props} />;
      case 'audit':
        return <SuccessAudit {...props} />;
      case 'comment':
        return (
          <SuccessComment
            {...props}
            comment={props.comment || dataSuccess[current].comment}
            showPrime={dataSuccess[current].showPrime}
          />
        );
      case 'file':
        return <SuccessFile {...this.props} />;
      default:
        return <h1>{this.props.success}</h1>;
    }
  }

  render() {
    return (
      <div>
        {dataSuccess[this.props.success] && (
          <div>{this.renderSuccess(this.props.success)}</div>
        )}

        {!dataSuccess[this.props.success] && (
          <div className="p-50">
            <h1>{this.props.success}</h1>
          </div>
        )}
      </div>
    );
  }
}

export default Success;
