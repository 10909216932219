import React from 'react';
import Navigation from './Navigation';

class QuestionsNonPicto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
    };
  }

  showHover = () => {
    this.setState({ display: 'block' });
  };

  hideHover = () => {
    this.setState({ display: 'none' });
  };

  render() {
    const items1 = this.props.dataItems[this.props.name].items.map((item) => (
      <div
        className="col is-half item-container-Q45"
        key={item.long || item.short}
      >
        <label>
          <div
            className={
              this.props.data == item.short ? 'item item-checked' : 'item'
            }
          >
            <span>{item.long}</span>
          </div>
          <input
            type="radio"
            name={this.props.name}
            value={item.short}
            onChange={this.props.handleData}
            className="is-hidden"
          />
        </label>
      </div>
    ));

    return (
      <div
        className="fadeIn"
        style={{
          paddingBottom: '100px',
          width: '692px',
          margin: 'auto auto',
          maxWidth: '100%',
        }}
      >
        <div className="row">{items1}</div>

        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
          leadsource={this.props.leadsource}
          lastStepPrequestion={this.props.lastStepPrequestion}
        />
      </div>
    );
  }
}

export default QuestionsNonPicto;
