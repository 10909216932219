import React from 'react';
import Navigation from './Navigation';
import InfoTooltip from 'components/InfoTooltip';
class QuestionsTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
    };
  }

  showHover = () => {
    this.setState({ display: 'block' });
  };

  hideHover = () => {
    this.setState({ display: 'none' });
  };

  render() {
    const itemsMobile = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="w-100 d-flex aic" key={item.long || item.short}>
          <label style={{ cursor: 'pointer' }} className="w-100 mt-5">
            <div className="w-100 d-flex aic" key={item.long || item.short}>
              <div className="d-flex flex-start">
                {item.icon && (
                  <>
                    <input
                      type="radio"
                      name={this.props.name}
                      value={item.short}
                      onChange={this.props.handleData}
                      className=""
                    />
                    <div className="img">
                      <img src={item.icon} alt={item.short} />
                    </div>
                    <span
                      className={
                        this.props.data == item.short ? 'text-sub' : ''
                      }
                    >
                      {item.long}
                    </span>
                  </>
                )}
                {item.tooltip && (
                  <InfoTooltip className="tooltip-bailleur">
                    <h3>Propriétaire bailleur : </h3>
                    <p className="mt-10">
                      <strong>Un bailleur</strong> désigne une personne physique
                      ou morale (banque, mutuelle, compagnie d&apos;assurance),
                      généralement <strong>propriétaire</strong> d&apos;un bien
                      immobilier et qui met celui-ci en location.
                    </p>
                  </InfoTooltip>
                )}
              </div>
            </div>
          </label>
        </div>
      ),
    );

    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="mb-30 mr-10" key={item.long || item.short}>
        <label style={{ cursor: 'pointer' }}>
          <div className="d-flex">
            <div className="d-flex flex-start">
              {item.icon && (
                <>
                  <input
                    type="radio"
                    name={this.props.name}
                    value={item.short}
                    onChange={this.props.handleData}
                    className=""
                  />
                  <div className="img">
                    <img src={item.icon} alt={item.short} />
                  </div>
                  <span
                    className={this.props.data == item.short ? 'text-sub' : ''}
                  >
                    {item.long}
                  </span>
                </>
              )}
              {item.tooltip && (
                <InfoTooltip className="tooltip-bailleur">
                  <h3>Propriétaire bailleur : </h3>
                  <p className="mt-10">
                    <strong>Un bailleur</strong> désigne une personne physique
                    ou morale (banque, mutuelle, compagnie d&apos;assurance),
                    généralement <strong>propriétaire</strong> d&apos;un bien
                    immobilier et qui met celui-ci en location.
                  </p>
                </InfoTooltip>
              )}
            </div>
          </div>
        </label>
      </div>
    ));

    const itemsLast = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="w-100 d-flex jcc aic" key={item.long || item.short}>
          <label style={{ cursor: 'pointer' }}>
            {!item.icon && (
              <>
                <input
                  type="radio"
                  name={this.props.name}
                  value={item.short}
                  onChange={this.props.handleData}
                  className=""
                />
                <span>{item.long}</span>
              </>
            )}
          </label>
        </div>
      ),
    );

    const itemsLastMobile = this.props.dataItems[this.props.name].items.map(
      (item) => (
        <div className="w-100 d-flex" key={item.long || item.short}>
          <label style={{ cursor: 'pointer' }}>
            {!item.icon && (
              <>
                <input
                  type="radio"
                  name={this.props.name}
                  value={item.short}
                  onChange={this.props.handleData}
                  className=""
                />
                <span>{item.long}</span>
              </>
            )}
          </label>
        </div>
      ),
    );

    return (
      <div className="fadeIn">
        <div className="item-wrapper jcc is-hidden-mobile pt-20">
          {items}
          {itemsLast}
        </div>
        <div className="only-show-mobile d-flex aic jcc">
          <div style={{ maxWidth: '400px' }}>
            {itemsMobile}
            {itemsLastMobile}
          </div>
        </div>

        <Navigation
          data={this.props.data}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
          leadsource={this.props.leadsource}
          lastStepPrequestion={this.props.lastStepPrequestion}
        />
      </div>
    );
  }
}

export default QuestionsTags;
