import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSimulation } from '../../../providers/SimulationProvider';

export default function Reminder({ className }) {
  const { handleCallBtn, handleCreneauBtn } = useSimulation();

  return (
    <div className={clsx('flex items-center gap-1.5', className)}>
      <button
        className="border-none cursor-pointer bg-xblue rounded-full text-white px-6 py-4 text-[16px] font-medium leading-none"
        onClick={handleCallBtn}
      >
        Être rappelé
      </button>
      <button
        onClick={handleCreneauBtn}
        className="bg-transparent border-none text-white underline cursor-pointer"
      >
        Choisir un créneau pour être rappelé
      </button>
    </div>
  );
}

Reminder.propTypes = {
  className: PropTypes.string,
};
