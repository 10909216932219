/* eslint-disable react/prop-types */
import React from 'react';
import setMarque from '../../data/setMarque';
class CodePromo extends React.Component {
  render() {
    // console.log('set', setMarque[this.props.marqueHost]['removeCodePromo'])
    const removeCodePromo = setMarque[this.props.marqueHost].removeCodePromo;
    if (!this.props.resultCodePromo.success) {
      return (
        <div
          className={
            removeCodePromo
              ? 'codePromo-container is-hidden'
              : 'codePromo-container'
          }
        >
          <div className="codePromo-item">
            <div className="d-flex">
              <input
                type="text"
                value={this.props.codePromo}
                name="codePromo"
                onChange={this.props.handleCodePromo}
                placeholder="Code parrain ou promo"
                className={this.props.codePromo ? 'valid' : ''}
              />
              <button
                onClick={this.props.validCodePromo}
                className={this.props.codePromo ? 'valid' : ''}
              >
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.14752 0L6.46671 1.6576L10.8085 6.01688H0V8.37181H10.8085L6.46671 12.7311L8.14752 14.3887L15.3149 7.19435L8.14752 0Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>

          {this.props.resultCodePromo.success === 'false' && <p>Une erreur</p>}
        </div>
      );
    } else {
      const valid =
        this.props.resultCodePromo.data.is_code_parrain ||
        this.props.resultCodePromo.data.is_code_promo;
      // const hasData = this.props.codePromo !== undefined;
      // const valid = this.props.resultCodePromo['success'];
      return (
        <div
          className={
            removeCodePromo
              ? 'codePromo-container is-hidden'
              : 'codePromo-container'
          }
        >
          <div className="codePromo-item">
            <div className="d-flex">
              <input
                type="text"
                value={this.props.codePromo}
                name="codePromo"
                onChange={this.props.handleCodePromo}
                className={valid ? 'valid' : 'notvalid'}
                placeholder="Code parrain ou promo"
              />
              <button
                onClick={this.props.validCodePromo}
                className={this.props.codePromo ? 'valid' : ''}
              >
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.14752 0L6.46671 1.6576L10.8085 6.01688H0V8.37181H10.8085L6.46671 12.7311L8.14752 14.3887L15.3149 7.19435L8.14752 0Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>

          {valid && (
            <p className="valid">
              Code validé.
              <a
                href="https://particulier.hellio.com/conditions-generales-parrainage"
                target="_blank"
                rel="noreferrer"
              >
                Voir les conditions
              </a>
              .
            </p>
          )}

          {!valid && (
            <p className="notvalid">Désolé, ce code n’est pas valide</p>
          )}
        </div>
      );
    }
  }
}

export default CodePromo;
