import React from 'react';
import Navigation from 'components/Navigation';
class Redirect extends React.Component {
  render() {
    return (
      <div className="fadeIn">
        <div className={'flex column items-center gap-16 min-w-full'}>
          <img
            className={'max-w-80'}
            src={this.props.dataItems[this.props.name].image}
            alt="L'énergie au coeur de votre projet"
          />
          <p
            style={{
              fontSize: '24px',
              lineHeight: '28px',
              textAlign: 'center',
              color: '#006FBA',
            }}
          >
            {this.props.dataItems[this.props.name].text}
          </p>
        </div>

        <Navigation
          data={true}
          step={this.props.step}
          nextStep={() =>
            window.open(
              this.props.dataItems[this.props.name].redirect,
              '_blank',
            )
          }
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
          leadsource={this.props.leadsource}
          lastStepPrequestion={this.props.lastStepPrequestion}
        />
      </div>
    );
  }
}

export default Redirect;
