import React from 'react';

class Loader extends React.Component {
  render() {
    return (
      <div className="align-center p-50" style={{ minHeight: '400px' }}>
        <h1 className="text-main mt-50" style={{ fontSize: '1.4em' }}>
          Chargement en cours, veuillez patienter...
        </h1>
        <img
          src="https://hellio.com/hubfs/simulateur/images/spinnerBlue.svg"
          alt="loading"
        />
      </div>
    );
  }
}

export default Loader;
