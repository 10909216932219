import React from 'react';
import { useSimulation } from 'providers/SimulationProvider';
import SuccessPhotovoltaique from 'components/success-v2/SuccessPhotovoltaique';

// Conteneur principal de toutes les pages success
export default function SuccessMain() {
  const { successTemplate } = useSimulation();
  switch (successTemplate) {
    case 'photovoltaique':
      return <SuccessPhotovoltaique />;
    default:
      return null;
  }
}
