import React from 'react';
import PropTypes from 'prop-types';

export default function Policy({ onChange }) {
  return (
    <div className={'flex gap-4'}>
      <input onChange={onChange} type="checkbox" id={'policy'} />
      <label className="text-xs max-w-[350px]" htmlFor={'policy'}>
        J'accepte d'être recontacté par Hellio ou l'un de ses partenaires. Pour
        plus d'informations, je peux consulter la{' '}
        <a
          className={'text-main'}
          target={'_blank'}
          href="https://www.hellio.com/politique-confidentialite"
          rel="noreferrer"
        >
          Politique de confidentialité
        </a>{' '}
        de Hellio
      </label>
    </div>
  );
}

Policy.propTypes = {
  onChange: PropTypes.func.isRequired,
};
