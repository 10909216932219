import React from 'react';
import Navigation from 'components/Navigation';
class QR38 extends React.Component {
  render() {
    return (
      <div>
        <div className="final-container">
          <div className="iconColor1">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4540_11019)">
                <path
                  d="M36.751 5.58778C32.824 2.26578 27.676 0.175781 22 0.175781C16.324 0.175781 11.176 2.26578 7.249 5.58778C2.794 9.60278 0 15.4548 0 21.9118C0 29.9418 4.191 36.8388 10.56 40.7658C13.882 42.6908 17.809 43.8238 22 43.8238C26.191 43.8238 30.118 42.6908 33.44 40.7658C39.809 36.9268 44 29.9418 44 21.9998C44 15.4548 41.206 9.60278 36.751 5.58778ZM18.425 33.4398L7.689 22.7918L10.131 20.3498L18.425 28.4678L34.133 13.0898L36.575 15.5318L18.425 33.4398Z"
                  fill="#0070BA"
                />
              </g>
              <defs>
                <clipPath id="clip0_4540_11019">
                  <rect width="44" height="44" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <h2>Votre inscription a bien été prise en compte. </h2>
          <p>
            Un expert Hellio vous rappelera dans les plus brefs <br />
            délais pour donner suite à votre projet.
          </p>
        </div>
        {/* <div className='mv-20 d-flex jcc flex-end is-hidden-mobile'>
          <button className="btn-retour" onClick={this.props.lastStep}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="19" fill="white"/>
            <path d="M17.2012 13.3345L10.9819 19.0468L17.2012 24.6654" stroke="#FBA03D" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M12.2924 19.0469L27.0186 19.0469" stroke="#FBA03D" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
            </svg>
            </button>

            <a href="https://particulier.hellio.com/" target="_blank" rel="noreferrer" className='btn-aller is-bold no-underline'>Retour au site Hellio</a>
          </div> */}

        {/* <div className='only-show-mobile' style={{marginTop: '30px'}}>
          <div className='navigation-mobile'>
          <button className="btn-nav" onClick={this.props.lastStep}>
              <svg width="49" height="49" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.08984" y="0.674194" width="49.2822" height="51.0884" rx="24.6411" fill="white"/>
                <path d="M23.4404 18.0444L15.5177 26.2857L23.4404 34.392" stroke="#FBA03D" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
                <path d="M17.1876 26.2861L35.9473 26.2861" stroke="#FBA03D" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
              </svg>
            </button>
            <a href="https://particulier.hellio.com/" target="_blank" rel="noreferrer"  className="btn-nav-m pt-10 align-center" style={{textDecoration:'none'}}>
                <span className='text-normal'>Retour au site Hellio</span>
                <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7109 19.2052L22.6337 10.9639L14.7109 2.85763" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
              <path d="M20.9638 10.964L2.2041 10.964" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
              </svg>
              </a>
          </div>
        </div> */}
        <Navigation
          data={this.props.data}
          step={this.props.step}
          lastStep={this.props.lastStep}
          last={true}
        />
      </div>
    );
  }
}

export default QR38;
