const dataAgentLaforet = [
  ['OC9', 'Saint-Paul-lès-Dax', 'OC9 ST PAUL LES DAX'],
  ['SB4', "L'Isle-d'Abeau", 'SB4 LISLE'],
  ['OA1', 'Conches-en-Ouche', 'OA1 CONCHES EN OUCHE'],
  ['SE6', 'Lorient', 'SE6 LORIENT 2'],
  ['SI8', 'La Chapelle-Saint-Laurent', 'SI8 LA CHAPELLE SAINT LAURENT'],
  ['QN2', 'Gréasque', 'QN2 GREASQUE'],
  ['PV4', 'Martigues La Couronne', 'PV4 LA COURONNE'],
  ['RN7', 'Carqueiranne', 'RN7 CARQUEIRANNE'],
  ['SI7', 'Saint-Varent', 'SI7 SAINT-VARENT'],
  ['SI6', 'Mauléon', 'SI6 MAULEON'],
  ['SL4', 'Plouhinec', 'SL4 PLOUHINEC'],
  ['PD1', 'Locminé', 'PD1 LOCMINE'],
  ['OH6', 'Riantec', 'OH6 RIANTEC'],
  ['OH4', 'Plouay', 'OH4 PLOUAY'],
  ['N61', 'Lanester', 'N61 LANESTER'],
  ['SH0', 'Nanterre', 'SH0 NANTERRE MONT VALERIEN'],
  ['RD1', 'Pélissanne', 'RD1 PELISSANNE'],
  ['SE5', 'Sarrebourg', 'SE5 SARREBOURG'],
  ['SE4', 'Thionville', 'SE4 THIONVILLE'],
  ['SE3', 'Audun-le-Tiche', 'SE3 AUDUN LE TICHE'],
  ['SE2', 'Saint-Avold', 'SE2 SAINT AVOLD'],
  ['SD9', 'Jarny', 'SD9 JARNY'],
  ['G97', 'Hennebont', 'G97 HENNEBONT'],
  ['QB8', 'Quetigny', 'QB8 QUETIGNY'],
  ['RL5', 'Mornant', 'RL5 MORNANT'],
  ['SK7', 'Plonéour-Lanvern', 'SK7 PLONEOUR-LANVERN'],
  ['SF7', 'Guilvinec', 'SF7 GUILVINEC'],
  ['OG1', 'VEUZAIN SUR LOIRE', 'OG1 ONZAIN'],
  ['OA4', 'LE CONTROIS EN SOLOGNE', 'OA4 CONTRES'],
  ['OV5', 'Sainte-Menehould', 'OV5 SAINTE MENEHOULD'],
  ['QV7', 'Andrézieux-Bouthéon', 'QV7 ANDREZIEUX'],
  ['RV3', 'Saint-Martin-en-Haut', 'RV3 SAINT MARTIN EN HAUT'],
  ['OK8', 'Saint-Laurent-de-Chamousset', 'OK8 SAINT LAURENT'],
  ['RV6', 'Levallois-Perret', 'RV6 LEVALLOIS-PERRET'],
  ['RW1', 'Salins-les-Bains', 'RW1 SALINS LES BAINS'],
  ['RI9', 'Joinville', 'RI9 JOINVILLE'],
  ['PP5', 'Montfort-sur-Risle', 'PP5 MONTFORT SUR RISLE'],
  ['QW1', 'Rioz', 'QW1 RIOZ'],
  ['QU3', 'Roquebrune-Cap-Martin', 'QU3 ROQUEBRUNE-CAP-MARTIN'],
  ['PW5', 'Marignane', 'PW5 MARIGNANE'],
  ['QT1', 'Combourg', 'QT1 COMBOURG'],
  ['G92', 'Clamart', 'G92 CLAMART'],
  ['G88', 'La Rochelle', 'G88 LA ROCHELLE'],
  ['LC4', 'Besançon', 'LC4 BESANCON'],
  ['LB7', 'Arras', 'LB7 ARRAS'],
  ['LI9', 'Levallois-Perret', 'LI9 LEVALLOIS'],
  ['G96', 'Lorient', 'G96 LORIENT'],
  ['P15', 'Châlons-en-Champagne', 'P15 CHALONS EN CHAMPAGNE'],
  ['B06', 'Pessac', 'B06 PESSAC'],
  ['ML7', 'Lyon 09', 'ML7 LYON 9'],
  ['LG8', 'Fontenay-aux-Roses', 'LG8 FONTENAY AUX ROSES'],
  ['LG3', 'Draguignan', 'LG3 DRAGUIGNAN'],
  ['LS7', 'Salon-de-Provence', 'LS7 SALON DE PROVENCE'],
  ['J30', 'Paris 19', 'J30 JOURDAIN'],
  ['LV6', 'Franconville', 'LV6 FRANCONVILLE'],
  ['ME1', 'Vitry-sur-Seine', 'ME1 VITRY SUR SEINE'],
  ['LD9', 'Bourg-en-Bresse', 'LD9 BOURG EN BRESSE'],
  ['MG8', 'Montauban', 'MG8 MONTAUBAN'],
  ['K81', 'Paris 14', 'K81 PARIS BOULARD'],
  ['MD3', 'Auxerre', 'MD3 AUXERRE'],
  ['J93', 'Nogent-sur-Marne', 'J93 NOGENT-SUR-MARNE'],
  ['J40', 'Mérignac', 'J40 MERIGNAC'],
  ['ME7', 'Bernay', 'ME7 BERNAY'],
  ['E36', 'Lyon 08', 'E36 LYON 8'],
  ['MJ6', 'Carcassonne', 'MJ6 CARCASSONNE'],
  ['MN9', 'Palaiseau', 'MN9 PALAISEAU'],
  ['Q22', "L'Isle-Adam", 'Q22 LISLE ADAM'],
  ['N63', 'Castelnau-le-Lez', 'N63 CASTELNAU LE LEZ'],
  ['P16', 'Vitry-le-François', 'P16 VITRY LE FRANCOIS'],
  ['MG6', 'Menton', 'MG6 MENTON'],
  ['E03', 'Dijon', 'E03 DIJON-MONGE'],
  ['Q33', 'Viry-Châtillon', 'Q33 VIRY CHATILLON'],
  ['QO5', 'Cergy', 'QO5 CERGY'],
  ['MV3', 'Sausset-les-Pins', 'MV3 SAUSSET LES PINS'],
  ['NB4', 'Ermont', 'NB4 ERMONT'],
  ['J46', 'Argenteuil', 'J46 ARGENTEUIL'],
  ['MX2', 'Crépy-en-Valois', 'MX2 CREPY EN VALOIS'],
  ['LQ7', 'Sarcelles', 'LQ7 SARCELLES'],
  ['MX6', 'Orléans', 'MX6 ORLEANS'],
  ['NF9', 'Paris 09', 'NF9 PARIS 09 MARTYRS'],
  ['MD4', 'Dax', 'MD4 DAX'],
  ['NB9', 'Saint-André-de-Cubzac', 'NB9 SAINT ANDRE DE CUBZAC'],
  ['NF1', 'Ancenis', 'NF1 ANCENIS'],
  ['H24', 'Paris 03', 'H24 PARIS 03 MARAIS'],
  ['NQ5', 'Moulins', 'NQ5 MOULINS'],
  ['NJ8', 'Chilly-Mazarin', 'NJ8 CHILLY-MAZARIN'],
  ['NQ3', 'Le Mesnil-Esnard', 'NQ3 LE MESNIL ESNARD'],
  ['OT2', 'Bobigny', 'OT2 BOBIGNY'],
  ['NQ2', 'Argelès-sur-Mer', 'NQ2 ARGELES SUR MER'],
  ['NJ1', 'Noisy-le-Sec', 'NJ1 NOISY LE SEC'],
  ['P64', 'Alès', 'P64 ALES'],
  ['NV1', 'Cabourg', 'NV1 CABOURG'],
  ['X82', 'Caen', 'X82 CAEN'],
  ['NV8', 'Tarbes', 'NV8 TARBES'],
  ['OC3', 'Pont-Audemer', 'OC3 PONT-AUDEMER'],
  ['T66', 'Aulnay-sous-Bois', 'T66 AULNAY'],
  ['OE1', 'Goussainville', 'OE1 GOUSSAINVILLE'],
  ['ND9', 'Gardanne', 'ND9 GARDANNE'],
  ['OI9', 'Marseille 07', 'OI9 MARSEILLE 07'],
  ['NZ6', 'Charenton-le-Pont', 'NZ6 CHARENTON LE PONT'],
  ['OC1', 'Paris 07', 'OC1 PRESTIGE PARIS 7'],
  ['PI4', 'Bry-sur-Marne', 'PI4 BRY SUR MARNE'],
  ['J92', 'Le Perreux-sur-Marne', 'J92 LE PERREUX'],
  ['J97', 'Paris 19', 'J97 LAUMIERE'],
  ['OC2', 'Bruguières', 'OC2 BRUGUIERES'],
  ['LB6', 'Arpajon', 'LB6 ARPAJON'],
  ['QX4', 'Nancy', 'QX4 NANCY PLACE DES VOSGES'],
  ['ON4', 'Magny-en-Vexin', 'ON4 MAGNY EN VEXIN'],
  ['NX7', 'Ouistreham', 'NX7 OUISTREHAM'],
  ['OF4', 'Redon', 'OF4 REDON'],
  ['OP5', 'Pontivy', 'OP5 PONTIVY'],
  ['OW4', 'Dol-de-Bretagne', 'OW4 DOL DE BRETAGNE'],
  ['OQ5', 'Paris 15', 'OQ5 PARIS 15 LOURMEL'],
  ['OQ4', 'Étampes', 'OQ4 ETAMPES'],
  ['OL7', 'Toulouse', 'OL7 TOULOUSE BONNEFOY'],
  ['OS5', 'Vesoul', 'OS5 VESOUL'],
  ['OR4', 'Paris 06', 'OR4 PARIS 6'],
  ['NH9', 'Nogent-sur-Oise', 'NH9 NOGENT-SUR-OISE'],
  ['LA7', 'Aix-les-Bains', 'LA7 AIX LES BAINS'],
  ['OU4', 'Orthez', 'OU4 ORTHEZ'],
  ['OW2', 'Talence', 'OW2 TALENCE'],
  ['PH1', 'Saint-Just-Saint-Rambert', 'PH1 SAINT-JUST'],
  ['PG8', 'Rosny-sous-Bois', 'PG8 ROSNY-SOUS-BOIS'],
  ['PB4', 'Colmar', 'PB4 COLMAR'],
  ['OR3', 'Sèvres', 'OR3 SEVRES'],
  ['PE5', 'Ploërmel', 'PE5 PLOERMEL'],
  ['PC3', 'Périgueux', 'PC3 PERIGUEUX'],
  ['OY6', 'Meudon', 'OY6 MEUDON'],
  ['PF7', 'Paris 18', 'PF7 PARIS 18 ABBESSES'],
  ['PD5', 'Paris 18', 'PD5 PARIS 18 MONTMARTRE'],
  ['PK4', 'Fort-de-France', 'PK4 FORT DE FRANCE - CLUNY'],
  ['PG3', 'Lieusaint', 'PG3 GESTION SENART'],
  ['PG7', 'Lisieux', 'PG7 LISIEUX'],
  ['PH5', 'Concarneau', 'PH5 CONCARNEAU'],
  ['PO6', "Villenave-d'Ornon", 'PO6 VILLENAVEDORNON'],
  ['PT9', 'Rouen', 'PT9 ROUEN DROITE'],
  ['PS5', 'Nérac', 'PS5 NERAC'],
  ['PG9', 'Bourgoin-Jallieu', 'PG9 BOURGOIN JALLIEU'],
  ['PM9', 'Fécamp', 'PM9 FECAMP'],
  ['J44', 'Paris 14', 'J44 PARIS 14 LOSSERAND'],
  ['PV5', 'Colomiers', 'PV5 COLOMIERS'],
  ['PR4', 'Solliès-Pont', 'PR4 SOLLIES-PONT'],
  ['PM8', 'Saint-Jean', 'PM8 SAINT JEAN'],
  ['PP7', 'Caussade', 'PP7 CAUSSADE'],
  ['PL3', 'Ambérieu-en-Bugey', 'PL3 AMBERIEU EN BUGEY'],
  ['QB1', 'Guer', 'QB1 GUER'],
  ['P75', 'Blois', 'P75 AGENCE DU CENTRE - BLOIS 1'],
  ['QE7', 'Le Chesnay', 'QE7 LE CHESNAY'],
  ['PX1', 'Orange', 'PX1 ORANGE'],
  ['PV3', 'Lattes', 'PV3 LATTES'],
  ['PW7', 'Paris 11', 'PW7 PARIS 11 VOLTAIRE'],
  ['PT1', 'Marseille 06', 'PT1 MARSEILLE 06'],
  ['QF9', 'Le Boulou', 'QF9 LE BOULOU'],
  ['QA4', 'Chauny', 'QA4 CHAUNY'],
  ['QH6', 'Le Lamentin', 'QH6 LE LAMENTIN'],
  ['QN1', 'Brive-la-Gaillarde', 'QN1 BRIVE'],
  ['PW6', 'Le Cannet-des-Maures', 'PW6 LE CANNET DES MAURES'],
  ['QD6', 'La Tour-du-Pin', 'QD6 LA TOUR DUPIN'],
  ['PK9', 'Rivesaltes', 'PK9 RIVESALTES'],
  ['QC4', 'Villemur-sur-Tarn', 'QC4 VILLEMUR SUR TARN'],
  ['QG3', 'Nort-sur-Erdre', 'QG3 NORT SUR ERDRE'],
  ['QE2', 'Morsang-sur-Orge', 'QE2 MORSANG'],
  ['PW8', "LES MONTS D'AUNAY", "PW8 LES MONTS D'AUNAY"],
  ['QP1', 'Vernon', 'QP1 VERNON'],
  ['RT5', 'Rueil-Malmaison', 'RT5 RUEIL 65'],
  ['QH3', 'Plérin', 'QH3 PLERIN'],
  ['QH1', 'Rueil-Malmaison', 'QH1 RUEIL 106'],
  ['PT3', 'Nyons', 'PT3 NYONS'],
  ['QQ9', 'Paris 04', 'QQ9 PARIS 04'],
  ['OY5', 'Montgeron', 'OY5 MONTGERON'],
  ['QL5', 'Château-Thierry', 'QL5 CHATEAU-THIERRY'],
  ['QO2', 'Falaise', 'QO2 FALAISE'],
  ['U08', 'Colombes', 'U08 COLOMBES'],
  ['Q21', 'Chantilly', 'Q21 CHANTILLY'],
  ['N88', 'Nice', 'N88 NICE'],
  ['QV2', 'Paris 13', 'QV2 PARIS 13 JEANNE DARC'],
  ['QQ5', "Pont-l'Abbé", "QQ5 PONT L'ABBE"],
  ['RL8', 'Penmarch', 'RL8 PENMARCH'],
  ['QL7', 'Balma', 'QL7 BALMA'],
  ['QV8', 'Albi', 'QV8 ALBI'],
  ['QX2', 'Muret', 'QX2 MURET'],
  ['QU9', 'Massy', 'QU9 MASSY'],
  ['QW9', 'Corbeil-Essonnes', 'QW9 CORBEIL'],
  ['QW4', 'Versailles', 'QW4 VERSAILLES'],
  ['QX3', 'Prats-de-Mollo-la-Preste', 'QX3 PRATSDEMOLLO'],
  ['QX1', 'Paris 12', 'QX1 PARIS 12 DAUMESNIL'],
  ['QR4', 'Bastia', 'QR4 BASTIA'],
  ['QX7', 'Lyon 08', 'QX7 LYON 8 LOC'],
  ['QU6', 'Elbeuf', 'QU6 ELBEUF'],
  ['QY9', 'Nanterre', 'QY9 NANTERRE'],
  ['QY7', 'Gujan-Mestras', 'QY7 GUJAN'],
  ['LN1', 'Issoire', 'LN1 ISSOIRE'],
  ['OM8', 'Brioude', 'OM8 BRIOUDE'],
  ['RA8', 'Saint-Vit', 'RA8 SAINT VIT'],
  ['E04', 'Dijon', 'E04 DIJON'],
  ['QZ5', 'Paris 13', 'QZ5 PARIS 13 ARAGO'],
  ['QZ4', 'Suresnes', 'QZ4 SURESNES'],
  ['RA9', 'Rodez', 'RA9 RODEZ'],
  ['RA2', 'Paris 12', 'RA2 PARIS 12 GARE DE LYON'],
  ['RB9', 'Bondy', 'RB9 BONDY'],
  ['LP8', 'Plaisir', 'LP8 PLAISIR'],
  ['QY5', 'Quimper', 'QY5 QUIMPER'],
  ['RD4', 'Châtillon-sur-Chalaronne', 'RD4 CHATILLON-SUR-CHALARONNE'],
  ['RC2', 'Fosses', 'RC2 FOSSES'],
  ['QV1', 'La Gacilly', 'QV1 LA GACILLY'],
  ['RD5', 'Gaillon', 'RD5 GAILLON'],
  ['NT7', 'Blagnac', 'NT7 BLAGNAC'],
  ['RH5', 'Gémozac', 'RH5 GEMOZAC'],
  ['RB4', 'Saint-Juéry', 'RB4 SAINT JUERY'],
  ['ML9', 'Vitrolles', 'ML9 VITROLLES'],
  ['RE2', 'Gonesse', 'RE2 GONESSE'],
  ['RB3', 'Houilles', 'RB3 HOUILLES'],
  ['RF2', 'Martigues', 'RF2 MARTIGUES'],
  ['RG4', "Pont-l'Évêque", "RG4 PONT-L'EVEQUE"],
  ['RG8', 'Gagny', 'RG8 GAGNY'],
  ['RG9', 'Gagny', 'RG9 GAGNY 2'],
  ['RH8', 'Brétigny-sur-Orge', 'RH8 BRETIGNY'],
  ['RH9', 'Langeac', 'RH9 LANGEAC'],
  ['SC5', 'La Forêt-Fouesnant', 'SC5 LA FORET FOUESNANT'],
  ['RI8', 'Langres', 'RI8 LANGRES'],
  ['RI7', 'Chaumont', 'RI7 CHAUMONT'],
  ['RL1', 'Les Angles', 'RL1 LES ANGLES'],
  ['RK2', 'Paris 12', 'RK2 PARIS 12 NATION'],
  ['SA4', 'Morsang-sur-Orge', 'SA4 MORSANG GESTION'],
  ['RI1', 'Orsay', 'RI1 ORSAY'],
  ['OD7', 'Meulan', 'OD7 MEULAN'],
  ['RL2', 'Brignais', 'RL2 BRIGNAIS'],
  ['RM2', 'Paris 14', 'RM2 PARIS 14 ALESIA'],
  ['RK9', 'Bourg-Saint-Maurice', 'RK9 BOURG SAINT MAURICE'],
  ['RJ1', 'Valenciennes', 'RJ1 VALENCIENNES'],
  ['RG3', 'Courseulles-sur-Mer', 'RG3 COURSEULLES'],
  ['RM5', 'Neauphle-le-Château', 'RM5 NEAUPHLE'],
  ['MY8', 'Limoges', 'MY8 LIMOGES'],
  ['RN6', 'La Garde', 'RN6 LA GARDE'],
  ['F61', 'Saint-Cloud', 'F61 SAINT CLOUD'],
  ['RN3', 'Saint-Raphaël', 'RN3 SAINT-RAPHAEL'],
  ['RN9', 'Prades', 'RN9 PRADES'],
  ['RQ6', 'Saint-Denis', 'RQ6 SAINT-DENIS'],
  ['RN2', 'Fontenay-sous-Bois', 'RN2 FONTENAY SOUS BOIS'],
  ['RP6', 'Cagnes-sur-Mer', 'RP6 CAGNES SUR MER'],
  ['RQ5', 'Le Kremlin-Bicêtre', 'RQ5 LE KREMLIN BICETRE'],
  ['RQ1', 'Paris 10', 'RQ1 PARIS 10 SAINT-MARTIN'],
  ['RR7', 'Paris 17', 'RR7 PARIS 17 TERNES'],
  ['T88', 'Ris-Orangis', 'T88 RISORANGIS'],
  ['OP1', 'ANNECY', 'OP1 ANNECY SEYNOD'],
  ['RP3', 'Sotteville-lès-Rouen', 'RP3 SOTTEVILLE'],
  ['RT3', 'Saint-Quentin', 'RT3 SAINT-QUENTIN'],
  ['RS7', 'Aix-en-Provence', 'RS7 AIX EN PROVENCE'],
  ['RR2', 'Paris 16', 'RR2 PARIS 16 PASSY-MUETTE'],
  ['RS4', 'Paris 11', 'RS4 PARIS 11 BASTILLE'],
  ['RT9', 'Saint-Michel-sur-Orge', 'RT9 SAINT MICHEL'],
  ['RT8', 'Paris 09', 'RT8 PARIS 09 MOGADOR'],
  ['RS8', 'La Roche-sur-Yon', 'RS8 LAROCHESURYON'],
  ['RM1', 'Paris 02', 'RM1 PARIS 1&2'],
  ['RU2', 'Lannion', 'RU2 LANNION'],
  ['RU3', 'Pleubian', 'RU3 PLEUBIAN'],
  ['RU4', 'Guingamp', 'RU4 GUINGAMP'],
  ['RU8', 'Paris 16', 'RU8 PARIS 16 VICTOR HUGO'],
  ['RV2', 'Fougères', 'RV2 FOUGERES'],
  ['RV9', 'Penvénan', 'RV9 PENVENAN'],
  ['RU1', 'Saint-Malo', 'RU1 SAINT MALO'],
  ['RW7', 'Paimpol', 'RW7 PAIMPOL'],
  ['RZ4', 'Saint-Ouen', 'RZ4 SAINT-OUEN'],
  ['RV8', 'Taverny', 'RV8 TAVERNY'],
  ['G82', 'Paris 15', 'G82 PARIS 15 VAUGIRARD'],
  ['RE3', 'Ille-sur-Têt', 'RE3 ILLE SUR TET'],
  ['RX1', 'Cachan', 'RX1 CACHAN'],
  ['RU6', 'Baie-Mahault', 'RU6 BAIE MAHAULT'],
  ['RY4', 'Paris 11', 'RY4 PARIS 11 PARMENTIER'],
  ['RV1', 'Gennevilliers', 'RV1 GENNEVILLIERS'],
  ['RT7', 'Cesson', 'RT7 CESSON 77'],
  ['RZ2', 'Aime', 'RZ2 AIME'],
  ['RZ6', 'Fréjus', 'RZ6 FREJUS'],
  ['RP4', 'LE GRAND-QUEVILLY', 'RP4 LE GRAND-QUEVILLY'],
  ['RZ8', 'Viroflay', 'RZ8 VIROFLAY'],
  ['SA7', 'Chazelles-sur-Lyon', 'SA7 CHAZELLES SUR LYON'],
  ['RY8', 'Gradignan', 'RY8 GRADIGNAN'],
  ['SA9', 'Saint-Symphorien-sur-Coise', 'SA9 SAINT SYMPHORIEN'],
  ['G81', 'Saint-Maur-des-Fossés', 'G81 SAINT-MAUR'],
  ['SA8', 'Paris 11', 'SA8 PARIS 11 VOLTAIRE GESTION'],
  ['RX4', 'Le Vésinet', 'RX4 VESINET'],
  ['SA6', 'Linas', 'SA6 LINAS'],
  ['RY3', 'Erstein', 'RY3 ERSTEIN'],
  ['ON2', 'Thuir', 'ON2 THUIR'],
  ['RY7', 'Saint-Nazaire', 'RY7 SAINT NAZAIRE'],
  ['RX8', 'Les Pennes-Mirabeau', 'RX8 LES PENNES MIRABEAU'],
  ['RW4', 'Maisons-Alfort', 'RW4 MAISONS ALFORT'],
  ['SB5', 'Perpignan', 'SB5 PERPIGNAN'],
  ['SB3', 'Lyon 05', 'SB3 LYON 5'],
  ['RY2', 'Limours', 'RY2 LIMOURS'],
  ['SA5', 'Créteil', 'SA5 CRETEIL'],
  ['RZ7', 'Paris 11', 'RZ7 PARIS 11 ST AMBROISE'],
  ['SB6', 'Draveil', 'SB6 DRAVEIL'],
  ['PY7', 'Cayenne', 'PY7 CAYENNE'],
  ['SB2', 'Clermont', 'SB2 CLERMONT'],
  ['SA1', 'Vandoeuvre-lès-Nancy', 'SA1 VANDOEUVRE LES NANCY'],
  ['G18', 'Paris 17', 'G18 PARIS 17 VILLIERS'],
  ['SA2', 'Saint-Genis-Pouilly', 'SA2 SAINT GENIS POUILLY'],
  ['SD7', 'Armentières', 'SD7 ARMENTIERES'],
  ['SD1', 'Arpajon', 'SD1 ARPAJON GESTION'],
  ['SF5', 'Verson', 'SF5 VERSON'],
  ['SD8', 'Metz', 'SD8 METZ'],
  ['SE9', 'Epinal', 'SE9 EPINAL TERRE-LORRAINE'],
  ['SD5', 'Courbevoie', 'SD5 COURBEVOIE BECON'],
  ['SD4', 'Courbevoie', 'SD4 COURBEVOIE MARCEAU'],
  ['SC3', 'Vedène', 'SC3 VEDENE'],
  ['SB1', 'Pornic', 'SB1 PORNIC'],
  ['SD6', "Saint-Cyr-l'École", "SD6 SAINT CYR L'ECOLE"],
  ['SF3', 'Oullins', 'SF3 OULLINS'],
  ['SF1', 'Poitiers', 'SF1 POITIERS'],
  ['P80', 'Sainte-Geneviève-des-Bois', 'P80 SAINTE GENEVIEVE'],
  ['SF8', 'Caluire-et-Cuire', 'SF8 CALUIRE'],
  ['SG4', 'Thonon-les-Bains', 'SG4 THONON'],
  ['PD4', 'Lambesc', 'PD4 LAMBESC'],
  ['SH2', 'Noisy-le-Grand', 'SH2 PREMIUM GESTION'],
  ['SH3', 'Rumilly', 'SH3 RUMILLY'],
  ['SH6', 'Pantin', 'SH6 PANTIN'],
  ['SH4', 'Aucamville', 'SH4 AUCAMVILLE'],
  ['SI4', 'Beuzeville', 'SI4 BEUZEVILLE'],
  ['SH1', 'Lyon 03', 'SH1 LYON PART-DIEU'],
  ['SH8', 'Paris 20', 'SH8 PARIS 20 AVRON'],
  ['SC8', 'Biarritz', 'SC8 BIARRITZ'],
  ['RT6', 'Meyzieu', 'RT6 MEYZIEU'],
  ['SI1', 'Nueil-les-Aubiers', 'SI1 NUEIL LES AUBIERS'],
  ['SG8', 'Caen', 'SG8 CAEN VAUCELLES'],
  ['SI9', 'Marnay', 'SI9 MARNAY'],
  ['SJ7', 'Ivry-sur-Seine', 'SJ7 IVRY SUR SEINE'],
  ['SI0', 'Boulogne-Billancourt', 'SI0 BOULOGNE'],
  ['SK4', 'Plouha', 'SK4 PLOUHA'],
  ['SK2', 'Clermont-Ferrand', 'SK2 CLERMONT FERRAND'],
  ['SK1', 'Choisy-le-Roi', 'SK1 CHOISY LE ROI'],
  ['QH8', 'Quimperlé', 'QH8 QUIMPERLE'],
  ['SL0', 'Paris 17', 'SL0 PARIS GUY MOQUET'],
  ['SK6', 'Conflans-Sainte-Honorine', 'SK6 CONFLANS'],
  ['SM0', 'Clichy', 'SM0 CLICHY'],
  ['SM6', 'Paris 15', 'SM6 PARIS 15 CONVENTION'],
  ['SJ1', 'Gap', 'SJ1 GAP'],
  ['SJ3', 'Herblay-sur-Seine', 'SJ3 HERBLAY'],
  ['SJ5', 'Champigny-sur-Marne', 'SJ5 CHAMPIGNY SUR MARNE'],
  ['SK9', 'Bordeaux', 'SK9 BORDEAUX BASTIDE'],
  ['SN3', 'Lyon 04', 'SN3 LYON 04 CROIX-ROUSSE'],
  ['SQ6', 'Blois', 'SQ6 AGENCE DU CENTRE - BLOIS 2'],
  ['SK3', 'Évian-les-Bains', 'SK3 EVIAN LES BAINS'],
  ['SN0', 'Bron', 'SN0 BRON'],
  ['SP3', 'Bompas', 'SP3 BOMPAS'],
  ['SN4', 'Paris 05', 'SN4 PARIS 05 MONGE'],
  ['SP4', 'Capbreton', 'SP4 CAPBRETON'],
  ['SO3', 'Lyon 03', 'SO3 LYON 03 MONTCHAT'],
  ['SL5', 'Douai', 'SL5 DOUAI'],
  ['SM8', 'Lille', 'SM8 LILLE'],
  ['ND2', 'La Garenne-Colombes', 'ND2 LA GARENNE COLOMBES'],
  ['G90', 'Lyon 07', 'G90 LYON 7'],
  ['SQ3', 'Moncoutant', 'SQ3 MONCOUTANT'],
  ['SO9', 'Marcoussis', 'SO9 MARCOUSSIS'],
  ['SM5', 'Saint Maximin La Sainte Baume', 'SM5 ST MAXIMIN LA STE BAUME'],
  ['SM2', 'Pithiviers', 'SM2 PITHIVIERS'],
  ['SQ2', 'Albi', 'SQ2 ALBI FAC'],
  ['SL1', "Saint-Pierre-d'Oléron", 'SL1 OLERON'],
  ['SM9', 'Longjumeau', 'SM9 LONGJUMEAU'],
  ['SQ7', 'Montech', 'SQ7 MONTECH'],
  ['SR2', 'Montbeton', 'SR2 MONTBETON'],
  ['SN5', 'Rennes', 'SN5 RENNES'],
  ['SR3', 'Montbéliard', 'SR3 MONTBELIARD'],
  ['SO8', 'Évry Courcouronnes', 'SO8 EVRY'],
  ['SP2', 'Arcachon', 'SP2 ARCACHON'],
  ['SJ9', 'Alfortville', 'SJ9 ALFORTVILLE'],
  ['SR6', 'Amelie-Les-Bains-Palalda', 'SR6 AMELIE-LES-BAINS'],
  ['SM4', 'Blanquefort', 'SM4 BLANQUEFORT'],
  ['SQ8', 'Mouy', 'SQ8 MOUY'],
  ['SR7', 'Barbâtre', 'SR7 BARBATRE'],
  ['SP5', 'Libourne', 'SP5 LIBOURNE'],
  ['SP0', "L'Arbresle", "SP0 L'ARBRESLE"],
  ['SP7', 'Pamiers', 'SP7 PAMIERS'],
  ['SO4', 'Le Cannet', 'SO4 LE CANNET'],
  ['SP6', 'Voves', 'SP6 VOVES'],
  ['SQ5', 'Jassans-Riottier', 'SQ5 JASSANS-RIOTTIER'],
  ['SS2', 'Perros-Guirec', 'SS2 PERROS-GUIREC'],
  ['ST2', 'Biscarrosse', 'ST2 BISCARROSSE'],
  ['SO6', 'Porto-Vecchio', 'SO6 PORTO-VECCHIO'],
  ['SS5', 'Nancy', 'SS5 NANCY POINCARE'],
  ['ST5', 'Lyon 02', 'ST5 LYON 02 BELLECOUR'],
  ['SN6', 'Versailles', 'SN6 SAINT-LOUIS'],
  ['SP9', 'Saint-François', 'SP9 SAINT-FRANCOIS'],
  ['SV0', 'Avignon', 'SV0 AVIGNON'],
  ['SS1', 'Aubervilliers', 'SS1 AUBERVILLIERS'],
  ['SQ4', 'Strasbourg', 'SQ4 STRASBOURG'],
  ['SV9', 'Rognac', 'SV9 ROGNAC'],
  ['SS0', 'Molsheim', 'SS0 MOLSHEIM'],
  ['LW6', 'Évreux', 'LW6 EVREUX'],
  ['SV2', 'Boulogne-Billancourt', 'SV2 BOULOGNE CENTRE'],
  ['SR9', 'Saverne', 'SR9 SAVERNE'],
  ['SW5', "Les Sables-d'Olonne", "SW5 LES SABLES D'OLONNE 2"],
  ['SV6', "Les Sables-d'Olonne", "SV6 LES SABLES D'OLONNE"],
  ['SU2', 'Gournay-sur-Marne', 'SU2 GOURNAY SUR MARNE'],
  ['SW2', 'Deuil-la-Barre', 'SW2 DEUIL-LA-BARRE'],
  ['SO0', 'Paris 11', 'SO0 NATION PARIS XI'],
  ['SV1', 'La Ferté-Alais', 'SV1 LA FERTE-ALAIS'],
  ['SU9', 'ANNECY', 'SU9 ANNECY PARMELAN'],
  ['SO1', 'Paris 17', 'SO1 PARIS BATIGNOLLES'],
  ['ST6', 'Grenoble', 'ST6 GRENOBLE NOTRE DAME'],
  ['SV5', 'SCHILTIGHEIM', 'SV5 SCHILTIGHEIM'],
  ['SZ8', 'NOISY-LE-GRAND', 'SZ8 NOISY LE GRAND'],
  ['SQ0', 'Villefranche-sur-Saône', 'SQ0 VILLEFRANCHE SUR SAONE'],
  ['SO2', 'Paris 10', 'SO2 PARIS 10 SAINT-DENIS'],
  ['SW4', 'Cholet', 'SW4 CHOLET'],
  ['SY8', 'ROANNE', 'SY8 ROANNE'],
  ['SX4', 'MAICHE', 'SX4 MAICHE'],
  ['ST4', 'Rochefort', 'ST4 ROCHEFORT'],
  ['SX7', 'Rouen', 'SX7 ROUEN GAUCHE'],
  ['SX5', 'Saint-Orens-de-Gameville', 'SX5 SAINT-ORENS-DE-GAMEVILLE'],
  ['SX8', 'Gif-sur-Yvette', 'SX8 GIF-SUR-YVETTE'],
  ['ST3', 'Villers-sur-Mer', 'ST3 VILLERS SUR MER'],
  ['SU6', 'Hyères', 'SU6 HYERES'],
  ['SV7', 'Mouans-Sartoux', 'SV7 MOUANS-SARTOUX'],
  ['SX3', 'Lisle-sur-Tarn', 'SX3 LISLE-SUR-TARN'],
  ['SU5', 'Paris 11', 'SU5 PARIS 11 COURONNES'],
  ['SW9', 'Blaye', 'SW9 BLAYE'],
  ['SW3', 'CHAMPEIX', 'SW3 LAFORET CHAMPEIX'],
  ['SX2', 'DIJON', 'SX2 LES PORTES DU SUD'],
  ['SZ5', 'VILLEMOMBLE', 'SZ5 VILLEMOMBLE'],
  ['TA9', 'Bois-Colombes', 'TA9 BOIS-COLOMBES'],
  ['ST9', 'Bègles', 'ST9 BEGLES'],
  ['SU7', 'Amnéville', 'SU7 AMNEVILLE'],
  ['RQ3', 'Mantes-la-Jolie', 'RQ3 MANTES EN YVELINES'],
  ['SU1', 'Nice', 'SU1 NICE GAMBETTA'],
  ['TA7', 'Paris 15', 'TA7 PARIS 15 PASTEUR'],
  ['TA1', 'La Valette-du-Var', 'TA1 LA VALETTE-DU-VAR'],
  ['SY9', 'Brunoy', 'SY9 BRUNOY'],
  ['SW1', 'Paris 10', 'SW1 PARIS 10 REPUBLIQUE'],
  ['SZ2', 'VILLENEUVE-LE-ROI', 'SZ2 VILLENEUVE-LE-ROI'],
  ['TD2', 'Thouars', 'TD2 THOUARS'],
  ['SY2', 'ILLKIRCH-GRAFFENSTADEN', 'SY2 ILLKIRCH'],
  ['TD4', 'Paris 15', 'TD4 PARIS 15 FELIX-FAURE'],
  ['SY3', 'STRASBOURG', 'SY3 STRASBOURG ORANGERIE'],
  ['SY0', 'Marseille 08', 'SY0 MARSEILLE 08'],
  ['TC4', 'Suresnes', 'TC4 SURESNES PLATEAU MONT VALERIEN'],
  ['SX0', 'PONT-DE-CHERUY', 'SX0 PONT DE CHERUY'],
  ['SV4', 'Paris 04', 'SV4 PARIS 04 ARSENAL'],
  ['TC5', 'MARBOZ', 'TC5 MARBOZ'],
  ['SW7', 'Eysines', 'SW7 EYSINES'],
  ['TE5', 'Montauroux', 'TE5 MONTAUROUX'],
  ['TA4', 'Sucy-en-Brie', 'TA4 SUCY-EN-BRIE'],
  ['TE9', 'CERGY', 'TE9 CERGY LE HAUT'],
  ['TA8', 'Moissac', 'TA8 MOISSAC'],
  ['SZ9', 'Garches', 'SZ9 GARCHES'],
  ['SY5', 'Saint-Malo', 'SY5 SAINT-SERVAN'],
  ['TE1', 'Bordeaux', 'TE1 BORDEAUX CAUDERAN'],
  ['TC3', 'Nevers', 'TC3 NEVERS'],
  ['SZ7', 'Saint-Priest', 'SZ7 SAINT-PRIEST'],
  ['TC0', 'Rambouillet', 'TC0 RAMBOUILLET'],
  ['TB4', 'TASSIN-LA-DEMI-LUNE', 'TB4 TASSIN-LA-DEMI-LUNE'],
  ['TC6', 'ANGERS', 'TC6 ANGERS'],
  ['TB9', 'Paris 16', 'TB9 PARIS 16 AUTEUIL'],
  ['TF0', 'Merville-Franceville-Plage', 'TF0 MERVILLE-FRANCEVILLE-PLAGE'],
  ['TB6', 'Laguiole', 'TB6 LAGUIOLE'],
  ['TC1', 'Gisors', 'TC1 GISORS'],
  ['TB1', 'BELFORT', 'TB1 BELFORT'],
  ['TF3', 'Antibes', 'TF3 ANTIBES'],
  ['TE3', 'Gruissan', 'TE3 GRUISSAN'],
  ['TD9', 'Paris 01', 'TD9 PARIS 01'],
  ['TF4', 'VILLEURBANNE', 'TF4 VILLEURBANNE REPUBLIQUE'],
  ['TD5', "Cournon d'Auvergne", "TD5 COURNON D'AUVERGNE"],
  ['TD1', 'Langon', 'TD1 LANGON'],
  ['TD8', 'Valence', 'TD8 VALENCE'],
  ['TF2', 'Châtillon', 'TF2 CHATILLON'],
  ['TF6', 'HONFLEUR', 'TF6 HONFLEUR'],
  ['TH8', 'GIVORS', 'TH8 CABINET MERMET GIVORS'],
  ['TA2', 'Tarnos', 'TA2 TARNOS'],
  ['TD6', 'Arès', 'TD6 CAP FERRET'],
  ['TB2', 'Brie-Comte-Robert', 'TB2 BRIE COMTE ROBERT'],
  ['TI8', 'Maurepas', 'TI8 MAUREPAS'],
  ['SZ6', 'Le Plessis-Trévise', 'SZ6 LE PLESSIS-TREVISE'],
  ['TD3', 'Milly-la-Forêt', 'TD3 MILLY-LA-FORET'],
  ['TH9', 'NEUVES-MAISONS', 'TH9 NEUVES-MAISONS'],
  ['TK1', 'ESSEY-LES-NANCY', 'TK1 ESSEY-LES-NANCY'],
  ['TE8', 'Bourg-la-Reine', 'TE8 BOURG LA REINE'],
  ['TG2', 'Verdun-sur-Garonne', 'TG2 VERDUN-SUR-GARONNE'],
  ['TB7', 'VAUJOURS', 'TB7 VAUJOURS'],
  ['TG4', 'Saint-Estève', 'TG4 SAINT-ESTÈVE'],
  ['TG6', 'Saintes', 'TG6 Saintes'],
  ['TC9', 'LE HAVRE', 'TC9 LE HAVRE CENTRE'],
  ['TD7', 'La Seyne-sur-Mer', 'TD7 LA SEYNE SUR MER'],
  ['SW8', 'Neuilly-sur-Seine', 'SW8 NEUILLY SUR SEINE'],
  ['TK5', 'Paris 18', 'TK5 PARIS 18 MARX DORMOY'],
  ['TF8', 'Vélizy-Villacoublay', 'TF8 VÉLIZY-VILLACOUBLAY'],
  ['TJ3', 'Auray', 'TJ3 AURAY'],
  ['TJ1', 'TERRES-DE-CAUX', 'TJ1 FAUVILLE EN CAUX'],
  ['TE7', "VILLENEUVE-D'ASCQ", "TE7 VILLENEUVE D'ASCQ"],
  ['TH6', 'Saint-Cyprien', 'TH6 Saint Cyprien'],
  ['TG8', 'Saint-Maur-des-Fossés', 'TG8 SAINT-MAUR ADAMVILLE'],
  ['TF7', 'Orgeval', 'TF7 ORGEVAL'],
  ['TH7', 'Saint-Gilles-Croix-de-Vie', 'TH7 SAINT GILLES CROIX DE VIE'],
  ['SZ1', 'Saint-Geours-de-Maremme', 'SZ1 ST GEOURS DE MAREMME'],
  ['TJ8', 'MONTPELLIER', 'TJ8 MONTPELLIER PORT MARIANNE'],
  ['TF5', 'Saint-Médard-en-Jalles', 'TF5 ST MEDARD EN JALLES'],
  ['TH3', 'DARNETAL', 'TH3 DARNETAL'],
  ['TK9', 'COMPIEGNE', 'TK9 COMPIEGNE'],
  ['TK7', 'LYON 06', 'TK7 LYON 06'],
  ['TE6', 'NOGENT', 'TE6 NOGENT 52'],
  ['TH2', 'Chateaulin', 'TH2 CHATEAULIN'],
  ['SY7', 'SAINT-MAUR-DES-FOSSES', 'SY7 SAINT-MAUR LA VARENNE'],
  ['TI7', 'La Celle-Saint-Cloud', 'TI7 LA CELLE SAINT CLOUD'],
  ['TK3', 'EPINAL', 'TK3 EPINAL'],
  ['TL9', 'NEUVILLE-SUR-SAONE', 'TL9 NEUVILLE-SUR-SAONE'],
  ['TN4', 'AIRVAULT', 'TN4 AIRVAULT'],
  ['TK0', 'Saint-Laurent-du-Var', 'TK0 SAINT-LAURENT-DU-VAR'],
  ['TI9', 'Ronchin', 'TI9 RONCHIN'],
  ['SN9', 'Chaville', 'SN9 CHAVILLE'],
  ['TK8', 'Paris 18', 'TK8 PARIS 18 MAIRIE'],
  ['TH4', 'DUNKERQUE', 'TH4 DUNKERQUE CENTRE'],
  ['TJ9', 'Marseille 04', 'TJ9 MARSEILLE 04'],
  ['TG5', 'Saint-Remy', 'TG5 SAINT-REMY'],
  ['TI0', 'BAGNOLET', 'TI0 BAGNOLET'],
  ['TL0', 'FARGUES-SAINT-HILAIRE', 'TL0 FARGUES SAINT HILAIRE'],
  ['TK4', 'Paris 08', 'TK4 PARIS 8 HAUSSMANN'],
  ['TI4', 'DIVES-SUR-MER', 'TI4 DIVES-SUR-MER'],
  ['TE2', 'ARGENTEUIL', 'TE2 ARGENTEUIL COTEAUX'],
  ['TM5', 'Belleville-en-Beaujolais', 'TM5 BELLEVILLE-EN-BEAUJOLAIS'],
  ['TM9', 'Brest', 'TM9 BREST'],
  ['TO7', 'SAINT-FLOUR', 'TO7 SAINT-FLOUR'],
  ['TF1', 'CAEN', 'TF1 CAEN BEAULIEU'],
  ['TQ6', 'PARIS 20', 'TQ6 PARIS 20 GAMBETTA'],
  ['TO5', 'Aurillac', 'TO5 AURILLAC'],
  ['TP7', 'CHALLES-LES-EAUX', 'TP7 CHALLES-LES-EAUX'],
  ['TM2', 'TOURCOING', 'TM2 TOURCOING'],
  ['TS2', 'MACON', 'TS2 MACON'],
  ['TQ4', 'CHATEAURENARD', 'TQ4 CHATEAURENARD'],
  ['TS6', 'CABANNES', 'TS6 CABANNES'],
  ['TS7', "PLAN D'ORGON", "TS7 PLAN D'ORGON"],
  ['TQ2', 'CAVAILLON', 'TQ2 CAVAILLON'],
  ['TP4', 'LA TESTE-DE-BUCH', 'TP4 LA TESTE DE BUCH'],
  ['TQ7', 'SAINT-MAURICE', 'TQ7 SAINT-MAURICE'],
  ['TH1', 'PIREY', 'TH1 PIREY'],
  ['TR0', 'PUTEAUX', 'TR0 Puteaux'],
  ['TC7', 'Paris 05', 'TC7 Paris 05 Gay-Lussac'],
  ['TR8', 'BAILLEUL', 'TR8 BAILLEUL'],
  ['TP2', 'PARIS 20EME', 'TP2 Paris 20'],
  ['TW3', 'IS SUR TILLE', 'TW3 IS SUR TILLE'],
  ['TO4', 'CERET', 'TO4 CERET'],
  ['TU5', 'LA FARE-LES-OLIVIERS', 'TU5 LA FARE LES OLIVIERS'],
  ['TX3', 'Carqueiranne', 'TX3 La Garde Location Gestion'],
  ['TR7', 'MONTROUGE', 'TR7 MONTROUGE'],
  ['TF9', 'Antony', 'TF9 Antony Gare'],
  ['TQ1', 'BRANTOME', 'TQ1 BRANTOME'],
  ['TN5', 'Quincy-Voisins', 'TN5 QUINCY-VOISINS'],
  ['TY6', "L'ISLE-SUR-LA-SORGUE", 'TY6 ISLE SUR LA SORGUE'],
  ['TO3', 'BONNEUIL-SUR-MARNE', 'TO3 BONNEUIL-SUR-MARNE'],
  ['TN9', 'Bandol', 'TN9 BANDOL'],
  ['TP5', 'MORET LOING ET ORVANNE', 'TP5 MORET LOING ET ORVANNE'],
  ['TM8', 'Rezé', 'TM8 REZE'],
  ['TX0', 'ALBERTVILLE', 'TX0 ALBERTVILLE'],
  ['TL7', 'Aubagne', 'TL7 AUBAGNE'],
  ['TT6', 'La Roche-sur-Foron', 'TT6 La Roche sur Foron'],
  ['TT9', 'Châtenay-Malabry', 'TT9 Châtenay-Malabry'],
  ['TT7', 'Bonneville', 'TT7 Bonneville'],
  ['TR1', 'BEZIERS', 'TR1 BEZIERS'],
  ['TT1', 'LE BOUSCAT', 'TT1 LE BOUSCAT'],
  ['TL1', 'SAINT-LOUIS', 'TL1 SAINT LOUIS'],
  ['TU4', 'CALAIS', 'TU4 CALAIS'],
  ['TU0', 'SAINT-BREVIN-LES-PINS', 'TU0 SAINT BREVIN LES PINS'],
  ['TV8', 'LUZARCHES', 'TV8 LUZARCHES'],
  ['UB3', 'PARIS 09', 'UB3 PARIS 09 MARTYRS GESTION'],
  ['TM6', 'Rillieux-la-Pape', 'TM6 RILLIEUX-LA-PAPE'],
  ['TM0', 'AUNEAU BLEURY SAINT SYMPHORIEN', 'TM0 AUNEAU'],
  ['TS5', 'AVIGNON', 'TS5 AVIGNON SAINT ROCH'],
  ['UA8', 'MARSEILLE 9EME', 'UA8 MARSEILLE 09'],
  ['TS1', 'AGEN', 'TS1 AGEN'],
  ['TV2', 'YERRES', 'TV2 YERRES'],
  ['TN2', 'BEZONS', 'TN2 BEZONS'],
  ['TV3', 'MERIGNIES', 'TV3 MERIGNIES'],
  ['TV4', 'TEMPLEUVE', 'TV4 TEMPLEUVE'],
  ['UA3', 'LES PAVILLONS-SOUS-BOIS', 'UA3 LES PAVILLONS-SOUS-BOIS'],
  ['TU1', 'RIOM', 'TU1 RIOM'],
  ['TS8', 'Nîmes', 'TS8 NIMES CARNOT'],
  ['TO6', 'MEAUX', 'TO6 MEAUX'],
  ['TM7', 'Cormeilles-en-Parisis', 'TM7 CORMEILLES EN PARISIS'],
  ['UA0', 'Toulon', 'UA0 TOULON'],
  ['TR3', 'NEUILLY PLAISANCE', 'TR3 NEUILLY PLAISANCE'],
  ['UB7', 'BOURG-EN-BRESSE', 'UB7 BOURG EN BRESSE GARE'],
  ['TR5', 'REIMS', 'TR5 REIMS SUD'],
  ['TX4', 'Saint-Dizier', 'TX4 SAINT DIZIER'],
  ['UC9', 'Loctudy', 'UC9 LOCTUDY'],
  ['TY5', 'Saint-Mars-de-Coutais', 'TY5 SAINT MARS DE COUTAIS'],
  ['TW8', 'EPERNAY', 'TW8 EPERNAY'],
  ['TU3', 'Dourdan', 'TU3 DOURDAN'],
  ['TW0', 'Marseille', 'TW0 MARSEILLE 11'],
  ['TV9', 'CUERS', 'TV9 CUERS'],
  ['TU6', 'Chemillé-en-Anjou', 'TU6 CHEMILLE-EN-ANJOU'],
  ['TX2', 'Créon', 'TX2 CREON'],
  ['TR6', 'Asnières-sur-Seine', 'TR6 ASNIERES-SUR-SEINE MAIRIE'],
  ['TU9', 'ORVAULT', 'TU9 ORVAULT'],
  ['TV5', 'MIRIBEL', 'TV5 MIRIBEL'],
  ['UE1', 'Bressuire', 'UE1 BRESSUIRE'],
  ['TY2', 'PARIS 07', 'TY2 PARIS 7 RASPAIL'],
  ['TR2', 'CANNES', 'TR2 CANNES CARNOT'],
  ['TY1', 'BEAUMONT-SUR-OISE', 'TY1 BEAUMONT-SUR-OISE'],
  ['TY3', 'CAPINGHEM', 'TY3 CAPINGHEM LOMME'],
  ['TW7', 'SARROLA-CARCOPINO', 'TW7 AJACCIO SARROLA-CARCOPINO'],
  ['TO8', 'MONTIGNY-LES-METZ', 'TO8 MONTIGNY LES METZ'],
  ['UF3', 'PIOLENC', 'UF3 PIOLENC'],
  ['TW4', 'CLERMONT-FERRAND', 'TW4 CLERMONT-FERRAND SUD'],
  ['RM3', 'Versailles', 'RM3 VERSAILLES PORCHEFONTAINE'],
  ['TZ3', 'SAINT-GENIS-LAVAL', 'TZ3 SAINT-GENIS-LAVAL'],
  ['UF2', 'Le Soler', 'UF2 LE SOLER'],
  ['TN8', 'LIFFRE', 'TN8 LIFFRE'],
  ['TR9', 'Perpignan', 'TR9 PERPIGNAN NORD'],
  ['TO1', 'Paris 10', 'TO1 PARIS 10 REPUBLIQUE SYNDIC'],
  ['TY7', 'VILLENEUVE EN RETZ', 'TY7 VILLENEUVE EN RETZ'],
  ['UA5', 'Dombasle-sur-Meurthe', 'UA5 DOMBASLE SUR MEURTHE'],
  ['TU2', 'VILLIERS-SUR-MARNE', 'TU2 Villiers sur Marne'],
  ['UB5', 'Roissy-en-Brie', 'UB5 ROISSY EN BRIE'],
  ['UB1', 'OZOIR-LA-FERRIERE', 'UB1 OZOIR LA FERRIERE'],
  ['UB0', 'PONTAULT-COMBAULT', 'UB0 PONTAULT COMBAULT'],
  ['UE9', 'Pontault-Combault', 'UE9 PONTAULT COMBAULT GESTION'],
  ['UA4', 'ORMESSON-SUR-MARNE', 'UA4 ORMESSON-SUR-MARNE'],
  ['TT3', "VERNEUIL D'AVRE ET D'ITON", "TT3 VERNEUIL D'AVRE ET D'ITON"],
  ['TT0', 'Arles', 'TT0 ARLES'],
  ['UC3', 'AMIENS', 'UC3 AMIENS'],
  ['UJ4', 'Lieusaint', 'UJ4 LIEUSAINT'],
  ['UA1', 'MOISSY-CRAMAYEL', 'UA1 MOISSY CRAMAYEL'],
  ['TZ2', 'Mallemort', 'TZ2 MALLEMORT'],
  ['TT4', 'Miramas', 'TT4 MIRAMAS'],
  ['UI3', 'BOIS-GUILLAUME', 'UI3 BOIS-GUILLAUME'],
  ['UI2', 'MONT-SAINT-AIGNAN', 'UI2 MONT-SAINT-AIGNAN'],
  ['UD3', 'Six-Fours-les-Plages', 'UD3 SIX-FOURS-LES-PLAGES'],
  ['SR1', 'La Grand-Croix', 'SR1 LA GRAND-CROIX'],
  ['TN6', 'Castelsarrasin', 'TN6 CASTELSARRASIN'],
  ['TZ9', 'CROIX', 'TZ9 CROIX'],
  ['UD2', 'Cannes', 'UD2 CANNES CROISETTE'],
  ['P93', 'Marseille 12', 'P93 MARSEILLE 12 ST BARNABE'],
  ['OP9', 'Gaillac', 'OP9 GAILLAC'],
  ['SP8', 'Neufchâteau', 'SP8 NEUFCHATEAU'],
  ['PH2', 'Beausoleil', 'PH2 BEAUSOLEIL'],
  ['UM6', 'ESTAIRES', 'UM6 ESTAIRES'],
  ['UR3', 'Obernai', 'UR3 OBERNAI'],
  ['UM5', 'Pontarlier', 'UM5 PONTARLIER'],
  ['UL3', 'Saint-Avertin', 'UL3 SAINT AVERTIN'],
  ['UM4', 'Montfermeil', 'UM4 MONTFERMEIL'],
  ['U03', 'BRY-SUR-MARNE', 'U03 BRY SUR MARNE'],
  ['UK7', "SAINT-OUEN-L'AUMONE", "UK7 SAINT OUEN L'AUMONE"],
  ['UR6', 'Miniac-Morvan', 'UR6 MINIAC MORVAN'],
  ['US5', 'MONTPELLIER', 'US5 MONTPELLIER'],
  ['UN2', 'NIMES', 'UN2 NIMES'],
  ['UR4', 'EMBRUN', 'UR4 EMBRUN'],
  ['UE8', 'Paris', 'UE8 PARIS'],
  ['TJ7', 'LUCCIANA', 'TJ7 LUCCIANA'],
  ['UO3', 'Flins-sur-Seine', 'UO3 FLINS SUR SEINE'],
  ['UR1', 'Étaples', 'UR1 ÉTAPLES'],
  ['UN6', 'Maromme', 'UN6 MAROMME'],
  ['UM9', 'CASTELNAU DE MEDOC', 'UM9 CASTELNAU DE MEDOC'],
  ['UJ9', 'MARSEILLE', 'UJ9 MARSEILLE'],
  ['UN7', 'Athis-Mons', 'UN7 ATHIS MONS'],
  ['UL1', 'BAYONNE', 'UL1 BAYONNE'],
  ['UK2', 'Pau', 'UK2 PAU'],
  ['UO9', 'Juvisy-sur-Orge', 'UO9 JUVISY SUR ORGE'],
  ['UN5', 'Bordeaux', 'UN5 BORDEAUX'],
  ['UK4', 'La Madeleine', 'UK4 LA MADELEINE'],
  ['UH6', 'Challans', 'UH6 CHALLANS'],
  ['TY8', 'MONTPELLIER', 'TY8 MONTPELLIER'],
  ['UH8', 'Brest', 'UH8 BREST'],
  ['UB6', 'Mennecy', 'UB6 MENNECY'],
  ['US2', 'Saint-Loubès', 'US2 SAINT LOUBÈS'],
  ['UI7', 'PUISEUX-EN-FRANCE', 'UI7 PUISEUX EN FRANCE'],
  ['TT8', 'Bonne', 'TT8 BONNE'],
  ['UP6', 'Bordeaux', 'UP6 BORDEAUX'],
  ['UK5', 'Lormont', 'UK5 LORMONT'],
  ['UI5', 'Sète', 'UI5 SÈTE'],
  ['UP5', 'Cognac', 'UP5 COGNAC'],
  ['UL2', 'ANGLET', 'UL2 ANGLET'],
  ['UK8', 'Nice', 'UK8 NICE'],
  ['UO8', 'Chambéry', 'UO8 CHAMBÉRY'],
  ['UO7', 'MONTREUIL', 'UO7 MONTREUIL'],
  ['UI1', 'SAINT-AMOUR', 'UI1 SAINT AMOUR'],
  ['UJ1', 'Tournefeuille', 'UJ1 TOURNEFEUILLE'],
  ['UN1', 'Valréas', 'UN1 VALRÉAS'],
  ['UH2', 'LES LILAS', 'UH2 LES LILAS'],
  ['UL5', 'VILLEJUIF', 'UL5 VILLEJUIF'],
  ['UI9', 'Toulouse', 'UI9 TOULOUSE'],
  ['UK3', 'Castanet-Tolosan', 'UK3 CASTANET TOLOSAN'],
  ['UM3', 'HESDIN', 'UM3 HESDIN'],
  ['UD9', 'Muzillac', 'UD9 MUZILLAC'],
  ['UN9', 'Vallauris', 'UN9 VALLAURIS'],
  ['UG3', 'Nice', 'UG3 NICE'],
  ['UF9', 'Sartrouville', 'UF9 SARTROUVILLE'],
  ['UJ7', 'Savigny-sur-Orge', 'UJ7 SAVIGNY SUR ORGE'],
  ['UF4', 'Chatou', 'UF4 CHATOU'],
  ['UC6', 'Lunel', 'UC6 LUNEL'],
  ['UC8', 'Biganos', 'UC8 BIGANOS'],
  ['TL4', 'EZANVILLE', 'TL4 EZANVILLE'],
  ['UC1', 'Montigny-le-Bretonneux', 'UC1 MONTIGNY LE BRETONNEUX'],
  ['UJ2', 'La Rochelle', 'UJ2 LA ROCHELLE'],
  ['UO4', 'Reims', 'UO4 REIMS'],
  ['UM2', 'CHERBOURG-EN-COTENTIN', 'UM2 CHERBOURG EN COTENTIN'],
  ['UF7', 'Valbonne', 'UF7 VALBONNE'],
  ['UH5', 'Paris', 'UH5 PARIS'],
  ['UC5', 'Fontainebleau', 'UC5 FONTAINEBLEAU'],
  ['UB9', 'Martignas-sur-Jalle', 'UB9 MARTIGNAS SUR JALLE'],
  ['TZ6', 'Canet-en-Roussillon', 'TZ6 CANET EN ROUSSILLON'],
  ['UP9', 'Vittel', 'UP9 VITTEL'],
  ['UL9', 'ANTONY', 'UL9 ANTONY'],
  ['UG8', 'Saint-Sever', 'UG8 SAINT SEVER'],
  ['UM7', 'HOULGATE', 'UM7 HOULGATE'],
  ['UK6', 'Vendenheim', 'UK6 VENDENHEIM'],
  ['TY0', 'Carpentras', 'TY0 CARPENTRAS'],
  ['UN8', 'Parthenay', 'UN8 PARTHENAY'],
  ['UF6', 'COUSANCE', 'UF6 COUSANCE'],
  ['UD5', "CHAMPAGNE-AU-MONT-D'OR", "UD5 CHAMPAGNE AU MONT D'OR"],
  ['UF5', 'LOUHANS', 'UF5 LOUHANS'],
  ['UE6', 'Vanves', 'UE6 VANVES'],
  ['UH7', 'Chartres', 'UH7 CHARTRES'],
  ['UH4', 'Royan', 'UH4 ROYAN'],
  ['TG1', 'THIAIS', 'TG1 THIAIS'],
  ['UO6', 'Saint-Raphaël', 'UO6 SAINT RAPHAËL'],
  ['UH9', 'Saint-Brieuc', 'UH9 SAINT BRIEUC'],
  ['UB4', 'Vigneux-sur-Seine', 'UB4 VIGNEUX SUR SEINE'],
  ['UJ3', 'MARLY-LE-ROI', 'UJ3 MARLY LE ROI'],
  ['UE3', 'MONTMORENCY', 'UE3 MONTMORENCY'],
  ['UD7', 'VILLARS-LES-DOMBES', 'UD7 VILLARS LES DOMBES'],
  ['UD8', 'VIC-LE-COMTE', 'UD8 VIC LE COMTE'],
  ['UE2', 'PARIS 13', 'UE2 PARIS 13'],
  ['TZ7', 'Le Plessis-Robinson', 'TZ7 LE PLESSIS ROBINSON'],
  ['UA7', 'Mimizan', 'UA7 MIMIZAN'],
  ['UE7', 'Seignosse', 'UE7 SEIGNOSSE'],
  ['UH1', 'HAZEBROUCK', 'UH1 HAZEBROUCK'],
  ['UJ5', 'Beaumont-de-Lomagne', 'UJ5 BEAUMONT DE LOMAGNE'],
  ['OD3', 'Vouziers', 'OD3 VOUZIERS'],
  ['UJ6', 'CAYENNE', 'UJ6 CAYENNE'],
  ['UE5', 'Dammarie-les-Lys', 'UE5 DAMMARIE LES LYS'],
  ['UB2', 'Lingolsheim', 'UB2 LINGOLSHEIM'],
  ['UK9', 'ANNECY', 'UK9 ANNECY'],
  ['TS9', 'Serris', 'TS9 SERRIS'],
  ['UA6', 'SECLIN', 'UA6 SECLIN'],
  ['TZ4', 'Marseille', 'TZ4 MARSEILLE'],
  ['TW5', 'SEVRAN', 'TW5 SEVRAN'],
  ['UG5', 'PORT SAINT PERE', 'UG5 PORT SAINT PERE'],
  ['TQ5', 'Châteaubriant', 'TQ5 CHÂTEAUBRIANT'],
];
export default dataAgentLaforet;
