/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import Amount from 'components/Amount';
import MaPrimeRenovBlock from 'components/success/MaPrimeRenovBlock';
import ErrorHandler from 'components/ErrorHandler';
import NavSuccess from 'components/success/NavSuccess';
import SuccessTemplateSidebar from 'components/success/SuccessTemplateSidebar';
import EnergyTag from 'components/success/EnergyTag';
import SuccessItem from 'components/success/SuccessItem';
import FileUploader from 'components/FileUploader';
import PrimeBlock from './PrimeBlock';

export default function SuccessPage({ children, comment, ...props }) {
  const APIresult = props?.result?.data;
  const bgMobile = `url(${dataSuccess[props?.success]?.imgMobile})`;
  if (props?.result?.success === 'false') {
    return <ErrorHandler message={props.result.message} reset={props.reset} />;
  } else {
    return (
      <div className="flex-container">
        <SuccessTemplateSidebar {...props}>
          {props?.answers?.Q1 !== 'thermostat' && <EnergyTag {...props} />}
          {!!(props?.answers?.Q2 || props?.answers?.['Q2.V2']) && (
            <SuccessItem
              label="Type de logement"
              value={props?.answers?.Q2 ?? props?.answers?.['Q2.V2']}
            />
          )}
          {!!props?.answers?.Q8 && !props.isPrime && (
            <SuccessItem
              label="Surface du logement"
              value={
                <>
                  {props.answers.Q8}&nbsp;m<sup className="text-xs">2</sup>
                </>
              }
            />
          )}
          {!!props?.answers?.Q47 && props.isPrime && (
            <SuccessItem
              label="Surface chauffée"
              value={
                <>
                  {props.answers.Q47}&nbsp;m<sup className="text-xs">2</sup>
                </>
              }
            />
          )}
          {!!dataSuccess[props.success].simulation &&
            !!props.result.data['Couleur MPR'] && (
              <MaPrimeRenovBlock color={props.result.data['Couleur MPR']} />
            )}
          {!!props?.answers?.Q22 && (
            <SuccessItem
              label="Système de chauffage"
              value={props.answers.Q22}
            />
          )}
          {!!props?.answers?.Q15 && (
            <SuccessItem
              label="Facture d’électricité / mois"
              value={<Amount value={props.answers.Q15} />}
            />
          )}
          {!!props?.answers?.Q16 && (
            <SuccessItem label="Type de contrat" value={props.answers.Q16} />
          )}
          {!!props?.answers?.Q46 && (
            <SuccessItem label="Nombre de fenêtres" value={props.answers.Q46} />
          )}
          {!!props?.answers?.Q12 && (
            <SuccessItem
              label={
                <>
                  Distance entre la borne et <br />
                  le tableau électrique
                </>
              }
              value={props.answers.Q12}
            />
          )}
          {!!props?.answers?.Q10 && (
            <SuccessItem label="Modèle de voiture" value={props.answers.Q10} />
          )}
          {!!props?.answers?.Q11 && (
            <SuccessItem
              label="Puissance souhaitée"
              value={props.answers.Q11}
            />
          )}
        </SuccessTemplateSidebar>
        <div className="success-right-container">
          <div
            style={{
              backgroundImage: bgMobile,
            }}
            className="success-header-img only-show-mobile py-4"
          >
            <h1>{dataSuccess[props.success].h1}</h1>

            <button className="btn-init-success" onClick={props.reset}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4697_13755)">
                  <path
                    d="M13.3083 2.97206C13.4457 3.18957 13.5756 3.38159 13.6888 3.57816C14.2172 4.47858 14.5306 5.47976 14.6065 6.50979C14.6824 7.53982 14.519 8.57316 14.1279 9.53559C13.7368 10.498 13.1278 11.3657 12.3446 12.0762C11.5613 12.7868 10.6234 13.3227 9.59799 13.6453C5.59089 14.9267 1.21656 12.6169 0.214785 8.69046C-0.741326 4.9419 1.57998 1.21063 5.481 0.226846C6.06199 0.0832859 6.65906 0.0078038 7.25908 0.00205981C7.63201 -0.00385562 7.90886 0.236857 7.91504 0.567211C7.92123 0.897565 7.64961 1.14237 7.27478 1.14874C5.44485 1.17969 3.90604 1.83903 2.7021 3.15316C1.51766 4.44546 1.02011 5.97073 1.2275 7.67892C1.54716 10.2899 3.55832 12.3162 6.2497 12.7839C9.54566 13.3559 12.7741 11.1945 13.3373 8.03476C13.6033 6.54649 13.319 5.16031 12.4844 3.87621C12.4402 3.80796 12.3921 3.74198 12.3198 3.63777C12.3079 3.81023 12.297 3.94265 12.2903 4.07551C12.277 4.34398 12.268 4.61336 12.2532 4.88183C12.2511 4.95684 12.2333 5.03068 12.2009 5.099C12.1685 5.16732 12.1221 5.22874 12.0644 5.27963C12.0068 5.33052 11.9391 5.36986 11.8653 5.39532C11.7915 5.42078 11.7132 5.43185 11.6348 5.42787C11.5576 5.42661 11.4813 5.41054 11.4106 5.38061C11.3399 5.35069 11.2762 5.30751 11.2233 5.25364C11.1704 5.19977 11.1292 5.13629 11.1024 5.06696C11.0755 4.99763 11.0634 4.92385 11.0669 4.84998C11.0987 3.97222 11.1344 3.09446 11.1806 2.21716C11.2024 1.79853 11.4845 1.58785 11.9378 1.642C12.6904 1.73301 13.4415 1.83266 14.1935 1.92867C14.2986 1.94187 14.4042 1.95324 14.5084 1.97144C14.8266 2.02605 15.0274 2.28997 14.9931 2.60348C14.9786 2.74429 14.9078 2.8743 14.7955 2.96638C14.6832 3.05846 14.5381 3.10549 14.3904 3.09765C14.095 3.07945 13.8011 3.0303 13.5066 2.99481C13.4524 2.98753 13.3948 2.98162 13.3083 2.97206Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4697_13755">
                    <rect width="15" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>

          {children}

          {props.showFile && (
            <FileUploader
              patchName="fileImposition"
              description="Déposez votre avis imposition pour valider le montant de votre prime. (15Mo maximum)"
              handleFile={props.handleFile}
            ></FileUploader>
          )}

          {props.showPrime && (
            <PrimeBlock
              APIresult={APIresult}
              success={props.success}
              resultCodePromo={props.resultCodePromo}
            ></PrimeBlock>
          )}

          <NavSuccess
            {...props}
            price2={APIresult?.['Montant total prime (€)']}
          ></NavSuccess>

          {comment}
        </div>
      </div>
    );
  }
}
