import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CreneauPicker from '../components/CreneauPicker';
import UserFormDialog from '../components/UserFormDialog';

export const SimulationContext = createContext(null);

export function useSimulation() {
  return useContext(SimulationContext);
}

// Fournit l'ensemble des données communes de la simulation aux composants enfants.
export default function SimulationProvider({ children, ...props }) {
  const [showPicker, setShowPicker] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);

  const hideUserFormDialog = () => {
    setShowUserForm(false);
  };

  const hidePickerDialog = () => {
    setShowPicker(false);
  };

  const showUserFormDialog = (requestDate) => {
    setShowUserForm({ requestDate });
  };

  const showPickerDialog = () => {
    setShowPicker(true);
  };

  const handleCallBtn = () => {
    if (!props.contact.lastname) {
      showUserFormDialog(true);
    } else if (props.date && props.creneau) {
      props.callBtn();
    } else {
      showPickerDialog();
    }
  };

  const handleCreneauBtn = () => {
    if (!props.contact.lastname) {
      showUserFormDialog(true);
    } else showPickerDialog();
  };

  const value = {
    ...props,
    showUserFormDialog,
    hideUserFormDialog,
    showPickerDialog,
    hidePickerDialog,
    handleCallBtn,
    handleCreneauBtn,
  };

  return (
    <SimulationContext.Provider value={value}>
      {children}

      {showPicker && (
        <CreneauPicker
          callBtn={props.callBtn}
          creneau={props.creneau}
          date={props.date}
          handleDate={props.handleDate}
          handleCreneau={props.handleCreneau}
          hidePicker={hidePickerDialog}
        />
      )}

      {showUserForm && (
        <UserFormDialog
          onClose={hideUserFormDialog}
          open={!!showUserForm}
          requestDate={showUserForm?.requestDate}
          onSubmit={() => {
            props.simulationAPI(true);
            hideUserFormDialog();
          }}
          onChange={props.handleUserForm}
          initialValues={{
            ...props.contact,
            date: props.date,
            creneau: props.creneau,
          }}
        />
      )}
    </SimulationContext.Provider>
  );
}
SimulationProvider.propTypes = {
  successTemplate: PropTypes.string,
  requestCallBack: PropTypes.func.isRequired,
  reset: PropTypes.func,
  lastStep: PropTypes.func,
  answers: PropTypes.object,
  result: PropTypes.object,
  children: PropTypes.any.isRequired,
  codePromo: PropTypes.string,
  resultCodePromo: PropTypes.object,
  callBtn: PropTypes.func,
  creneau: PropTypes.string,
  date: PropTypes.string,
  handleDate: PropTypes.func,
  handleCreneau: PropTypes.func,
  handleUserForm: PropTypes.func,
  simulationAPI: PropTypes.func,
  contact: PropTypes.object,
};
