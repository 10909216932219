import React from 'react';
import PropTypes from 'prop-types';
import HeaderNavigation from './blocks/HeaderNavigation';
import { useSimulation } from '../../providers/SimulationProvider';

export default function SuccessPage({ footer, children }) {
  const { reset, lastStep } = useSimulation();

  return (
    <div className="success-v2 flex flex-col">
      <div className="flex flex-col grow overflow-y-auto">
        <div className="flex flex-col items-center">
          <HeaderNavigation
            className={'px-4 lg:px-8 pt-6'}
            onBack={lastStep}
            onReset={reset}
          />
          <div className="w-full px-4 lg:px-8 lg:pb-8 pb-4 flex flex-col">
            {children}
          </div>
        </div>
      </div>
      {footer && <div className="static">{footer}</div>}
    </div>
  );
}
SuccessPage.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.any,
};
