import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export default function PowerCharacteristics({
  className,
  maxPower,
  annualSaved,
  bonus25Years,
  surface,
}) {
  return (
    <div
      className={clsx(
        `bg-white p-6 rounded border border-solid border-xlight_gray`,
        className,
      )}
    >
      <h2 className="text-[14px] leading-none font-medium">Caractéristiques</h2>
      <div className={'pt-6 grid lg:grid-cols-2 grid-cols-1 gap-4'}>
        <div>
          <p className={'font-light text-[14px]'}>Puissance maximale</p>
          <div className={'flex items-center'}>
            <span className={'text-[16px] font-bold'}>{maxPower}</span>
          </div>
        </div>
        <div>
          <p className={'font-light text-[14px]'}>Surface</p>
          <div className={'flex items-center'}>
            <span className={'text-[16px] font-bold'}>{surface}</span>
          </div>
        </div>
        <div>
          <p className={'font-light text-[14px]'}>Économies annuelles</p>
          <div className={'flex items-center'}>
            <span className={'text-[16px] font-bold'}>
              {annualSaved} maximum
            </span>
          </div>
        </div>
        <div>
          <p className={'font-light text-[14px]'}>Gains sur 25 ans</p>
          <div className={'flex items-center'}>
            <span className={'text-[16px] font-bold'}>{bonus25Years}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

PowerCharacteristics.propTypes = {
  className: PropTypes.string,
  maxPower: PropTypes.string,
  surface: PropTypes.string,
  annualSaved: PropTypes.number,
  bonus25Years: PropTypes.string,
};
