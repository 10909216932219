/* eslint-disable react/prop-types */
import React from 'react';
import Amount from 'components/Amount';
import InfoHoverBlock from 'components/success/InfoHoverBlock';
import nextEnergy from 'images/icons/nextEnergy.svg';

class RenoPrimeBlock extends React.Component {
  render() {
    const infoReno = this.props.APIresult['Information Rénovation globale'];
    const InfoComponent = ({ infoReno }) => {
      return (
        <div className="renoItem">
          {infoReno.map((info, index) => (
            <div
              key={index}
              className={`${info.letter.join(', ') === this.props.renoTagOffer?.letter.join(', ') ? 'selected' : 'unselected'}`}
            >
              <label className="d-flex jcsb">
                <div className="d-flex aic ">
                  <input
                    type="radio"
                    value={info.letter.join(', ')}
                    name="reno"
                    checked={
                      info.letter.join(', ') ===
                      this.props.renoTagOffer?.letter.join(', ')
                    }
                    onChange={this.props.handleRenoTagOffer}
                  />
                  <img
                    src={`https://www.hellio.com/hubfs/simulateur/icons3/tags/${this.props.energyTag}.svg`}
                    className="ml-5"
                  />
                  <img src={nextEnergy} className="mh-5" />
                  {info.letter.map((item) => (
                    <img
                      key={item}
                      src={`https://www.hellio.com/hubfs/simulateur/icons3/tags/${item}.svg`}
                      className="mr-5"
                    />
                  ))}
                </div>
                <h2 className="whitespace-nowrap">
                  - <Amount value={info.prime} />
                </h2>
              </label>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div>
        <InfoHoverBlock
          data={this.props.APIresult}
          success={this.props.success}
          item="PrimeReno"
          showTooltip
        ></InfoHoverBlock>
        <div className="success-item-right" style={{ border: 0 }}>
          <InfoComponent infoReno={infoReno} />
        </div>
        <hr className="hr-reno" />
        <div>
          <div className="success-item-right last">
            <h5>Reste à payer</h5>
            <div>
              <h5 className="h5-price whitespace-nowrap">
                {this.props.renoTagOffer ? (
                  <Amount value={this.props.renoTagOffer.reste_a_charge} />
                ) : null}
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RenoPrimeBlock;
