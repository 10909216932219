/* eslint-disable react/prop-types */
import React from 'react';
import InfoTooltip from 'components/InfoTooltip';

class InfoHoverBlock extends React.Component {
  render() {
    const MaPrimeRenov = this.props.data['Montant MPR unitaire (€)'];
    const PrimeHellio = this.props.data['Montant prime CEE unitaire (€)'];
    const MaPrimeRenovInfo = `est une aide publique dont le montant varie en fonction des revenus et du type de travaux.`;
    const PrimeHellioInfo = `est versée par les fournisseurs d'énergie, obligés de financer des opérations d'économies d'énergie.`;
    const showTooltip = this.props.showTooltip;
    if (
      MaPrimeRenov == null &&
      PrimeHellio == null &&
      this.props.item === 'PrimeReno'
    ) {
      return <div></div>;
    } else {
      return (
        <div style={{ maxWidth: 'fit-content' }}>
          <div className="info-block-container">
            <div className="is-bold mb-10">
              <span className="prime-name">
                {this.props.item === 'Prime1' || this.props.item === 'PrimeReno'
                  ? 'Prime Hellio'
                  : "MaPrimeRénov'"}
              </span>

              {showTooltip && (
                <InfoTooltip>
                  {(this.props.item === 'Prime1' ||
                    this.props.item === 'PrimeReno') && (
                    <>
                      <strong>La prime Hellio</strong> : {PrimeHellioInfo}
                    </>
                  )}
                  {this.props.item === 'Prime2' && (
                    <>
                      <strong>MaPrimeRénov&apos;</strong> : {MaPrimeRenovInfo}
                    </>
                  )}

                  {this.props.item === 'Energy' && (
                    <>
                      Votre étiquette énergétique est une estimation fournie par
                      Go rénove grâce à votre adresse, vous pouvez{' '}
                      <a
                        href="https://particulier.gorenove.fr/"
                        target="_blank"
                        className="text-white"
                        rel="noreferrer"
                      >
                        voir votre diagnostic ici
                      </a>
                      .
                    </>
                  )}

                  {this.props.item === 'CodePromo' && (
                    <strong>Code Promo</strong>
                  )}
                </InfoTooltip>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default InfoHoverBlock;
