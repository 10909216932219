const dataRoutesQ1 = {
  '/isolation': 'isolation',
  '/chauffage': 'chauffage',
  '/renovation-globale': 'renovationGlobale',
  '/panneaux-solaires': 'photovoltaïque',
  '/bornes-de-recharge': 'borneDeRecharge',
  '/audit-energetique': 'auditEnergetique',
  '/conseil': 'nonRenseigne',
};

export default dataRoutesQ1;
