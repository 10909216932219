const dataMPRcolors = {
  bleue: {
    bg: '#4CAFE6',
    display: 'Bleu',
  },
  rose: {
    bg: '#E7A1AA',
    display: 'Rose',
  },
  violette: {
    bg: '#8D86BB',
    display: 'Violet',
  },
  jaune: {
    bg: '#F7D173',
    display: 'Jaune',
  },
};

export default dataMPRcolors;
