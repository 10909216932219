import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import arrowRight from 'images/icons/arrow-right.svg';
import { useSimulation } from '../../../providers/SimulationProvider';

export default function PromoCode({ className }) {
  const { handleCodePromo, codePromo, validCodePromo } = useSimulation();
  return (
    <div className={clsx('flex items-stretch gap-2', className)}>
      <input
        className={
          'bg-white px-3 grow py-2 border border-solid border-xborder rounded placeholder:text-placeholder'
        }
        id={'codePromo'}
        placeholder={'Ajouter un code promo'}
        type="text"
        onChange={handleCodePromo}
        value={codePromo}
      />
      <button
        onClick={validCodePromo}
        className={'border-none bg-xblue px-6 rounded-full flex items-center'}
      >
        <img src={arrowRight} alt="Ajouter" />
      </button>
    </div>
  );
}

PromoCode.propTypes = {
  className: PropTypes.string,
};
