const fakeQuestions = [
  'Q2',
  'Q3',
  'Q4',
  'Q5',
  'Q6',
  'Q7',
  'Q8',
  'Q9',
  'Q10',
  'Q11',
  'Q12',
  'Q13',
  'Q14',
  'Q15',
  'Q16',
  'Q17',
  'Q18',
  'Q19',
  'Q20',
];
const dataPreQuestions = {
  'Conseiller Optimhome': ['OptimhomeAgent', ...fakeQuestions],
  Optimhome: ['OptimhomeClient', ...fakeQuestions],
  'Capifrance agent': ['CapifranceAgent', ...fakeQuestions],
  Capifrance: ['CapifranceClient', ...fakeQuestions],
  agentlaforet: ['LaforetAgent', ...fakeQuestions],
  laforet: ['LaforetClient', ...fakeQuestions],
  'lots-isoles-agent': ['LotsAgent', ...fakeQuestions],
  'laforet.hellio.com/lots-isolés': [
    'LaforetAgentEmailPhone',
    'LaforetEmailPhone',
    ...fakeQuestions,
  ],
  'lots-isoles-adb': ['LotsADB', ...fakeQuestions],
  'Toutvabiens Agent': ['ToutvabiensAgent', ...fakeQuestions],
  'Toutvabiens Particulier': ['ToutvabiensParticulier', ...fakeQuestions],
  'Agent Bricomarche': ['BricomarcheEmail', ...fakeQuestions],
};

export default dataPreQuestions;
