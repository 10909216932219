import React from 'react';

class ErrorHandler extends React.Component {
  render() {
    return (
      <div className="align-center mt-20 p-50" style={{ minHeight: '400px' }}>
        <h1 className="text-main mt-50" style={{ fontSize: '1.4em' }}>
          Désolé, échec de récupération des données.
        </h1>
        <p>{this.props.message}</p>
        <button className="btn-main mt-30 is-bold" onClick={this.props.reset}>
          Réinitialiser
        </button>
        {/* <button className='btn-main'>Retour</button> */}
      </div>
    );
  }
}

export default ErrorHandler;
