import React from 'react';
import './Greeting.scss';
// import dataItems from "../data/dataItems";
const LauraIMG = 'https://hellio.com/hubfs/simulateur/images/personLaura.png';
class Greeting extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.dataItems[this.props.currentStepName].greetingSide
            ? 'm-auto greeting-container'
            : 'm-auto greeting-container'
        }
      >
        {/* <img
                src={LauraIMG}
                alt="greetings"
                width="75"
                className="mr-20 is-hidden-mobile"/> */}
        <div>
          <h3
            className="text-main"
            dangerouslySetInnerHTML={{
              __html: this.props.dataItems[this.props.currentStepName].greeting,
            }}
          ></h3>
          <p className="paragraph mb-10">
            {this.props.dataItems[this.props.currentStepName].paragraph}
          </p>
        </div>
      </div>
    );
  }
}

export default Greeting;
