import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import infosBlack from 'images/icons/infos-black.svg';
import InfoTooltip from '../../InfoTooltip';

export default function PowerRecommandations({
  className,
  activeGamme,
  gammes,
  quantities,
  activeQuantity,
  onGammeChange,
  onQuantityChange,
}) {
  return (
    <div className={className}>
      <div>
        <h2 className="text-[14px] font-medium">Choisissez votre gamme</h2>
        <div className={'pt-2'}>
          {gammes.map((gamme) => {
            const active = gamme.id === activeGamme;
            return (
              <button
                onClick={() => onGammeChange(gamme.id)}
                key={gamme.id}
                className={clsx(
                  `flex items-center gap-4 mt-4 p-3 rounded bg-transparent w-full cursor-pointer`,
                  {
                    'border-2 border-solid border-xorange': active,
                    'border border-solid border-xlight_gray': !active,
                  },
                )}
              >
                <span
                  className={clsx(
                    `px-2 py-1 min-w-[98px] rounded text-[16px]`,
                    {
                      'bg-xorange text-white font-bold': active,
                      'bg-light_orange text-xorange': !active,
                    },
                  )}
                >
                  {gamme.tag}
                </span>
                <span className="mt-1 flex-grow gap-1 flex items-start">
                  <span className={'whitespace-break-spaces align-left'}>
                    {gamme.title}
                  </span>
                  {gamme.gamme_info && (
                    <InfoTooltip
                      className={'bg-black bg-opacity-70'}
                      icon={
                        <img
                          src={infosBlack}
                          className={'w-3 h-3 mt-1'}
                          alt=""
                        />
                      }
                    >
                      <p className={'text-white'}>{gamme.gamme_info}</p>
                      {gamme.url_gamme_info && (
                        <a
                          className={'text-white underline'}
                          href={gamme.url_gamme_info}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          En savoir plus
                        </a>
                      )}
                    </InfoTooltip>
                  )}
                </span>
                <span className="flex flex-col text-right">
                  <span className={'text-nowrap'}>À partir de</span>
                  <span className="font-bold">{gamme.price}</span>
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

PowerRecommandations.propTypes = {
  className: PropTypes.string,
  quantities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      amount: PropTypes.number,
      power: PropTypes.string,
    }),
  ),
  gammes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      gamme_info: PropTypes.string,
      url_gamme_info: PropTypes.string,
    }),
  ),
  activeGamme: PropTypes.string,
  activeQuantity: PropTypes.number,
  onGammeChange: PropTypes.func,
  onQuantityChange: PropTypes.func,
};
