import PropTypes from 'prop-types';
import init from 'images/init.svg';
import back from 'images/icons/arrow-left-black.svg';
import React from 'react';
import clsx from 'clsx';

export default function HeaderNavigation({ onReset, onBack, className }) {
  return (
    <div
      className={clsx('flex justify-between items-center w-full', className)}
    >
      <button className="btn-icon" onClick={onBack}>
        <img src={back} alt="Réinitialiser" className="pr-5" />
        Retour
      </button>
      <button className="btn-icon" onClick={onReset}>
        Réinitialiser
        <img src={init} alt="Réinitialiser" className="pl-5" />
      </button>
    </div>
  );
}

HeaderNavigation.propTypes = {
  onBack: PropTypes.func,
  onReset: PropTypes.func,
  className: PropTypes.string,
};
