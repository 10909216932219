/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import Amount from 'components/Amount';
import SuccessComment from 'components/success/SuccessComment';
import SuccessPage from 'components/success/SuccessPage';

class SuccessBorne extends React.Component {
  render() {
    const price1 = this.props.result.data['Coût travaux unitaire (€)'];

    // Aucun coût de travaux disponible
    if (!price1) {
      return <SuccessComment {...this.props} />;
    }

    return (
      <SuccessPage {...this.props}>
        <div className="success-item-right">
          <h5>Estimation des coûts de travaux</h5>
          <div>
            <h5 className="h5-price text-main whitespace-nowrap">
              <Amount value={price1} />
            </h5>
          </div>
        </div>

        {this.props.resultCodePromo.data.reste_a_charge &&
          this.props.resultCodePromo.data.reste_a_charge !== price1 && (
            <>
              <div className="success-item-right">
                <div className="info-block-container">
                  <p className="p">Code Promo</p>
                </div>
                <div>
                  <h5 className="h5-prime whitespace-nowrap">
                    -{' '}
                    <Amount
                      value={this.props.resultCodePromo.data.montant_promo}
                    />
                  </h5>
                </div>
              </div>
              <div className="success-item-right last">
                <h5>Reste à payer</h5>
                <div>
                  <h5 className="h5-price text-main whitespace-nowrap">
                    <Amount
                      value={this.props.resultCodePromo.data.reste_a_charge}
                    />
                  </h5>
                </div>
              </div>
            </>
          )}

        <div style={{ fontWeight: '200', fontSize: '14px' }}>
          <p className="is-hidden-mobile">
            {dataSuccess[this.props.success].paragraph}
          </p>
          <p className="text-main mv-10" style={{ fontWeight: '400' }}>
            Compris dans le prix :
          </p>

          <ul className="borne-list">
            <li>
              <span>+</span>Protection électrique (disjoncteur dédié)
            </li>
            <li>
              <span>+</span>Cable d’alimentation R2V
            </li>
            <li>
              <span>+</span>Installation par nos électrictiens certifiés IRVE
            </li>
          </ul>
        </div>
      </SuccessPage>
    );
  }
}

export default SuccessBorne;
