// const perduPhoto = 'https://hellio.com/hubfs/simulateur/images/perduPhoto.png';
// const amenagePhoto = 'https://hellio.com/hubfs/simulateur/images/amenagePhoto.png';
import perduPhoto from '../images/perduPhoto.png';
import amenagePhoto from '../images/amenagePhoto.png';
import reglementairePhoto from '../images/reglementairePhoto.png';
import incitatifPhoto from '../images/incitatifPhoto.png';

const maison = 'https://hellio.com/hubfs/simulateur/icons3/maison.svg';
const chauffage = 'https://hellio.com/hubfs/simulateur/icons3/chauffage.svg';
const renovation = 'https://hellio.com/hubfs/simulateur/icons3/renovation.svg';
const solaires = 'https://hellio.com/hubfs/simulateur/icons3/solaires.svg';
const recharge = 'https://hellio.com/hubfs/simulateur/icons3/recharge.svg';
const audit = 'https://hellio.com/hubfs/simulateur/icons3/audit.svg';
const question = 'https://hellio.com/hubfs/simulateur/icons3/question.svg';
const appartement =
  'https://hellio.com/hubfs/simulateur/icons3/appartement.svg';
const tempsA = 'https://hellio.com/hubfs/simulateur/icons3/tempsA.svg';
const tempsB = 'https://hellio.com/hubfs/simulateur/icons3/tempsB.svg';
const tempsC = 'https://hellio.com/hubfs/simulateur/icons3/tempsC.svg';
const isolation1 = 'https://hellio.com/hubfs/simulateur/icons3/isolation1.svg';
const isolation2 = 'https://hellio.com/hubfs/simulateur/icons3/isolation2.svg';
const isolation3 = 'https://hellio.com/hubfs/simulateur/icons3/isolation3.svg';
const isolation4 = 'https://hellio.com/hubfs/simulateur/icons3/isolation4.svg';
const q5a = 'https://hellio.com/hubfs/simulateur/icons3/q5a.svg';
const q5b = 'https://hellio.com/hubfs/simulateur/icons3/q5b.svg';
const mur1 = 'https://hellio.com/hubfs/simulateur/icons3/mur1.svg';
const mur2 = 'https://hellio.com/hubfs/simulateur/icons3/mur2.svg';
const q7a = 'https://hellio.com/hubfs/simulateur/icons3/q7a.svg';
const q7b = 'https://hellio.com/hubfs/simulateur/icons3/q7b.svg';
const q7c = 'https://hellio.com/hubfs/simulateur/icons3/q7c.svg';
const q7d = 'https://hellio.com/hubfs/simulateur/icons3/q7d.svg';
const oui = 'https://hellio.com/hubfs/simulateur/icons3/oui.svg';
const non = 'https://hellio.com/hubfs/simulateur/icons3/non.svg';
const kwA = 'https://hellio.com/hubfs/simulateur/icons3/3kw.svg';
const kwB = 'https://hellio.com/hubfs/simulateur/icons3/7kw.svg';
const kwC = 'https://hellio.com/hubfs/simulateur/icons3/11kw.svg';
const kwD = 'https://hellio.com/hubfs/simulateur/icons3/22kw.svg';
const borneA = 'https://hellio.com/hubfs/simulateur/icons3/borneDIstanceA.svg';
const borneB = 'https://hellio.com/hubfs/simulateur/icons3/borneDIstanceB.svg';
const borneC = 'https://hellio.com/hubfs/simulateur/icons3/borneDIstanceC.svg';
const borneD = 'https://hellio.com/hubfs/simulateur/icons3/borneDIstanceD.svg';
const borneE = 'https://hellio.com/hubfs/simulateur/icons3/borneDIstanceE.svg';
const localPro = 'https://hellio.com/hubfs/simulateur/icons3/localpro.svg';
const localProApt =
  'https://hellio.com/hubfs/simulateur/icons3/localproapt.svg';
const contratA = 'https://hellio.com/hubfs/simulateur/icons3/contratA.svg';
const contratB = 'https://hellio.com/hubfs/simulateur/icons3/contratB.svg';
const autres = 'https://hellio.com/hubfs/simulateur/icons3/autres.svg';
// const copro = 'https://hellio.com/hubfs/simulateur/icons3/copro.svg';
const gaz = 'https://hellio.com/hubfs/simulateur/icons3/gaz.svg';
const fioul = 'https://hellio.com/hubfs/simulateur/icons3/fioul.svg';
const electricite =
  'https://hellio.com/hubfs/simulateur/icons3/electricite.svg';
const pompe = 'https://hellio.com/hubfs/simulateur/icons3/pompe.svg';
const bois = 'https://hellio.com/hubfs/simulateur/icons3/bois.svg';
const panneaux = 'https://hellio.com/hubfs/simulateur/icons3/panneaux.svg';
const charbon = 'https://hellio.com/hubfs/simulateur/icons3/charbon.svg';
const occupant = 'https://hellio.com/hubfs/simulateur/icons3/occupant.svg';
const nonOccupant =
  'https://hellio.com/hubfs/simulateur/icons3/non-occupant.svg';
const bailleur = 'https://hellio.com/hubfs/simulateur/icons3/bailleur.svg';
const locataire = 'https://hellio.com/hubfs/simulateur/icons3/locataire.svg';
const fioulA = 'https://hellio.com/hubfs/simulateur/icons3/fioulA.svg';
const fioulB = 'https://hellio.com/hubfs/simulateur/icons3/fioulB.svg';
const fioulC = 'https://hellio.com/hubfs/simulateur/icons3/fioulC.svg';
const electricA = 'https://hellio.com/hubfs/simulateur/icons3/electricA.svg';
const electricB = 'https://hellio.com/hubfs/simulateur/icons3/electricB.svg';
const electricC = 'https://hellio.com/hubfs/simulateur/icons3/electricC.svg';
const radiateurGaz =
  'https://hellio.com/hubfs/simulateur/icons3/radiateurGaz.svg';
const poeleGaz = 'https://hellio.com/hubfs/simulateur/icons3/poeleGaz.svg';
const temps1 = 'https://hellio.com/hubfs/simulateur/icons3/temps1.svg';
const temps2 = 'https://hellio.com/hubfs/simulateur/icons3/temps2.svg';
const temps3 = 'https://hellio.com/hubfs/simulateur/icons3/temps3.svg';
const temps4 = 'https://hellio.com/hubfs/simulateur/icons3/temps4.svg';
const adviceA = 'https://hellio.com/hubfs/simulateur/icons3/adviceA.svg';
const adviceB = 'https://hellio.com/hubfs/simulateur/icons3/adviceB.svg';
const adviceC = 'https://hellio.com/hubfs/simulateur/icons3/adviceC.svg';
const newsletterA =
  'https://hellio.com/hubfs/simulateur/icons3/newsletterA.svg';
const newsletterB =
  'https://hellio.com/hubfs/simulateur/icons3/newsletterB.svg';
const charbonA = 'https://hellio.com/hubfs/simulateur/icons3/charbonA.svg';
const charbonB = 'https://hellio.com/hubfs/simulateur/icons3/charbonB.svg';
const menuiserie = 'https://hellio.com/hubfs/simulateur/icons3/menuiserie.svg';
const plomberie = 'https://hellio.com/hubfs/simulateur/icons3/plomberie.svg';
const ventilation =
  'https://hellio.com/hubfs/simulateur/icons3/ventilation.svg';
const maconnerie = 'https://hellio.com/hubfs/simulateur/icons3/maconnerie.svg';
const amenagementINT =
  'https://hellio.com/hubfs/simulateur/icons3/amenagementINT.svg';
const auditA = 'https://hellio.com/hubfs/simulateur/icons3/auditA.svg';
const auditB = 'https://hellio.com/hubfs/simulateur/icons3/auditB.svg';
const fenetreA =
  'https://hellio.com/hubfs/simulateur/icons3/FenetreToiture.svg';
const fenetreB = 'https://hellio.com/hubfs/simulateur/icons3/Fenetre.svg';
const gaz1 = 'https://hellio.com/hubfs/simulateur/icons3/gaz.svg';
const gaz2 = 'https://hellio.com/hubfs/simulateur/icons3/gaz2.svg';
const classeA = 'https://hellio.com/hubfs/simulateur/icons3/classeA.svg';
const classeB = 'https://hellio.com/hubfs/simulateur/icons3/classeB.svg';
const classeC = 'https://hellio.com/hubfs/simulateur/icons3/classeC.svg';
const classeD = 'https://hellio.com/hubfs/simulateur/icons3/classeD.svg';
const classeE = 'https://hellio.com/hubfs/simulateur/icons3/classeE.svg';
const classeF = 'https://hellio.com/hubfs/simulateur/icons3/classeF.svg';
const classeG = 'https://hellio.com/hubfs/simulateur/icons3/classeG.svg';
const accompagnateur =
  'https://www.hellio.com/hubfs/simulateur/icons3/accompagnateur.svg';
const thermostat =
  'https://www.hellio.com/hubfs/simulateur/icons3/thermostat.svg';
const logementCollectif =
  'https://www.hellio.com/hubfs/simulateur/icons3/tags/logementCollectif.svg';
const gestionnaireLocatif =
  'https://www.hellio.com/hubfs/simulateur/icons3/tags/gestionnaireLocatif.svg';
const thermostatSansFil =
  'https://www.hellio.com/hubfs/simulateur/icons3/tags/thermostatSansFil.svg';
const thermostatFilaire =
  'https://www.hellio.com/hubfs/simulateur/icons3/tags/thermostatFilaire.svg';
export const Q1OptionToSkip = ['borneDeRecharge'];

const dataItems = {
  Q1: {
    greeting: 'Bonjour, quel projet souhaitez-vous réaliser ?',
    // 'paragraph': 'Vous pouvez sélectionner une ou plusieurs réponses.',
    items: [
      { id: 1, short: 'isolation', long: 'Isoler mon logement', icon: maison },
      {
        id: 2,
        short: 'chauffage',
        long: 'Changer mon chauffage',
        icon: chauffage,
      },
      {
        id: 3,
        short: 'renovationGlobale',
        long: 'Rénovation d’ampleur',
        icon: renovation,
      },
      {
        id: 4,
        short: 'photovoltaïque',
        long: 'Installer des panneaux solaires',
        icon: solaires,
      },
      {
        id: 4,
        short: 'thermostat',
        long: 'Installation de Thermostat',
        icon: thermostat,
      },
      {
        id: 5,
        short: 'borneDeRecharge',
        long: 'Borne de recharge',
        icon: recharge,
      },
      {
        id: 6,
        short: 'auditEnergetique',
        long: 'Audit énergétique',
        icon: audit,
      },
      // { id: 7, short: "primeHellio", long: "J'ai déjà un devis et je veux une prime", icon: adviceB },
      {
        id: 9,
        short: 'accompagnateurRenov',
        long: 'Je souhaite avoir un Accompagnateur Rénov’',
        icon: accompagnateur,
      },
      {
        id: 8,
        short: 'nonRenseigne',
        long: 'J’hésite encore, je souhaite être conseillé',
        icon: question,
      },
    ],
  },

  Q2: {
    greeting: 'Avez-vous une maison ou un appartement ?',
    items: [
      { short: 'Maison individuelle', long: 'Maison', icon: maison },
      { short: 'Appartement', long: 'Appartement', icon: appartement },
    ],
  },

  'Q2.V2': {
    greeting: 'Avez-vous une maison ou un appartement ?',
    items: [
      { short: 'Maison individuelle', long: 'Maison', icon: maison },
      { short: 'Appartement', long: 'Appartement', icon: appartement },
    ],
  },

  Q3: {
    greeting: 'Votre logement a été construit il y a :',
    items: [
      { short: 'Moins de 2 ans', long: 'Moins de 2 ans', icon: tempsA },
      { short: 'Entre 2 et 15 ans', long: 'Entre 2 et 15 ans', icon: tempsB },
      { short: 'Plus de 15 ans', long: 'Plus de 15 ans', icon: tempsC },
    ],
  },
  Q4: {
    greeting: 'Que souhaitez-vous isoler ?',
    paragraph:
      ' Si vous souhaitez isoler votre garage ou sous-sol, ceux-ci ne doivent pas être chauffés.',
    items: [
      {
        short: 'Isolation de combles ou de toitures',
        long: 'Des combles',
        icon: isolation1,
      },
      { short: 'Isolation des murs', long: 'Des murs', icon: isolation2 },
      { short: 'garage', long: 'Un garage ou sous-sol', icon: isolation3 },
      { short: 'vide', long: 'Un vide sanitaire', icon: isolation4 },
    ],
  },
  Q5: {
    greeting: 'Les combles de votre maison sont :',
    minHeight: '350px',
    items: [
      {
        short: 'Perdus',
        long: 'Perdus',
        icon: q5a,
        img: perduPhoto,
        text: "L’isolation se fait au sol - Les combles perdus sont aussi appelés combles non aménageables ou combles visitables. C'est un espace sous la toiture difficile, voire impossible à aménager à cause d’une hauteur libre moyenne insuffisante, ou d’une mauvaise disposition des bois de la charpente.",
      },
      {
        short: 'Aménagés',
        long: 'Aménagés',
        icon: q5b,
        img: amenagePhoto,
        text: 'L’isolation se fait au plafond - Les combles aménagés sont connus sous plusieurs autres appellations comme les combles accessibles ou les combles habitables. Ils désignent des combles qui peuvent être aménagés en lieux de vie, une pièce à part entière de la maison. ',
      },
      // { short: "Non renseigné", long: "Je ne sais pas", icon: question },
    ],
  },

  Q6: {
    greeting: 'Vous souhaitez isoler vos murs :',
    items: [
      { short: 'ITE', long: 'Par l’extérieur', icon: mur1 },
      { short: 'ITI', long: 'Par l’intérieur', icon: mur2 },
    ],
  },

  Q7: {
    greeting:
      'Avez-vous une préférence pour le nouveau chauffage de votre logement ?',
    items: [
      { short: 'Pompe à chaleur', long: 'Pompe à chaleur', icon: q7a },
      { short: 'Chaudière', long: 'Chaudière', icon: q7b },
      // { short: "Chaudière biomasse", long: "Chaudière biomasse (bois)", icon: q7c },
      // { short: "Poêle à bois", long: "Poêle à bois", icon: q7d },
      {
        short: 'Non renseigné',
        long: 'Je n’ai pas de préférences',
        icon: question,
      },
    ],
  },

  Q8: {
    greeting: 'Quelle est la surface de votre logement ?',
    paragraph: 'Veuillez saisir la surface en m²',
    items: [
      {
        label: 'Surface (m²)',
        name: 'Q8',
        placeholder: '150',
        type: 'number',
        min: 10,
        step: 10,
      },
    ],
  },

  Q9: {
    greeting:
      'Avez-vous déjà votre véhicule électrique ou hybride rechargeable ?',
    items: [
      { short: 'true', long: 'Oui', icon: oui },
      { short: 'false', long: 'Non', icon: non },
    ],
  },

  Q10: {
    greeting: 'Quel est le modèle de votre voiture ?',
    items: [
      { label: 'Modèle', name: 'Q10', placeholder: 'Citroën C4', type: 'text' },
    ],
  },
  Q11: {
    greeting:
      'Connaissez-vous la puissance de recharge dont vous avez besoin ?',
    paragraph: 'kW = Kilowatt',
    items: [
      { short: '3 kW', long: '3 kW', icon: kwA },
      { short: '7 kW', long: '7 kW', icon: kwB },
      { short: '11 kW', long: '11 kW', icon: kwC },
      { short: '22 kW', long: '22 kW', icon: kwD },
      { short: 'Je ne sais pas', long: 'Je ne sais pas', icon: question },
    ],
  },
  Q12: {
    greeting:
      "Quelle est la distance entre votre tableau électrique et l'emplacement souhaité pour votre borne de recharge ?",
    items: [
      {
        short: 'Entre 0 et 10 mètres',
        long: 'Entre 0 et 10 mètres',
        icon: borneA,
      },
      { short: '15 mètres', long: '15 mètres', icon: borneB },
      { short: '20 mètres', long: '20 mètres', icon: borneC },
      { short: '25 mètres', long: '25 mètres', icon: borneD },
      { short: '+ 25 mètres', long: '+ de 25 mètres', icon: borneE },
      { short: 'Je ne sais pas', long: 'Je ne sais pas ', icon: question },
    ],
  },

  Q13: {
    greeting:
      'Dans quel type de local ou logement doit être installer votre borne de recharge ?',
    items: [
      { short: 'Maison individuelle', long: 'Une maison', icon: maison },
      { short: 'Appartement', long: 'Un appartement', icon: appartement },
      {
        short: 'Local professionnel',
        long: 'Un local professionnel',
        icon: localPro,
      },
      {
        short: 'Local professionnel appartement',
        long: 'Un local professionnel en appartement',
        icon: localProApt,
      },
    ],
  },

  Q14: {
    greeting: 'Avez-vous réalisé un audit énergétique pour ce logement ?',
    paragraph:
      "Un audit énergétique permet d'évaluer la performance énergétique actuelle du logement et d'établir les scénarios de rénovation énergétique.",
    items: [
      { short: 'Oui', long: 'Oui', icon: oui },
      { short: 'Non', long: 'Non', icon: non },
    ],
  },

  Q15: {
    greeting:
      'Merci, quel est le montant de votre facture mensuelle d’électricité en euros ? ',
    paragraph:
      'Cela nous permettra de calculer vos économies d’énergie afin de baisser votre facture d’électricité.',
    items: [
      {
        label: 'Montant',
        name: 'Q15',
        placeholder: '89',
        type: 'number',
        unit: '€',
        min: 10,
        step: 10,
      },
    ],
  },

  Q16: {
    greeting: 'Quel est le type de contrat électrique que vous avez ?',
    items: [
      { short: 'Base', long: 'Contrat de base', icon: contratA },
      {
        short: 'Heures pleines heures creuses',
        long: 'Contrat heure creuse / heure pleine',
        icon: contratB,
      },
      { short: 'Autre', long: 'Autres', icon: autres },
    ],
  },

  Q17: {
    greeting: 'Votre projet de rénovation concerne :',
    items: [
      {
        short: 'Logement individuelle',
        long: 'Votre logement individuel',
        icon: maison,
      },
      { short: 'Copropriété', long: 'La copropriété', icon: appartement },
    ],
  },

  Q18: {
    greeting: 'Pouvez-vous remplir les coordonnées de votre syndic :',
    paragraph: 'Tous les champs sont obligatoires.',
    items: [
      {
        label: 'Prénom',
        name: 'syndicFirstname',
        placeholder: 'Pierre',
        type: 'text',
      },
      {
        label: 'Nom',
        name: 'syndicLastname',
        placeholder: 'Roche',
        type: 'text',
      },
      {
        label: 'Téléphone',
        name: 'syndicMobilephone',
        placeholder: '0146821239',
        type: 'tel',
        validation: 'phone',
        maxLength: 10,
        minLength: 10,
      },
      {
        label: 'E-mail',
        name: 'syndicEmail',
        placeholder: 'pierre.roche@gmail.com',
        type: 'email',
        validation: 'email',
      },
    ],
    salutation: [
      { label: 'Civilité', name: 'syndicSalutation', value: 'Madame' },
      { label: 'Civilité', name: 'syndicSalutation', value: 'Monsieur' },
    ],
  },

  Q19: {
    greeting: 'Quels sont les revenus de votre ménage ?',
    greetingSide: true,
    paragraph:
      "Ces informations nous permettront de vous donner la couleur de votre ménage MaPrimeRénov' ",
  },

  Q20: {
    greeting: 'Dans votre logement, vous êtes ?',
    items: [
      {
        short: 'Propriétaire occupant',
        long: 'Propriétaire occupant',
        icon: occupant,
      },
      {
        short: 'Propriétaire non-occupant',
        long: 'Propriétaire non-occupant',
        icon: nonOccupant,
      },
      {
        short: 'Propriétaire bailleur',
        long: 'Propriétaire bailleur',
        icon: bailleur,
        tooltip: true,
      },
      { short: 'Locataire', long: 'Locataire', icon: locataire },
    ],
  },

  'Q20.V2': {
    greeting: 'Dans votre logement, vous êtes ?',
    items: [
      {
        short: 'Propriétaire occupant',
        long: 'Propriétaire occupant',
        icon: occupant,
      },
      {
        short: 'Propriétaire non-occupant',
        long: 'Propriétaire non-occupant',
        icon: nonOccupant,
      },
      {
        short: 'Propriétaire bailleur',
        long: 'Propriétaire bailleur',
        icon: bailleur,
        tooltip: true,
      },
      { short: 'Locataire', long: 'Locataire', icon: locataire },
    ],
  },

  Q21: {
    greeting:
      'En tant que propriétaire bailleur, quelle est l’adresse de votre résidence principale ?',
    paragraph:
      'Les aides à la rénovation énergétique varient en fonction de votre localisation.',
    items: [
      { label: 'Adresse', name: 'street', placeholder: '113 rue du soiemont' },
      {
        label: 'Ville',
        name: 'city',
        placeholder: 'Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson',
      },
      {
        label: 'Code postal',
        name: 'postalcode',
        placeholder: '51290',
        validation: 'codepostal',
        maxLength: 5,
        minLength: 5,
      },
    ],
  },

  Q22: {
    greeting: 'Quelle est l’énergie de chauffage actuelle de votre logement ?',
    items: [
      { short: 'Gaz', long: 'Gaz', icon: gaz },
      { short: 'Fioul', long: 'Fioul', icon: fioul },
      { short: 'Electricité', long: 'Électricité', icon: electricite },
      { short: 'Pompe à chaleur', long: 'Pompe à chaleur', icon: pompe },
      { short: 'Bois', long: 'Bois', icon: bois },
      // { short: "Solaire thermique", long: "Solaire thermique", icon: panneaux },
      { short: 'Charbon', long: 'Charbon', icon: charbon },
      // { short: "Je ne suis pas chauffé(e)", long: "Je ne suis pas chauffé(e)", icon: non },
    ],
  },

  Q23: {
    greeting: "Quel type d'équipement au fioul est installé ?",
    // 'paragraph': "Les chaudières installées depuis moins de 10 ans sont généralement à condensation",
    items: [
      // { short: "Chaudière à fioul à condensation", long: "Chaudière à condensation", icon: fioulA },
      {
        short: 'Chaudière à fioul à basse température',
        long: 'Chaudière',
        icon: fioulB,
      },
      { short: 'Poêle fioul', long: 'Poêle', icon: fioulC },
    ],
  },

  Q24: {
    greeting: "Quel type d'équipement électrique est installé ?",
    items: [
      {
        short: 'Chaudière électrique',
        long: 'Chaudière électrique',
        icon: electricA,
      },
      {
        short: 'Plafonds ou plancher chauffants',
        long: 'Plafond ou plancher chauffant',
        icon: electricB,
      },
      {
        short: 'Radiateurs électriques',
        long: 'Radiateurs électriques',
        icon: electricC,
      },
    ],
  },

  Q25: {
    greeting: "Quel type d'équipement au gaz est installé ?",
    // 'paragraph': "Les chaudières installées depuis moins de 10 ans sont généralement à condensation",
    items: [
      // { short: "Chaudière gaz à condensation", long: "Chaudière à condensation", icon: fioulA },
      { short: 'Chaudière gaz classique', long: 'Chaudière', icon: fioulB },
      { short: 'Radiateur gaz', long: 'Radiateur au gaz', icon: radiateurGaz },
      { short: 'Poêle au gaz', long: 'Poêle au gaz', icon: poeleGaz },
    ],
  },

  Q26: {
    greeting: "Merci, quelle est l'adresse du logement à rénover ?",
    paragraph:
      'L’adresse de votre logement est nécessaire pour calculer les aides que vous pourrez obtenir et estimer votre étiquette énergétique.',
    items: [
      { label: 'Adresse', name: 'street', placeholder: '113 rue du soiemont' },
      {
        label: 'Ville',
        name: 'city',
        placeholder: 'Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson',
      },
      {
        label: 'Code postal',
        name: 'postalcode',
        placeholder: '51290',
        validation: 'codepostal',
        maxLength: 5,
        minLength: 5,
      },
    ],
  },

  Q27: {
    greeting: 'Quelle est votre adresse fiscale ?',
    paragraph: 'Tous les champs sont obligatoires.',
    items: [
      { label: 'Adresse', name: 'street', placeholder: '113 rue du soiemont' },
      {
        label: 'Ville',
        name: 'city',
        placeholder: 'Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson',
      },
      {
        label: 'Code postal',
        name: 'postalcode',
        placeholder: '51290',
        validation: 'codepostal',
        maxLength: 5,
        minLength: 5,
      },
    ],
  },

  Q28: {
    greeting:
      'Parfait ! Sous combien de temps souhaitez-vous réaliser ces travaux ?',
    items: [
      { short: 'Dès que possible', long: 'Dès que possible', icon: temps1 },
      { short: 'Moins de 3 mois', long: 'Moins de 3 mois', icon: temps2 },
      { short: 'Moins de 6 mois', long: 'Moins de 6 mois', icon: temps3 },
      { short: 'Plus de 6 mois', long: 'Plus de 6 mois', icon: temps4 },
    ],
  },

  Q29: {
    greeting:
      'Merci, maintenant apprenons à nous connaître. <br/> Pouvez-vous remplir vos coordonnées :',
    paragraph: '',
    optional: true,
    policy: true,
    items: [
      {
        label: 'Prénom',
        name: 'firstname',
        placeholder: 'Pierre',
        type: 'text',
      },
      { label: 'Nom', name: 'lastname', placeholder: 'Roche', type: 'text' },
      {
        label: 'Téléphone',
        name: 'mobilephone',
        placeholder: '0146821239',
        type: 'tel',
        validation: 'phone',
        maxLength: 10,
        minLength: 10,
      },
      {
        label: 'E-mail',
        name: 'email',
        placeholder: 'pierre.roche@gmail.com',
        type: 'email',
        validation: 'email',
      },
    ],
    salutation: [
      { label: 'Civilité', name: 'salutation', value: 'Madame' },
      { label: 'Civilité', name: 'salutation', value: 'Monsieur' },
    ],
  },

  Q30: {
    greeting: "Quel type d'accompagnement souhaitez-vous ?",
    items: [
      // { short: "comparaisonDevis", long: "Je veux comparer mon devis", icon: adviceA },
      {
        short: 'primeHellio',
        long: "J'ai déjà un devis et je veux une prime Hellio",
        icon: adviceB,
      },
      {
        short: 'simulationTravaux',
        long: 'Je veux simuler le coût de mes travaux et ma prime',
        icon: adviceC,
      },
    ],
  },

  Q31: {
    greeting: 'Avez-vous un code partenaire ou parrain ?',
    items: [
      { short: 'true', long: 'Oui', icon: oui },
      { short: 'false', long: 'Non', icon: non },
    ],
  },

  Q32: {
    greeting: 'Remplissez votre code partenaire ou parrain :',
    items: [{ label: 'Code', name: 'Q32', placeholder: 'BDICC3000' }],
  },

  Q33: {
    greeting: 'Souhaitez-vous recevoir la newsletter Hellio ?',
    paragraph:
      "Restez informé(e) sur les économies d'énergies et les aides à la rénovation.",
    items: [
      {
        short: 'Oui, 1 fois par mois',
        long: 'Oui, 1 fois par mois',
        icon: newsletterA,
      },
      {
        short: 'Oui, 1 fois par trimestre',
        long: 'Oui, 1 fois par trimestre',
        icon: newsletterB,
      },
      {
        short: 'Je ne suis pas intéressé(e)',
        long: 'Je ne suis pas intéressé',
        icon: non,
      },
    ],
  },

  Q34: {
    greeting: "Quel type d'équipement au charbon est installé ?",
    items: [
      { short: 'Chaudière à charbon', long: 'Chaudière', icon: charbonA },
      { short: 'Autre', long: 'Poêle ou autre', icon: charbonB },
    ],
  },

  Q35: {
    greeting: 'Quels travaux de rénovation envisagez-vous ?',
    paragraph:
      'Pour être éligible à la rénovation d’ampleur, vous devez au minimum sélectionner 2 postes d’isolation',
    items: [
      { short: 'isolation', long: 'Isolation', icon: maison },
      { short: 'chauffage', long: 'Chauffage', icon: chauffage },
      { short: 'menuiserie', long: 'Menuiserie', icon: menuiserie },
      { short: 'ventilation', long: 'Ventilation', icon: ventilation },
      { short: 'maçonnerie', long: 'Maçonnerie', icon: maconnerie },
      { short: 'plomberie', long: 'Plomberie', icon: plomberie },
      { short: 'électricité', long: 'Électricité', icon: electricite },
      {
        short: 'aménagement int',
        long: 'Aménagement d’intérieur',
        icon: amenagementINT,
      },
    ],
  },

  Q36: {
    greeting: 'Qu’est ce qu’une rénovation d’ampleur ?',
    content:
      'À ne pas confondre avec les travaux de peinture, maçonnerie, plomberie…, la rénovation énergétique d’ampleur désigne un ensemble de travaux coordonnés pour réaliser un maximum d’économies d’énergie',
    list: [
      'Travaux préconisés par un bilan énergétique en amont',
      'Gain de confort optimal pour les occupants',
      'Projet éligible à des financements plus importants qu’en réalisant les travaux un par un',
    ],
  },

  Q37: {
    greeting: 'Pourquoi faire réaliser un audit énergétique ?',
    content:
      'Ce bilan permet de déterminer les travaux de rénovation d’ampleur les plus efficaces, avec la garantie d’atteindre un certain niveau de performance énergétique.',
    list: [
      'Le professionnel calcule les économies d’énergie réelles et les caractéristiques thermiques du logement',
      'Primes disponibles sans condition de ressources : un atout pour les ménages aux revenus intermédiaires ou supérieurs',
      'Ouvre droit à un maximum d’aides financières pour rénover : l’investissement est facilement rentabilisé',
    ],
  },

  Q38: {
    greeting: '',
    title: 'Votre inscription a bien été pris en compte.',
    paragraph:
      'Un expert Hellio vous rappelera dans les plus brefs délais pour donner suite à votre projet.',
  },

  Q39: {
    greeting: 'Que souhaitez-vous isoler ?',
    paragraph:
      ' Si vous souhaitez isoler votre garage ou sous-sol, celui-ci ne doit pas être chauffé.',
    items: [
      {
        short: 'Isolation de combles ou de toitures',
        long: 'Des combles',
        icon: isolation1,
      },
      { short: 'Isolation des murs', long: 'Des murs', icon: isolation2 },
      { short: 'garage', long: 'Un garage ou sous-sol', icon: isolation3 },
      { short: 'vide', long: 'Un vide sanitaire', icon: isolation4 },
    ],
  },

  Q40: {
    greeting:
      'Avez-vous une préférence pour le nouveau chauffage de votre logement ?',
    items: [
      { short: 'Pompe à chaleur air/eau', long: 'Pompe à chaleur', icon: q7a },
      { short: 'Chaudière gaz', long: 'Chaudière à gaz', icon: q7b },
      {
        short: 'Chaudière biomasse',
        long: 'Chaudière à granulés de bois',
        icon: q7c,
      },
      // { short: "Poêle à bois", long: "Poêle à bois", icon: q7d },
      {
        short: 'Non renseigné',
        long: 'Je n’ai pas de préférences',
        icon: question,
      },
    ],
  },

  Q43: {
    greeting: 'Quel est le montant de votre devis ?',
    items: [
      {
        label: 'Montant des travaux',
        name: 'Q43',
        placeholder: '150',
        type: 'number',
        min: 10,
        step: 10,
      },
    ],
  },

  Q44: {
    greeting: 'Quel est la surface à isoler ?',
    paragraph:
      'Le coût et les aides de vos travaux sont calculés à partir de la surface à isoler et non la surface habitable. Veuillez saisir la surface en m²',
    items: [
      {
        label: 'Surface (m²)',
        name: 'Q44',
        placeholder: '150',
        type: 'number',
        min: 10,
        step: 10,
      },
    ],
  },

  Q45: {
    greeting: 'Vous avez un devis pour quel type de travaux ?',
    items: [
      [
        { short: 'Isolation des combles', long: 'Des combles' },
        { short: 'Isolation des murs', long: 'Des murs' },
        { short: 'Fenêtre', long: 'Des fenêtres' },
        {
          short: "Isolation d'un plancher",
          long: 'Un vide sanitaire, plancher bas ou sous sol',
        },
      ],
      [
        { short: 'PAC eau/eau', long: 'Pompe à chaleur eau/eau' },
        { short: 'Poêle à bois', long: 'Poêle à bois' },
        { short: 'PAC air/eau', long: 'Pompe à chaleur air/eau' },
        {
          short: 'Réseau de chaleur',
          long: 'Raccordement à un réseau de chaleur',
        },
        {
          short: 'PAC hybride air/eau',
          long: 'Pompe à chaleur hybride air/eau',
        },
        {
          short: 'Chaudière à granulé de bois',
          long: 'Chaudière biomasse (bois)',
        },
        { short: 'Poêle à granulé de bois', long: 'Poêle à granulé de bois' },
        { short: 'Système solaire combiné', long: 'Système solaire combiné' },
        { short: 'Chaudière gaz', long: 'Chaudière à gaz' },
      ],
      [
        {
          short: 'Chauffe-eau thermodynamique',
          long: 'Chauffe-eau thermodynamique',
        },
        {
          short: 'Chauffe-eau solaire individuel',
          long: 'Chauffe-eau solaire individuel (ECS)',
        },
      ],
      [
        { short: 'VMC simple flux', long: 'VMC Simple flux' },
        { short: 'VMC double flux', long: 'VMC double flux' },
      ],
      [{ short: 'Autre', long: 'Autre travaux' }],
    ],
  },

  Q46: {
    greeting: 'Combien de fenêtres souhaitez-vous installer ?',
    items: [
      {
        label: '',
        name: 'Q46',
        placeholder: '3',
        type: 'number',
        min: 1,
        step: 1,
      },
    ],
  },

  Q47: {
    greeting: 'Quelle est la surface chauffée ?',
    paragraph: 'Veuillez saisir la surface en m²',
    items: [
      {
        label: 'Surface (m²)',
        name: 'Q47',
        placeholder: '150',
        type: 'number',
        min: 5,
        step: 10,
      },
    ],
  },

  Q48: {
    greeting: 'Pourquoi souhaitez-vous réaliser un audit énergétique ?',
    items: [
      {
        short: 'Audit énergétique',
        long: 'Audit incitatif',
        icon: auditA,
        img: incitatifPhoto,
        text: 'Je souhaite un audit énergétique pour réaliser des travaux de rénovation énergétique et obtenir des aides',
      },
      {
        short: 'Audit règlementaire',
        long: 'Audit réglementaire',
        icon: auditB,
        img: reglementairePhoto,
        text: 'Je dois réaliser un audit énergétique pour vendre mon bien classé F ou G au DPE',
      },
    ],
  },
  Q49: {
    greeting: 'Quel type de fenêtre souhaiteriez-vous installer ?',
    items: [
      {
        short: 'Portes-fenêtres',
        long: 'Fenêtres ou Portes-fenêtres complètes avec vitrage isolant',
        icon: fenetreB,
      },
      { short: 'Fenêtres', long: 'Fenêtres de toitures', icon: fenetreA },
    ],
  },

  Q50: {
    greeting: 'Quel type de pompe à chaleur souhaitez-vous installer ? ',
    items: [
      {
        short: 'Pompe à chaleur air/air',
        long: 'Pompe à chaleur air/air',
        icon: gaz1,
      },
      {
        short: 'Pompe à chaleur air/eau',
        long: 'Pompe à chaleur air/eau',
        icon: gaz2,
      },
      {
        short: 'Pompe à chaleur eau/eau',
        long: 'Pompe à chaleur eau/eau',
        icon: gaz2,
      },
      { short: 'Non renseigné', long: 'Je ne sais pas', icon: question },
    ],
  },

  Q51: {
    greeting: "Quel est l'usage de votre pompe à chaleur ?",
    items: [
      { short: 'Chauffage seul', long: 'Chauffage uniquement', icon: gaz1 },
      {
        short: 'Chauffage + Eau Chaude Sanitaire',
        long: 'Chauffage et eau chaude sanitaire',
        icon: gaz2,
      },
      {
        short: 'Je ne sais pas',
        long: 'Je ne sais pas et je souhaite être conseillé',
        icon: question,
      },
    ],
  },

  Q52: {
    greeting: 'Quel type de chaudière souhaitez-vous installer ? ',
    items: [
      { short: 'Chaudière gaz', long: 'Chaudière à gaz', icon: q7b },
      { short: 'Chaudière biomasse', long: 'Chaudière biomasse', icon: q7c },
    ],
  },

  Q53: {
    greeting: "Quelle est l'étiquette énergétique de votre logement ?",
    paragraph:
      'L’étiquette énergétique (Diagnostic de performance énergétique ou DPE) permet d’évaluer la consommation énergétique de votre logement',
    items: [
      { short: 'A', long: '', icon: classeA },
      { short: 'B', long: '', icon: classeB },
      { short: 'C', long: '', icon: classeC },
      { short: 'D', long: '', icon: classeD },
      { short: 'E', long: '', icon: classeE },
      { short: 'F', long: '', icon: classeF },
      { short: 'G', long: '', icon: classeG },
      {
        short: 'Je ne sais pas',
        long: "Je ne connais pas l'étiquette énergétique de mon logement",
      },
    ],
  },

  'Q4.V2': {
    text: "Vous avez un projet de rénovation d'ampleur et vous souhaitez obtenir de l'aide grâce au programme Mon Accompagnateur Rénov'. Inscrivez-vous sur Hellio Ingénierie.",
    redirect:
      'https://form.typeform.com/to/Vtlr0zCP?originedelapiste=Simulateur%20B2C&typeform-source=www.google.com',
    image:
      'https://www.hellio.com/hubfs/simulateur/images/Hellio-ingenierie_LogoBaseline_RVB_Couleurs1.png',
  },

  'Q53.V2': {
    greeting: 'Quel type de chauffage utilisez-vous ?',
    items: [
      { short: 'Individuel', long: 'Individuel', icon: nonOccupant },
      { short: 'Collectif', long: 'Collectif', icon: appartement },
    ],
  },

  Q54: {
    greeting: 'Avez-vous des robinets thermostatiques en place ?',
    items: [
      { short: 'Oui', long: 'Oui', icon: oui },
      { short: 'Non', long: 'Non', icon: non },
    ],
  },
  Q55: {
    greeting: 'Avez-vous un thermostat existant ?',
    items: [
      {
        short: 'Oui, en filaire',
        long: 'Oui, en filaire',
        icon: thermostatFilaire,
      },
      {
        short: 'Oui, sans fil',
        long: 'Oui, sans fil',
        icon: thermostatSansFil,
      },
      { short: 'Non', long: 'Non', icon: non },
    ],
  },
  Q56: {
    greeting:
      'Quelle est le nombre d’émetteurs de chaleur dans votre logement ?',
    labelNoWrap: true,
    items: [
      {
        label: 'Nombre d’émetteurs',
        name: 'Q56',
        placeholder: '15',
        type: 'number',
      },
    ],
  },

  OptimhomeAgent: {
    greeting: 'Quelle est votre adresse e-mail Optimhome ?',
    paragraph: 'Exemple : jean.dubois@optimhome.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@optimhome.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  ToutvabiensParticulier: {
    greeting: 'Quelle est votre adresse e-mail ?',
    paragraph: 'Exemple : jean.dubois@toutvabiens.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@toutvabiens.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  ToutvabiensAgent: {
    greeting: 'Quelle est votre adresse e-mail ?',
    paragraph: 'Exemple : jean.dubois@toutvabiens.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@toutvabiens.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  OptimhomeClient: {
    greeting: "Indiquez l'adresse e-mail de votre conseiller Optimhome",
    paragraph: 'Exemple : jean.dubois@optimhome.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@optimhome.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  CapifranceAgent: {
    greeting: 'Quelle est votre adresse e-mail Capifrance ?',
    paragraph: 'Exemple : jean.dubois@capifrance.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@capifrance.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  CapifranceClient: {
    greeting: 'Indiquez l’adresse e-mail de votre conseiller Capifrance ?',
    paragraph: 'Exemple : jean.dubois@capifrance.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@capifrance.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  LaforetAgent: {
    greeting: 'Quelle est votre agence Laforêt ?',
    paragraph:
      'Pour faciliter votre recherche, veuillez saisir votre code agence(exemple: A84)',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@laforet.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  LotsAgent: {
    greeting: 'Quelle est votre adresse e-mail ?',
    paragraph: 'Exemple : jean.dubois@gmail.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@capifrance.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  LaforetClient: {
    greeting: 'Dans quelle agence Laforêt êtes-vous client ?',
    paragraph:
      'Pour faciliter votre recherche, veuillez saisir votre code agence (exemple: A84)',
    // 'items': [
    //     { label: 'E-mail', name: "partenaireEmail", placeholder: 'jean.dubois@capifrance.com', type:'email', validation: 'email'},
    // ],
  },
  LaforetAgentEmailPhone: {
    greeting: 'Quelle est votre agence Laforêt ?',
    paragraph:
      'Pour faciliter votre recherche, veuillez saisir votre code agence(exemple: A84)',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@laforet.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  LaforetEmailPhone: {
    greeting: 'Quelle est votre adresse e-mail Laforêt ?',
    paragraph: 'Exemple : jean.dubois@laforet.com',
    email: {
      label: 'E-mail',
      name: 'partenaireEmail',
      placeholder: 'jean.dubois@laforet.com',
      type: 'email',
      validation: 'email',
    },
    phone: {
      label: 'Téléphone',
      name: 'partenairePhone',
      placeholder: '0146821239',
      type: 'tel',
      validation: 'phone',
      maxLength: 10,
      minLength: 10,
    },
  },
  LotsADB: {
    greeting: 'Quelle est votre adresse e-mail ?',
    paragraph: 'Exemple : jean.dubois@gmail.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@laforet.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },
  BricomarcheEmail: {
    greeting: 'Quelle est votre adresse e-mail ?',
    paragraph: 'Exemple : jean.dubois@bricomarche.com',
    items: [
      {
        label: 'E-mail',
        name: 'partenaireEmail',
        placeholder: 'jean.dubois@bricomarche.com',
        type: 'email',
        validation: 'email',
      },
    ],
  },

  Success: {
    greeting: 'Success',
  },

  CreneauPicker: {
    creneau: [
      { label: 'Creneau', name: 'Creneau', value: 'Matin' },
      { label: 'Creneau', name: 'Creneau', value: 'Après-midi' },
    ],
  },
};

export default dataItems;
