const IDF1 = 6970;
const IDF2 = 8486;
const IDF3 = 12006;
const AUTRE1 = 5045;
const AUTRE2 = 6462;
const AUTRE3 = 9165;

const dataRevenu = {
  IDF: [
    [0, 23542, 28658, 40019],
    [0, 34552, 42059, 58828],
    [0, 41494, 50514, 70383],
    [0, 48448, 58982, 82840],
    [0, 55428, 67474, 94845],
    [0, 55428 + IDF1, 67474 + IDF2, 94845 + IDF3],
    [0, 55428 + IDF1 * 2, 67474 + IDF2 * 2, 94845 + IDF3 * 2],
    [0, 55428 + IDF1 * 3, 67474 + IDF2 * 3, 94845 + IDF3 * 3],
    [0, 55428 + IDF1 * 4, 67474 + IDF2 * 4, 94845 + IDF3 * 4],
    [0, 55428 + IDF1 * 5, 67474 + IDF2 * 5, 94845 + IDF3 * 5],
  ],
  Autres: [
    [0, 17010, 21806, 30550],
    [0, 24876, 31890, 44908],
    [0, 29918, 38350, 54072],
    [0, 34949, 44803, 63236],
    [0, 40003, 51282, 72401],
    [0, 40003 + AUTRE1, 51282 + AUTRE2, 72401 + AUTRE3],
    [0, 40003 + AUTRE1 * 2, 51282 + AUTRE2 * 2, 72401 + AUTRE3 * 2],
    [0, 40003 + AUTRE1 * 3, 51282 + AUTRE2 * 3, 72401 + AUTRE3 * 3],
    [0, 40003 + AUTRE1 * 4, 51282 + AUTRE2 * 4, 72401 + AUTRE3 * 4],
    [0, 40003 + AUTRE1 * 5, 51282 + AUTRE2 * 5, 72401 + AUTRE3 * 5],
  ],
};

export default dataRevenu;
