/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import SuccessPage from 'components/success/SuccessPage';
import CommentBlock from 'components/success/CommentBlock';

class SuccessComment extends React.Component {
  render() {
    const H3 = dataSuccess[this.props.success].h3;
    return (
      <SuccessPage {...this.props}>
        <CommentBlock>
          {typeof H3 === 'function' ? H3(this.props) : H3}
        </CommentBlock>
      </SuccessPage>
    );
  }
}

export default SuccessComment;
