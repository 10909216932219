/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from '../../data/dataSuccess';
import CodePromo from '../success/CodePromo';

class SuccessTemplateSidebar extends React.Component {
  render() {
    // const bgMobile = `url(${dataSuccess[this.props.success].imgMobile})`;
    const bgDesktop = `url(${dataSuccess[this.props.success].imgDesktop})`;
    const h1Title = this.props.H1audit
      ? this.props.H1audit
      : dataSuccess[this.props.success].h1;
    const h2Title =
      this.props.success === 'chauffagePac' ||
      this.props.success === 'chauffageGaz'
        ? `${dataSuccess[this.props.success].h2} ${this.props.result.data.Offre}`
        : dataSuccess[this.props.success].h2;
    return (
      <div>
        <button
          onClick={this.props.lastStep}
          className="btn-retour-success is-hidden-mobile"
        >
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99121 1.89551L2.10024 5.52993L5.99121 9.10477"
              stroke="#3A3A3A"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M3.88867 5.5L10.8887 5.5"
              stroke="#3A3A3A"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
          </svg>
          <span>Retour</span>
        </button>
        {!this.props.isPrime && (
          <div className="only-show-mobile">
            <CodePromo
              codePromo={this.props.codePromo}
              handleCodePromo={this.props.handleCodePromo}
              validCodePromo={this.props.validCodePromo}
              resultCodePromo={this.props.resultCodePromo}
              marqueHost={this.props.marqueHost}
            ></CodePromo>
          </div>
        )}

        <div className="success-left-container">
          <div
            style={{
              backgroundImage: bgDesktop,
              width: '100%',
              height: '133px',
            }}
            className="success-header-img is-hidden-mobile"
          >
            {h1Title && <h1>{h1Title}</h1>}
          </div>

          <div className="success-left-container-content">
            {h2Title && (
              <h2
                className="title-left"
                dangerouslySetInnerHTML={{ __html: h2Title }}
                style={{ maxWidth: '380px' }}
              />
            )}

            {dataSuccess[this.props.success].paragraphLeft && (
              <div style={{ maxWidth: '430px' }} className="ph-30 mt-10 mb-20">
                <p className="text-s" style={{ fontWeight: 500 }}>
                  {dataSuccess[this.props.success].paragraphLeft}
                </p>
              </div>
            )}
            <h4 className="text-main ml-30 mt-10">
              Détail de votre simulation
            </h4>
            <hr className="mh-30 mv-10 light-gray-hr" />
            {this.props.children}
          </div>
        </div>
        {!this.props.isPrime && (
          <div className="is-hidden-mobile">
            <CodePromo
              codePromo={this.props.codePromo}
              handleCodePromo={this.props.handleCodePromo}
              validCodePromo={this.props.validCodePromo}
              resultCodePromo={this.props.resultCodePromo}
              marqueHost={this.props.marqueHost}
            ></CodePromo>
          </div>
        )}
      </div>
    );
  }
}

export default SuccessTemplateSidebar;
