import React, { Component } from 'react';
import CreneauPicker from './CreneauPicker';
import UserFormDialog from './UserFormDialog';

class CallCTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showUserFormDialog: undefined,
    };
  }

  hideUserFormDialog = () => {
    this.setState({ showUserFormDialog: false });
  };

  showUserFormDialog = (requestDate) => {
    this.setState({ showUserFormDialog: { requestDate } });
  };

  showPicker = (e) => {
    this.setState({ showPicker: true });
  };

  hidePicker = (e) => {
    this.setState({ showPicker: false });
  };

  // show user form 20s after the component is mounted
  componentDidMount() {
    setTimeout(() => {
      if (!this.props.contact.lastname && !this.state.showUserFormDialog) {
        this.showUserFormDialog(false);
      }
    }, 10000);
  }

  render() {
    return (
      <>
        <div className="etre-rappele-box">
          <div>
            <button
              onClick={() => {
                if (!this.props.contact.lastname) {
                  this.showUserFormDialog(true);
                } else this.showPicker();
              }}
              className="creneau-btn"
            >
              Choisir un créneau pour être rappelé
            </button>
          </div>
          <button
            className="btn-main-hoverlarge is-bold"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!this.props.contact.lastname) {
                this.showUserFormDialog(true);
              } else if (this.props.date && this.props.creneau) {
                this.props.callBtn();
              } else {
                this.showPicker();
              }
            }}
          >
            Être rappelé
          </button>
          {/* <button className='btn-underline ml-30'>Simuler un prêt pour vos travaux</button>             */}
        </div>
        {this.state.showPicker && (
          <CreneauPicker
            callBtn={this.props.callBtn}
            creneau={this.props.creneau}
            date={this.props.date}
            handleDate={this.props.handleDate}
            handleCreneau={this.props.handleCreneau}
            hidePicker={this.hidePicker}
          />
        )}

        {this.state.showUserFormDialog && (
          <UserFormDialog
            onClose={this.hideUserFormDialog}
            open={!!this.state.showUserFormDialog}
            requestDate={this.state.showUserFormDialog?.requestDate}
            onSubmit={() => {
              this.props.simulationAPI(true);
              this.hideUserFormDialog();
            }}
            onChange={this.props.handleUserForm}
            initialValues={{
              ...this.props.contact,
              date: this.props.date,
              creneau: this.props.creneau,
            }}
          />
        )}
      </>
    );
  }
}

export default CallCTA;
