import React from 'react';
import Navigation from '../Navigation';
// import QuestionsTextSalutation from './QuestionsTextSalutation';
import showPopupList from '../../data/partenaires/showPopupList';
import Popup from './Popup';

class QuestionsEmailPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: false,
    };
  }

  handleTerms = () => {
    this.setState({ terms: !this.state.terms });
  };

  enterNext = (e) => {
    const inShowPopList = showPopupList.includes(this.props.name);
    if (e.key === 'Enter' && this.props.data && !inShowPopList) {
      this.props.nextStep();
    } else if (e.key === 'Enter' && this.props.data && inShowPopList) {
      this.props.handlePopup();
    }
  };

  componentDidMount() {
    document.querySelectorAll('input:not(input[type="radio"])')[0].focus();
  }

  componentDidUpdate() {
    const items = document.querySelectorAll('input:not(input[type="radio"])');
    if (!items[0].value) {
      items[0].focus();
    }
  }

  render() {
    console.log(this.state.terms);
    const inShowPopList = showPopupList.includes(this.props.name);
    const showPopup = inShowPopList && this.props.showPopup;
    // const items = this.props.dataItems[this.props.name]['items'].map(item => (
    //     <div className="input-container flex-input" key={item.name}>
    //         <label className="mt-10" style={{ width: '90px' }}>
    //             {item.label}
    //         </label>
    //         <div className="mt-5">
    //             <input
    //                 type={item.type ? item.type : 'text'}
    //                 placeholder={item.placeholder}
    //                 name={item.name}
    //                 onChange={this.props.handleData}
    //                 value={this.props.partenaireMail}
    //                 maxLength={item.maxLength}
    //                 minLength={item.minLength}
    //                 onKeyPress={this.enterNext}
    //             />
    //         </div>
    //     </div>
    // ));

    const mailItem = this.props.dataItems[this.props.name].email;
    const phoneItem = this.props.dataItems[this.props.name].phone;

    return (
      <div className="mt-40">
        <div className="w-100 d-flex jcc aic">
          <div className="d-flex column" style={{ maxWidth: '360px' }}>
            {showPopup && <Popup nextStep={this.props.nextStep}></Popup>}
            {mailItem && (
              <div>
                <div className="mb-10 d-flex">
                  <div
                    className="input-container flex-input"
                    key={mailItem.name}
                  >
                    <label className="mt-10" style={{ width: '90px' }}>
                      {mailItem.label}
                    </label>
                    <div className="mt-5">
                      <input
                        type={mailItem.type ? mailItem.type : 'text'}
                        placeholder={mailItem.placeholder}
                        name={mailItem.name}
                        onChange={this.props.handleData}
                        value={this.props.partenaireMail}
                        maxLength={mailItem.maxLength}
                        minLength={mailItem.minLength}
                        onKeyPress={this.enterNext}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.onForm && this.props.partenaireMail
                      ? 'd-flex'
                      : 'is-hidden'
                  }
                >
                  <div style={{ width: '90px' }}></div>
                  <div
                    className={
                      this.props.emailValid ? 'is-hidden' : 'validate-error'
                    }
                  >
                    Veuillez entrer un email valide
                  </div>
                </div>
              </div>
            )}
            {phoneItem && (
              <div>
                <div className="mb-10 d-flex">
                  <div
                    className="input-container flex-input"
                    key={phoneItem.name}
                  >
                    <label className="mt-10" style={{ width: '90px' }}>
                      {phoneItem.label}
                    </label>
                    <div className="mt-5">
                      <input
                        type={phoneItem.type ? phoneItem.type : 'text'}
                        placeholder={phoneItem.placeholder}
                        name={phoneItem.name}
                        onChange={this.props.handleData}
                        value={this.props.partenairePhone}
                        maxLength={phoneItem.maxLength}
                        minLength={phoneItem.minLength}
                        onKeyPress={this.enterNext}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.onForm && this.props.partenairePhone
                      ? 'd-flex'
                      : 'is-hidden'
                  }
                >
                  <div style={{ width: '90px' }}></div>
                  <div
                    className={
                      this.props.phoneValid ? 'is-hidden' : 'validate-error'
                    }
                  >
                    Veuillez entrer un numéro valide
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-100 d-flex jcc aic">
          <div className="checkbox-container mt-20">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              onChange={this.handleTerms}
              value={this.state.terms}
            ></input>
            <p>
              Je certifie avoir l'autorisation d'utiliser les coordonnées de mon
              mandant en vue de la réalisation d'un projet de rénovation
              énergétique.
            </p>
          </div>
        </div>

        {inShowPopList && (
          <Navigation
            data={this.props.data && this.state.terms}
            step={this.props.step}
            nextStep={this.props.handlePopup}
            lastStep={this.props.lastStep}
          />
        )}

        {!inShowPopList && (
          <Navigation
            data={this.props.data && this.state.terms}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
          />
        )}
      </div>
    );
  }
}

export default QuestionsEmailPhone;
