import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import safeIcon from 'images/icons/safeIcon.svg';

export default function ChoiceOffer({
  title = 'Votre offre choisie',
  className,
  descriptions,
  durations,
}) {
  return (
    <div className={className}>
      <h2 className="text-[14px] font-normal">{title}</h2>
      <div
        className={
          'flex flex-col lg:flex-row flex-wrap mt-3 lg:mt-4 gap-1 lg:gap-4'
        }
      >
        {descriptions.map((description, index) => (
          <div key={index} className={'flex items-center gap-2'}>
            <img src={description.icon} className={'w-4 h-4'} alt="" />
            <p className={'text-[14px]'}>{description.title}</p>
          </div>
        ))}
      </div>
      <div className={'flex mt-4 lg:justify-evenly justify-between'}>
        {durations.map((duration, index) => (
          <div
            key={index}
            className={
              'flex flex-row-reverse lg:flex-col lg:px-6 px-4 py-4 items-center gap-2 border border-solid border-xgreen rounded'
            }
          >
            <div className={'flex gap-2 items-center'}>
              <img src={safeIcon} className={'w-5 h-5'} alt="" />
              <p className="font-medium text[16px] lg:text-xdefault text-xgreen">
                {duration.years} ans
              </p>
            </div>
            <img
              src={duration.icon}
              className={'w-auto h-auto lg:max-h-[70px] max-h-[35px]'}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
}
ChoiceOffer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  descriptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  durations: PropTypes.arrayOf(
    PropTypes.shape({
      years: PropTypes.number,
      icon: PropTypes.string,
    }),
  ),
};
