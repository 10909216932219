import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import CreneauPicker from './CreneauPicker';
import Policy from './Policy';

const UserFormDialog = ({
  open,
  onClose,
  onChange,
  onSubmit,
  requestDate,
  initialValues = {},
}) => {
  const fields = [
    { name: 'lastname', placeholder: 'Nom', type: 'text' },
    { name: 'firstname', placeholder: 'Prénom', type: 'text' },
    { name: 'mobilephone', placeholder: 'Téléphone', type: 'tel' },
    { name: 'email', placeholder: 'E-mail', type: 'email' },
  ];

  const [showPicker, setShowPicker] = useState(false);
  const [policy, setPolicy] = useState(false);

  const [formValues, setFormValues] = useState({
    ...initialValues,
    lastname: '',
    firstname: '',
    mobilephone: '',
    email: '',
    salutation: '',
    date: undefined,
    creneau: 'Matin',
  });

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const validate = (values) => {
    const newErrors = {};

    fields.forEach((field) => {
      if (!values[field.name]) {
        newErrors[field.name] = `Le champ ${field.placeholder} est requis`;
      }
    });

    if (!values.salutation) {
      newErrors.salutation = 'Le champ Genre est requis';
    }

    return newErrors;
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    const newErrors = validate(formValues);
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValues = { ...formValues, [name]: value };
    const errors = validate(newValues);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onChange(newValues);
    }
    setFormValues(newValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Mark all fields as touched
    const allTouched = {};
    fields.forEach((field) => {
      allTouched[field.name] = true;
    });
    allTouched.salutation = true;
    if (requestDate) {
      allTouched.date = true;
    }
    setTouched(allTouched);

    const newErrors = validate(formValues);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSubmit(formValues);
    }
  };

  const labelClassName = 'block mb-2 text-[#3A3A3A] text-sm';
  const inputClassName =
    'py-3 px-3 box-border bg-[#F5F5F5] border-gray-300 rounded';
  const errorClassName = 'text-red-500 text-xs';

  const submitable =
    Object.keys(errors).length === 0 &&
    Object.keys(touched).length > 0 &&
    policy;

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        root: 'user_form_dialog',
      }}
    >
      <div className="p-3 w-[375px] calculette">
        <h2 className="text-center text-main mb-4">
          {requestDate
            ? 'Vous souhaitez être rappelé ?'
            : 'Concrétisez votre projet de rénovation'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="salutation"
                value="Monsieur"
                checked={formValues.salutation === 'Monsieur'}
                onChange={handleChange}
                onBlur={() => handleBlur('salutation')}
                required
              />
              <span className="ml-2">Monsieur</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="salutation"
                value="Madame"
                checked={formValues.salutation === 'Madame'}
                onChange={handleChange}
                onBlur={() => handleBlur('salutation')}
                required
              />
              <span className="ml-2">Madame</span>
            </label>
          </div>
          {touched.salutation && errors.salutation && (
            <p style={{ marginTop: 4 }} className="text-red-500 text-xs">
              {errors.salutation}
            </p>
          )}

          {fields.map((field) => (
            <div key={field.name}>
              <label
                htmlFor={`user-form-${field.name}`}
                className={labelClassName}
              >
                {field.placeholder}*
              </label>
              <input
                id={`user-form-${field.name}`}
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                value={formValues[field.name]}
                onChange={handleChange}
                onBlur={() => handleBlur(field.name)}
                className={`${inputClassName} w-full ${
                  touched[field.name] && errors[field.name]
                    ? 'border border-red-400 border-solid'
                    : 'border-none'
                } rounded`}
                required
              />
              {touched[field.name] && errors[field.name] && (
                <p style={{ marginTop: 4 }} className={errorClassName}>
                  {errors[field.name]}
                </p>
              )}
            </div>
          ))}

          {requestDate && (
            <div onClick={() => setShowPicker(true)}>
              <label className={labelClassName}>Définir une date</label>
              <input
                type="date"
                name="date"
                value={formValues.date?.toISOString()?.split('T')?.[0]}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onBlur={() => handleBlur('date')}
                className={`${inputClassName} ${
                  touched.date && errors.date
                    ? 'border border-red-400 border-solid'
                    : 'border-none'
                } rounded`}
              />
              {touched.date && errors.date && (
                <p className={errorClassName}>{errors.date}</p>
              )}
            </div>
          )}
          <div className={''}>
            <Policy
              onChange={(e) => {
                setPolicy(e.target.checked);
              }}
            />
          </div>
          {showPicker && (
            <CreneauPicker
              callBtn={() => setShowPicker(false)}
              creneau={formValues.creneau}
              date={formValues.date}
              handleDate={(date) => {
                const newValues = { ...formValues, date };
                setFormValues(newValues);
                onChange(newValues);
              }}
              handleCreneau={(e) => {
                const newValues = { ...formValues, creneau: e.target.value };
                setFormValues(newValues);
                onChange(newValues);
              }}
              hidePicker={() => setShowPicker(false)}
              callBtnText={'Valider'}
            />
          )}
          <div className={'pt-4 flex justify-center'}>
            <button
              disabled={!submitable}
              type="submit"
              className="btn-nav-m"
              style={{
                width: 'auto',
                backgroundColor: submitable ? '#FBA03D' : '#959595',
              }}
            >
              {requestDate ? 'Être rappelé' : 'Valider'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

UserFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  requestDate: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default UserFormDialog;
