import React from 'react';
import Navigation from '../Navigation';
import showPopupList from '../../data/partenaires/showPopupList';
import Popup from '../partenaires/Popup';

class QuestionsEmail extends React.Component {
  enterNext = (e) => {
    const inShowPopList = showPopupList.includes(this.props.name);
    if (e.key === 'Enter' && this.props.data && !inShowPopList) {
      this.props.nextStep();
    } else if (e.key === 'Enter' && this.props.data && inShowPopList) {
      this.props.handlePopup();
    }
  };

  componentDidMount() {
    document.querySelectorAll('input:not(input[type="radio"])')[0].focus();
  }

  componentDidUpdate() {
    const items = document.querySelectorAll('input:not(input[type="radio"])');
    if (!items[0].value) {
      items[0].focus();
    }
  }

  render() {
    const ReversedPopupColor = [
      'ToutvabiensParticulier',
      'ToutvabiensAgent',
    ].includes(this.props.name);
    const inShowPopList = showPopupList.includes(this.props.name);
    const showPopup = inShowPopList && this.props.showPopup;
    const items = this.props.dataItems[this.props.name].items.map((item) => (
      <div className="input-container flex-input" key={item.name}>
        <label className="mt-10" style={{ width: '90px' }}>
          {item.label}
        </label>
        <div className="mt-5">
          <input
            type={item.type ? item.type : 'text'}
            placeholder={item.placeholder}
            name={item.name}
            onChange={this.props.handleData}
            value={this.props.partenaireMail}
            maxLength={item.maxLength}
            minLength={item.minLength}
            onKeyPress={this.enterNext}
          />
        </div>
      </div>
    ));

    return (
      <div className="mt-40">
        <div className="w-100 d-flex jcc aic">
          <div className="d-flex column" style={{ maxWidth: '360px' }}>
            {showPopup && (
              <Popup
                nextStep={this.props.nextStep}
                ReversedPopupColor={ReversedPopupColor}
              ></Popup>
            )}
            <div className="mb-10 d-flex">{items}</div>
            <div className={this.props.onForm ? 'd-flex' : 'is-hidden'}>
              <div style={{ width: '90px' }}></div>
              <div
                className={
                  this.props.emailValid ? 'is-hidden' : 'validate-error'
                }
              >
                Veuillez entrer un email valide
              </div>
            </div>
          </div>
        </div>
        {inShowPopList && (
          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.handlePopup}
            lastStep={this.props.lastStep}
          />
        )}

        {!inShowPopList && (
          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
          />
        )}
      </div>
    );
  }
}

export default QuestionsEmail;
