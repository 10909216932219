/* eslint-disable react/prop-types */
import React from 'react';
import InfoTooltip from 'components/InfoTooltip';

class EnergyTag extends React.Component {
  render() {
    const APIresult = this?.props?.result?.data;
    const energyTagIsUnknown = this?.props?.answers?.Q53 === 'Je ne sais pas';
    const energyTag = energyTagIsUnknown
      ? APIresult?.['DPE estimé']
      : this?.props?.answers?.Q53;
    const gorenoveUrl =
      APIresult?.bnb_url ?? 'https://particulier.gorenove.fr/';
    return energyTag ? (
      <div className="success-item">
        <h3>
          Étiquette énergétique
          <InfoTooltip small>
            <p>
              Votre étiquette énergétique est une estimation fournie par Go
              rénove grâce à votre adresse, vous pouvez{' '}
              <a
                href={gorenoveUrl}
                target="_blank"
                className="text-white"
                rel="noreferrer"
              >
                voir votre diagnostic ici
              </a>
            </p>
          </InfoTooltip>
        </h3>
        <img
          src={`https://www.hellio.com/hubfs/simulateur/icons3/tags/${energyTag}.svg`}
          className="align-middle h-6"
        />
      </div>
    ) : null;
  }
}

export default EnergyTag;
