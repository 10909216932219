import React from 'react';
import PropTypes from 'prop-types';

export default function CommentBlock({ children }) {
  return children ? (
    <div className="success-item-right">
      <div className="mb-4">
        <p className="text-s mt-20 leading-4">{children}</p>
      </div>
    </div>
  ) : null;
}
CommentBlock.propTypes = {
  children: PropTypes.any,
};
