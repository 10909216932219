import React from 'react';
import dataRevenu from '../data/dataRevenu';
import Navigation from './Navigation';

class Revenu extends React.Component {
  //   enterNext = e => {
  //       console.log('next', e.key)
  //     if (e.key === 'Enter' && this.props.data) {
  //       this.props.nextStep();
  //     }
  //   };

  //   componentDidMount(){
  //     document.addEventListener("keydown", this.enterNext);
  //   }

  //   componentWillUnmount() {
  // document.removeEventListener('keydown', this.enterNext);
  //     }

  render() {
    const numberPeople = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
      <label
        key={item}
        className={
          item == this.props.revenuPeople
            ? 'radio-rounded radio-rounded-checked'
            : 'radio-rounded'
        }
      >
        {item}
        <input
          type="radio"
          value={item}
          name="people"
          onClick={this.props.handleRevenuPeople}
        />
      </label>
    ));
    const numberPeople1 = [1, 2, 3, 4, 5].map((item) => (
      <label
        key={item}
        className={
          item == this.props.revenuPeople
            ? 'radio-rounded radio-rounded-checked'
            : 'radio-rounded'
        }
      >
        {item}
        <input
          type="radio"
          value={item}
          name="people"
          onClick={this.props.handleRevenuPeople}
        />
      </label>
    ));

    const numberPeople2 = [6, 7, 8, 9, 10].map((item) => (
      <label
        key={item}
        className={
          item == this.props.revenuPeople
            ? 'radio-rounded radio-rounded-checked'
            : 'radio-rounded'
        }
      >
        {item}
        <input
          type="radio"
          value={item}
          name="people"
          onClick={this.props.handleRevenuPeople}
        />
      </label>
    ));
    const currentRevenus =
      dataRevenu[this.props.revenuArea][this.props.revenuPeople - 1];
    const options = currentRevenus.map((option) => (
      <label
        key={option}
        className={
          this.props.data !== '' && option == this.props.data
            ? 'radio-oval radio-oval-checked'
            : 'radio-oval'
        }
      >
        {option == currentRevenus[0] && (
          <p>
            {option} € à {currentRevenus[1] - 1} €{' '}
          </p>
        )}
        {option == currentRevenus[1] && (
          <p>
            {option} € à {currentRevenus[2] - 1} €{' '}
          </p>
        )}
        {option == currentRevenus[2] && (
          <p>
            {option} € à {currentRevenus[3] - 1} €{' '}
          </p>
        )}
        {option == currentRevenus[3] && <p>Supérieur à {option}€ </p>}

        <input
          type="radio"
          value={option}
          name="Q19"
          onClick={this.props.handleData}
          // onKeyPress={this.enterNext}
        />
      </label>
    ));
    //   console.log(dataRevenu)

    return (
      <div>
        <div className="fadeIn align-center">
          <div>
            <h4 className="text-main mv-10">
              Nombre de personnes dans votre logement
            </h4>
            <div className="only-show-mobile">
              <div className="d-flex aic jcc">{numberPeople1}</div>
              <div className="d-flex mt-10 aic jcc">{numberPeople2}</div>
            </div>
            <div className="is-hidden-mobile d-flex aic jcc">
              {numberPeople}
            </div>

            <h4 className="text-main mt-30 mb-10">
              Revenu fiscal de référence
            </h4>
            <div className="d-flex col-m jcc">{options}</div>
          </div>
          <Navigation
            data={this.props.data}
            step={this.props.step}
            nextStep={this.props.nextStep}
            lastStep={this.props.lastStep}
          />
        </div>
      </div>
    );
  }
}

export default Revenu;
