import React from 'react';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }
  // showHover = () => {
  //     this.setState({ display: true })
  // }

  // hideHover = () => {
  //     this.setState({ display: false })
  // }

  // toggleClick = () => {
  //     this.setState({ display: !this.state.display });
  // }

  render() {
    return (
      <div>
        <div className="popup-bg"></div>
        <div
          className={
            this.props.ReversedPopupColor
              ? 'popup-container bg-reversed'
              : 'popup-container'
          }
        >
          <h1>
            À partir de maintenant, vous allez saisir les informations pour
            votre client.
          </h1>
          <p className="mt-20">
            Veuillez renseigner les informations nécessaires en son nom.
          </p>
          <button className="btn-sub mt-20" onClick={this.props.nextStep}>
            Continuer
          </button>
        </div>
      </div>
    );
  }
}

export default Popup;
