import React from 'react';
import Main from './components/Main';
import './App.scss';
import TagManager from 'react-gtm-module';
import setMarque from './data/setMarque';
import dataItems, { Q1OptionToSkip } from 'data/dataItems';

const tagManagerArgs = {
  gtmId: 'GTM-P39RH8J',
  dataLayerName: 'simulateurGTM',
};

TagManager.initialize(tagManagerArgs);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marqueHost: 'default',
      // marqueHost: 'laforet-simulateur.hellio.com',
      // marqueHost: 'toutvabiens-simulateur.hellio.com',
      // marqueHost: 'monideal-artisans-simulateur.hellio.com',
      // marqueHost: 'bricorama-simulateur.hellio.com',
      // marqueHost: 'simulateur-corse.hellio.com',
      // Only change marqueHost for test purpose, make sure it stays as 'default' before officially deploy.
    };
  }

  componentDidMount() {
    const hostname = window.location.hostname;
    if (setMarque[hostname]) {
      this.setState({ marqueHost: hostname });
    }
  }

  render() {
    const color1 = setMarque[this.state.marqueHost].color1;
    const color2 = setMarque[this.state.marqueHost].color2;
    const logoMarque = setMarque[this.state.marqueHost].logoMarque;
    const logoLink = setMarque[this.state.marqueHost].logoLink;
    const logoMarqueWidth = setMarque[this.state.marqueHost].logoMarqueWidth;
    const headerBgcolor = setMarque[this.state.marqueHost].headerBgcolor
      ? setMarque[this.state.marqueHost].headerBgcolor
      : 'white';
    const arrayQ1 = setMarque[this.state.marqueHost].removeQ1;
    const dataItemsUpdate = dataItems;
    const marque =
      setMarque[this.state.marqueHost].marque == 'Hellio'
        ? undefined
        : setMarque[this.state.marqueHost].marque;
    const favicon = setMarque[this.state.marqueHost].favicon;
    const homeLink = 'https://hellio.com/';
    const hideHellioLogo = setMarque[this.state.marqueHost].hideHellioLogo;
    // const originalURL = this.state.marqueHost == 'default';

    // Add favicon from setMarque
    const link = document.querySelector("link[rel~='icon']");
    // let metaRobots = document.querySelector("meta[name='robots']");
    link.href =
      favicon || 'https://www.hellio.com/hubfs/Hellio_Logo_Bleu_Favicon.png';
    // metaRobots.content = originalURL ? 'all' : 'noindex, nofollow';
    // Remove certain options from Q1 :
    if (arrayQ1) {
      const dataItemsQ1 = dataItems.Q1.items.filter(function (item) {
        return (
          !arrayQ1.includes(item.short) && !Q1OptionToSkip.includes(item.short)
        );
      });
      dataItemsUpdate.Q1.items = dataItemsQ1;
    }

    const css = `

  .calculette .header2 {
    background-color: ${headerBgcolor};
  }
  .text-main {
      color: ${color1}!important;
  }

  .success-item-right .h5-prime {
    background-color: ${color1};
  }

  .creneau-btn {
    color: ${color2}
  }

  .react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: ${color1};
  }

  .final-container h2 {
    color: ${color1}
  }
  .popup-container {
    background-color: ${color2};
  }

  .bg-reversed {
    background: ${color1};
  }

  .btn-sub {
    color: ${color2}!important;
  }

  .btn-sub:hover {
        background-color: ${color2}!important;
        color: white!important;
  }

  .bg-reversed .btn-sub {
    color: ${color1}!important;
  }


  .calculette .progress-bar-container .progress-bar {
    background-color: ${color2};
  }
  .success-mobile-header {
    background-color: ${color2};
  }

  .btn-nav-m {
    background-color: ${color2};
  }

  .item-container-m .item-checked {
    border: 1px solid ${color2};
  }

  @media screen and (max-width:767px) {
    .calculette .progress-bar-container .progress-bar {
      background-color: ${color2};
    }
  }

  .calculette .text-sub2 {
    color: ${color2}
  }
  .calculette .btn-aller {
      background-color: ${color2};
      border: 3px solid ${color2};
  }

  .item-container .item-checked {
    border: 2px solid ${color2}!important;
  }

  .square-checked {
    background: ${color1};
    border: 1px solid ${color1};
  }

  .radio-rounded-checked {
    background-color: ${color2};
    border: 1px solid ${color2}!important;
  }

.radio-oval-checked {
    background-color: ${color1};
    border: 1px solid ${color1}!important;
  }

  .radio-oval:hover{
    border: 1px solid ${color1};
  }

  .success-right-container .successTitle {
    color: ${color1}
  }

  .success-right-container h3 {
    color: ${color1}
  }

  .btn-main-hoverlarge {
    background-color: ${color2};
    border-color: ${color2}
  }

  .input-container input {
    color: ${color1};
  }

  .btn-retour svg path{
    stroke: ${color2}
  }
  .icon-check svg path {
    fill: ${color2}
  }

  .info-block-container {
    color: ${color1}
  }

  .item-detail-container .radio, .item-detail-container .radio-checked, .item-detail-container .item-checked {
    border: 1px solid ${color2};
  }
.item-detail-container .radio-checked .radio-inner {
    background-color: ${color2}
}

.vertical-borne-list .list-item-l h1 {
  color: ${color1};
}

.vertical-borne-list .list-bullet, .vertical-borne-list .vline-main {
  background-color: ${color1}
}

.iconColor1retour svg path, .iconColor1retour svg rect {
  stroke: ${color2}
}
.iconColor1 svg path, .iconColor1 svg g path{
fill: ${color1}
}
.iconColor1 svg circle {
  stroke:  ${color1}
}

.iconColor2 svg path{
  fill: ${color2}
}

.btn-file-uploader{
  color: ${color1}
}

.solaire-card-container .solaire-card .head {
  background-color: ${color2}
}

.solaire-card-container .solaire-card .content .prime {
  background-color: ${color1}
}

.logoMarque {
  width: ${logoMarqueWidth + 'px'};
}

@media screen and (max-width:1024px) {
  .logoMarque {
    width: auto
  }
}
`;

    return (
      <div className="calculette">
        <style>{css}</style>
        <div className="wrapper">
          <header className="header2">
            {logoMarque && (
              <div>
                {logoLink && (
                  <a href={logoLink} target="_blank" rel="noreferrer">
                    <img src={logoMarque} className="logoMarque" />
                  </a>
                )}
                {!logoLink && <img src={logoMarque} className="logoMarque" />}

                <p
                  className={
                    headerBgcolor === 'white'
                      ? 'text-main header-text'
                      : 'header-text'
                  }
                >
                  Estimez le coût <br className="is-hidden-tablette" />
                  de votre projet !
                </p>
                <a href={homeLink} target="_blank" rel="noreferrer">
                  <img
                    src={
                      headerBgcolor === 'white'
                        ? '/logo-blue.svg'
                        : '/logo-white.svg'
                    }
                    className={
                      hideHellioLogo ? 'logoHeader is-hidden' : 'logoHeader'
                    }
                  />
                </a>
              </div>
            )}

            {!logoMarque && (
              <div className="default-header">
                <p>
                  Estimez le coût <br className="is-hidden-tablette" />
                  de votre projet !
                </p>
                <a href={homeLink} target="_blank" rel="noreferrer">
                  <img
                    src={
                      headerBgcolor === 'white'
                        ? '/logo-blue.svg'
                        : '/logo-white.svg'
                    }
                    className="logoHeader is-hidden-tablette"
                  />
                </a>
                <a href={homeLink} target="_blank" rel="noreferrer">
                  <img
                    src={
                      headerBgcolor === 'white'
                        ? '/logo-blue.svg'
                        : '/logo-white.svg'
                    }
                    className="logoHeaderDefault only-show-tablette"
                  />
                </a>
              </div>
            )}
          </header>
          <div className="header2s"></div>
          <main className="main-container">
            <div className="content-container">
              <Main
                dataItems={dataItemsUpdate}
                marque={marque}
                marqueHost={this.state.marqueHost}
              />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default App;
