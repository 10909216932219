const dataRoutes = {
  Q1: '/particuliers/projet-travaux',
  Q2: '/particuliers/logement-type',
  'Q2.V2': '/particuliers/logement-type',
  Q3: '/particuliers/logement-construction',
  Q4: '/particuliers/isolation',
  Q5: '/particuliers/isolation-combles',
  Q6: '/particuliers/isolation-murs',
  Q7: '/particuliers/changement-chauffage',
  Q8: '/particuliers/logement-surface',
  Q9: '/particuliers/vehicule-electrique',
  Q10: '/particuliers/voiture-modele',
  Q11: '/particuliers/puissance-installation',
  Q12: '/particuliers/cable-distance',
  // 'Q13': '/Q13',
  Q14: '/particuliers/audit',
  Q15: '/particuliers/mensualite',
  Q16: '/particuliers/contrat-electrique',
  Q17: '/particuliers/logement',
  Q18: '/particuliers/syndic-coordonnees',
  Q19: '/particuliers/logement-menage',
  Q20: '/particuliers/proprietaire-locataire',
  'Q20.V2': '/particuliers/proprietaire-locataire',
  // 'Q21': '',
  Q22: '/particuliers/logement-energie',
  Q23: '/particuliers/energie-fioul',
  Q24: '/particuliers/energie-electricite',
  Q25: '/particuliers/energie-gaz',
  Q26: '/particuliers/adresse-projet',
  Q27: '/particuliers/adresse-fiscale',
  Q28: '/particuliers/temps-travaux',
  Q29: '/particuliers/coordonnees',
  Q30: '/particuliers/accompagnement',
  Q31: '/particuliers/code-parrain',
  Q32: '/particuliers/newsletter',
  Q33: '/particuliers/Q33',
  Q34: '/particuliers/energie-charbon',
  Q35: '/particuliers/travaux-renovation-globale',
  Q36: '/particuliers/information-renovation-globale',
  Q37: '/particuliers/information-audit-energetique',
  Q38: '/particuliers/simulation-projet-final',
  Q39: '/particuliers/renovation-globale-isolation',
  Q40: '/particuliers/preference-chauffage',
  Q41: '/particuliers/type-combles',
  Q42: '/particuliers/type-murs',
  Q43: '/particuliers/devis',
  Q44: '/particuliers/surface-isolation',
  Q45: '/particuliers/primes',
  Q46: '/particuliers/nombre-fenetre',
  Q47: '/particuliers/surface-chauffe',
  Q48: '/particuliers/type-audit',
  Q49: '/particuliers/type-fenetre',
  Q50: '/particuliers/type-PAC',
  Q51: '/particuliers/usage-pac',
  Q52: '/particuliers/type-chaudiere',
  'Q53.V2': '/particuliers/type-chauffage',
  Q54: '/particuliers/robinets-thermostatiques',
  Q55: '/particuliers/thermostat',
  Q56: '/particuliers/nombre-émetteurs',
  Q53: '/particuliers/etiquette-energetique',
  'Q4.V2': '/particuliers/accompagnateur-renov',
  Success: '/particuliers/simulation-projet',
};

export default dataRoutes;
