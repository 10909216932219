import PropTypes from 'prop-types';
import React, { useState } from 'react';
import clsx from 'clsx';
import infos from 'images/icons/infos.svg';
import close from 'images/icons/close.svg';

export default function Alert({ children, className }) {
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  return visible ? (
    <div
      className={clsx(
        'flex items-center bg-xlight_blue border border-solid border-xborder_blue rounded p-3 gap-2',
        className,
      )}
    >
      <img src={infos} alt="Infos" />
      <p className="flex-grow text-xblue font-medium text-[12px] lg:text-[14px]">
        {children}
      </p>
      <button
        className={
          'border border-transparent bg-transparent flex items-center justify-center cursor-pointer rounded-full w-6 h-6 hover:border-xblue duration-300'
        }
        onClick={onClose}
      >
        <img src={close} alt="Fermer" />
      </button>
    </div>
  ) : (
    <div className={'h-4'} />
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func,
};
